.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;

  align-items: center;
}
.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  color: #fff;
  font-size: var(--font-size-16);
  padding: 10px 20px;
  background-color: var(--sidebar-header-bg);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.file-input__label svg {
  height: 16px;
  margin-right: 8px;
}
.file-input .placeholder {
  font-size: var(--font-size-16);
  font-weight: 500;
  line-height: 15.78px;
  color: var(--location-placeholder);
}
