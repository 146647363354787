.favoriteShare{

    height: 79px;
    border-radius: 9.15px;
    background: var(--element-light-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin: 0 35px;
}
