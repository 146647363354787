.recentSearchersTitle {
  color: var(--title-color);
  margin-bottom: 15px;
  font-size: var(--font-size-14-5);
  line-height: 14.37px;
}

.recentSearch {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 13px 0;
  border-bottom: 1px solid var(--line-color);
  cursor: pointer;
}
.recentSearchers {
  margin-top: 10px;
}
.recentSearchTitle {
  font-size: var(--font-size-13);
  font-weight: 400;
  line-height: 12.83px;
  color: var(--main-text-color);
}

.recentSearchLocation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .recentSearchAddress {
  color: var(--third-text-color);
  font-size: var(--font-size-13-7);
  line-height: 18.27px;
} */
.recentSearchDestination {
  color: #7a7f97;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.56px;
}

.shoppingIcon {
  width: 16px;
  height: 16px;
  background: var(--element-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pointOfInternetType {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pointOfInternetTypeTitle {
  color: var(--element-text-color);
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-13);
  font-weight: 400;
  line-height: 17.28px;
}

.loading {
  padding: 10px 0;
  color: var(--main-text-color);
}
