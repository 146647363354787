.routesFavorites {
  /* padding: 0 35px; */
}
.routeFavorite {
  /* min-width: 403.57px; */
  height: 72.03px;
  border-radius: 9.15px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 15px;
  background: var(--element-light-bg);
  box-shadow: 0px 0px 11.43px 0px #0000001a;
}

.routesLogoWarper {
  width: 36.58px;
  height: 36.58px;
  background: var(--favorite-icons);
  /* flex: 1; */
  border-radius: 50%;
  align-items: center;
  position: relative;
}

.routesLine {
  flex: 7;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.routeDetails {
  flex: 1.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.routeSettingWarper {
  flex: 0.3;
  display: flex;
  justify-content: flex-end;
  cursor:pointer
}

.routesLine .routeName {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-18-3);
  font-weight: 400;
  line-height: 13.58px;
  color: var(--route-name);
}
.routeDetails .distance {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-18-3);
  font-weight: 400;
  line-height: 13.58px;
  color: #009d91;
  text-align: end;
}

.routeDetails .street {
  /* font-size: var(--font-size-18-3); */

  line-height: 13.58px;
  color: var(--street-name);
  text-align: end;
  /* white-space: nowrap;*/
} 

.routesFavorites .marker {
  width: 10.79px;
  height: 10.79px;
  border: 2.11px solid var(--dot-line);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dot-bg);
  top: 0%;

  position:absolute

}
.routesFavorites .markerChild {
  width: 4.2px;
  height: 4.2px;
  background: #009d91;
  border-radius: 50%;
}

.routesFavorites {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.routesLogoWarper .flag {
  position: absolute;
  bottom: 25%;
  left: 55%;
}

.routesLogoWarper .flag2 {
  position: absolute;
  bottom: 50%;
  left: 25%;
}

.line {
  display: flex;
  border-top: 4px dashed var(--route-line);
  width: 90%;
  margin-top: 7px;
  position: relative;
}
.lineWarper {
  display: flex;
  gap: 4px;
}
.lineWarper p {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-18-3);
  font-weight: 400;
  line-height: 13.58px;
  color: var(--street-name);
}

.dash-container {
  display: flex;
  gap: 8px;
  padding: 5px;
  position: relative;
}

.dash {
  width: 17px;
  height: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #D1D1D1;
}
