@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.liststyle{
 
    list-style-type: disc; 
    margin-left: 15px;
    margin-right:-6px;
    color: var(--main-text-color);

  li{
    margin-bottom: 19.2px;
  }
    .parastyle{
      font-size: var(--font-size-14);
    margin-bottom: 0;
    line-height: 1.5;

    }
}


.subtitle-disclaimer{
    @include apply-font-family(semibold);
    font-size: var(--font-size-15);
    color: var(--main-text-color);
    margin-bottom: 10.27px;
    margin-top: 22.2px;
    line-height: 14.81px;
}

   // arabic mode 
 [dir=rtl]{
.liststyle{
  margin-left: inherit;
  margin-right: 15px;
}
 }

@media (max-width: 1679px) {
    .liststyle .parastyle{
      font-size: var(--font-size-14);
      
    }
}
@media (max-width: 991px) {
    .liststyle .parastyle{
      font-size: var(--font-size-13);
 
        margin-bottom: 15px;
    }
}