@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.myMakaniCards {
  max-height: calc(100% - 210px);
  width: 365px;
  position: absolute;
  left: 530px;
  background: var(--sidebar-bg);
  top: 191px;
  bottom: auto;
  border-radius: 21px;
  z-index: 100;
  box-shadow: 0px 0px 49.15px 0px rgba(0, 0, 0, 0.2117647059);
  display: flex;
  flex-direction: column;
  padding: 11px 15px 30px;
  gap: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

[data-size="2"] {
  @media (min-width: 1200px) {
    .myMakaniCards {
      width: 415px;
      left: 590px;
    }
    .streetView {
      left: 588px;
    }
  }
}
.streetView {
  height: calc(100% - 210px);
  width: 365px;
  position: absolute;
  left: 515px;
  background: var(--sidebar-bg);
  top: 191px;
  border-radius: 21px;
  z-index: 100;
  box-shadow: 0px 0px 49.15px 0px #00000036;
  display: flex;
  flex-direction: column;
  padding: 11px 15px;
  gap: 5px;
  // > div {
  //   mix-blend-mode: difference;
  // }
  > div > img {
    top: 10px !important;
    right: 10px !important;
  }
  .gm-fullscreen-control {
    top: 40px !important;
  }
}

[dir="rtl"] {
  .streetView {
    left: inherit;
    right: 515px;
    @media (max-width: 1679px) {
      right: 465px;
    }
    @media (max-width: 1479px) {
      right: 435px;
    }
    @media (max-width: 1281px) {
      right: 420px;
    }
    @media (max-width: 1199px) {
      right: 363px;
    }
    @media (max-width: 991px) {
      right: 390px;
    }
    @media (max-width: 767px) {
      right: 0px;
      left: 0;

      .gm-fullscreen-control {
        right: auto !important;
        left: 0px;
      }
    }
  }
}

.makaniCloseIcon {
  width: 22.81px;
  height: 22.81px;
  border-radius: 50%;
  background: #424c60;
  display: flex;
  padding-left: 0.95px;
  align-self: flex-end;
  cursor: pointer;
  flex-shrink: 0;
}

.myMakaniCardsWarper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 30px;
  color: #c6c6c6;
  // overflow-y: scroll;
  overflow: auto;
  .myMakaniCardHeader {
    overflow: hidden;
  }
  .contactDetailsLabel {
    display: flex;
  }
  .myMakaniCardBtns {
    p {
      display: flex;
      align-items: center;
      column-gap: 5px;
      line-height: 1.2;
      padding-top: 3px;
    }
    .top-spc {
      position: relative;
      top: 3px;
    }
    .icons-sm svg {
      color: var(--btn-icon-color);
      width: 16px;
    }
  }
}

.myMakaniCardHeader {
  background: var(--element-light-bg);
  display: flex;
  border: 0.39px solid var(--toggle-border);
  justify-content: space-between;
  border-radius: 8.81px;
  margin-bottom: 11px;
}
.MakaniCardHeader .logo {
  width: 76.38px;
  height: auto;
}
.myMakaniCardContent {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;
}

.myMakaniCardContentDetails {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.myMakaniCardContent .title {
  font-size: var(--font-size-12-3);
  font-weight: 500;
  line-height: 12.21px;
  color: var(--element-text-color);
}
.myMakaniCardContent .details {
  @include apply-font-family(regular);
  font-size: var(--font-size-11);
  font-weight: 400;
  line-height: 1.1;
  color: var(--element-text-color);
}

.myMakaniCardContent .makaniNumber {
  @include apply-font-family(semibold);
  font-size: var(--font-size-19);
  font-weight: 500;
  line-height: 1.1;
  color: var(--main-text-color);
}

.myMakaniCardContent .details {
  font-size: var(--font-size-11);
  font-weight: 500;
  line-height: 1.1;
  color: var(--element-text-color);
}

.qrCodeImage {
  position: absolute;
  right: 25px;
  top: 30px;
}
.CardQrWrapper p {
  position: absolute;
  right: 25px;
  bottom: 20px;
  font-size: var(--font-size-11);
  font-weight: 500;
  line-height: 10.47px;
  color: var(--website);
}

// arabic mode
[dir="rtl"] {
  @media (min-width: 1200px) {
    .CardQrWrapper {
      margin-left: 15px;
    }
    [data-size="2"] {
      .myMakaniCards {
        left: unset;
        right: 590px;
      }
    }
  }
}

.myMakaniCardBtns {
  display: flex;
  justify-content: space-between;
  column-gap: 6.5px;
}

.myMakaniCardBtns div {
  width: 33%;
  height: 37.51px;
  border-radius: 7.9px;
  background: #4e5972;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.myMakaniCardBtns p {
  font-size: var(--font-size-11);
  font-weight: 500;
  line-height: 10.84px;
  color: #ffffff;
}

.myMakaniCard {
  margin: 0 15px;
  cursor: pointer;
}
.myMakaniCardHeader:active {
  border: 1.82px solid #4e59728f;
}
.makaniCardMarker {
  left: 39.5%;
  top: 100%;
  position: absolute;
  z-index: 300;
  transform: translate(50%, 0);
}
.deatilsMakaniCard {
  // min-width: 362.53px;
  box-shadow: 0px 0px 11.4px 0px #0000001a;
  min-height: auto;
  height: auto;
  border-radius: 17.1px;
  background: var(--element-light-bg);
  margin: 20px 11.5px 15.26px;
  overflow: visible;
  transition: transform 0.3s ease;
  flex: 0 0 auto;
  position: relative;
  left: -6px !important;
}
.deatilsMakaniCardActive {
  display: block;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--element-light-bg);
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, 30%);
  }
  .MakaniCardContentText {
    font-size: var(--font-size-15);
  }
}
.DetailsMakaniCardBtn {
  flex-shrink: 0;
}
// .DetailsMakaniCardBtns{
//   padding-bottom: 18px;
//   column-gap: 12.64px;
// }
//   single card

.MakaniCardHeader {
  overflow: hidden;

  .copyWrapper {
    margin-right: auto;
    margin-left: 16px;
    padding-left: 16px;
    position: relative;
  }
  .copyWrapper:before {
    background-color: #fff;
    content: "";
    height: 60px;
    left: 0;
    position: absolute;
    width: 1px;
  }
}

.singleMakaniCard {
  .DetailsMakaniCardBtn {
    svg {
      width: 20px;
    }
  }

  .copyWrapper {
    margin-right: auto;
    margin-left: 16px;
    padding-left: 16px;
    position: relative;
    &:before {
      background-color: #fff;
      content: "";
      height: 60px;
      left: 0;
      position: absolute;
      width: 1px;
    }
  }
  .deatilsMakaniCard {
    height: auto;
  }
  .DetailsMakaniCardBtns {
    padding-bottom: 18px;
    column-gap: 12.49px;
  }
  .MakaniCardHeader {
    padding: 7px 15px;
    overflow: hidden;
    .love {
      width: 20px;
    }
  }
}
.MakaniCardHeader {
  display: flex;
  background: var(--makani-card-header);
  padding: 12px 18px 10px;
  justify-content: space-between;
  border-top-right-radius: 17.1px;
  border-top-left-radius: 17.1px;
  align-items: center;
}
.singleMakaniCard::after {
}
.singleMakaniCardAr {
  max-width: 362.53px;
  right: 55%;
  top: 30%;
  position: absolute;
  z-index: 300;
}
.singleMakaniCardAr::after {
  content: "";
  position: absolute;
  bottom: 0%;
  right: 47%;
  margin-top: 5px;
  border-width: 10px; /* Increased size */
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.MakaniCardContentText {
  display: flex;
  justify-content: space-between;
  padding: 15px 18px 10px;
  color: var(--main-text-color);
  font-size: var(--font-size-16);
  white-space: normal;
}
.MakaniCardContentDesinence {
  color: var(--toggle-item-color);
  font-size: var(--font-size-16);
  span {
    @include apply-font-family(regular);
    font-size: var(--font-size-12);
  }
}

.DetailsMakaniCardBtns {
  display: flex;
  padding: 10px 18px 18.24px;
  justify-content: space-between;
  column-gap: 12.5px;
}
.DetailsMakaniCardBtn {
  background: var(--makani-card-btn);
  width: 43.32px;
  height: 43.32px;
  border-radius: 9.12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigateWarper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--navigate-bg);
  color: var(--navigate-color);
  border-radius: 9.12px;
  flex: auto;
  height: 43.32px;
  gap: 8px;
}

.makaniCardDetails {
  .makaniCardDetailsTitle,
  .makaniCardDetailsAddressTitle,
  .parcelNumberLabel,
  .contactDetailsWrapper p {
    @include apply-font-family(semibold);
  }
  .contactDetailsLabel {
    @include apply-font-family(regular); 
  }
}
.myMakaniCards {
  .makaniCloseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    svg,
    img {
      width: 16px;
    }
  }
}
.CardQrWrapper {
  min-height: 158px;
  background: var(--rectangle);
  width: 148px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 1px -1px;
  background-size: cover;
  flex-shrink: 0;
}

.no-slider {
  .deatilsMakaniCard {
    margin-right: 28px;
    left: -9px !important;
  }
}

// dark theme custom
[data-theme="dark"] {
  .copyWrapper:before {
    background-color: #4e5972 !important;
  }
  .myMakaniCardContent > div {
    img,
    svg {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
    }
  }
}
.copy__btn {
  cursor: pointer;
  &:hover {
  }
}
.navigateWarper {
  cursor: pointer;
}
// arabic mode
[dir="rtl"] {
  .myMakaniCards {
    right: 530px;
    left: unset;
  }
  .MakaniCardHeader .copyWrapper {
    margin-right: 16px;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 16px;
  }
  .MakaniCardHeader .copyWrapper:before {
    left: inherit;
    right: 0;
  }
  .without-slide .deatilsMakaniCard {
    margin-right: -10px;
  }
  @media (max-width: 1281px) {
    .slider-cover-main .searchTitleWarper,
    .slider-cover-main .poiMenu,
    .slider-cover-main .PageHeaderTitleWarper {
      margin: 0 0px;
    }
  }
  @media (max-width: 767px) {
    .slider-cover-main .slick-cards-cover,
    .slider-cover-main .makaniCardDetails {
      padding-left: 20px !important;
    }
    .sideBarContent.inner-content .poiMenu {
      padding-left: 20px;
    }
  }
  .slick-cards-cover .no-slider .deatilsMakaniCard {
    position: static !important;
    margin-left: 36px !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 1379px) {
  .slick-cards-cover .no-slider .deatilsMakaniCard {
    // margin-left: 10px !important;
    position: static;
  }
}
.without-slide {
  .deatilsMakaniCard {
    margin-right: 0;
    // width: 100%;
  }
}

@media (max-width: 1679px) {
  .singleMakaniCard {
    .makaniCardNumber,
    .favoriteItemMakaniNumber {
      font-size: 18px;
    }
  }
  .myMakaniCards {
    max-height: calc(100% - 170px);
    left: 470px;
    top: 155px;
  }
  .streetView {
    left: 465px;
    top: 155px;
    height: calc(100% - 170px);
  }
  [dir="rtl"] {
    .myMakaniCards {
      right: 470px;
    }
  }
  .myMakaniCardBtns {
    column-gap: 10px;
  }
  .myMakaniCardBtns div {
    min-width: auto;
    flex-grow: 1;
    width: 50px;
  }
  .myMakaniCardContent {
    padding-left: 15px;
  }
}
@media (max-width: 1479px) {
  [dir="rtl"] {
    .myMakaniCards {
      right: 450px;
    }
  }
  .myMakaniCards {
    left: 440px;
    top: 150px;
  }
  .streetView {
    left: 435px;
    top: 150px;
    height: calc(100% - 170px);
  }
}

@media (max-width: 1379px) {
  .without-slide {
    .DetailsMakaniCardBtns {
      column-gap: 12px;
      .DetailsMakaniCardBtn {
        flex-grow: 1;
        height: 40px;
        width: 38px;
        svg {
          width: 18px;
        }
      }
    }
    .navigateWarper {
      height: 40px;
      width: 40% !important;
    }
  }
  .no-slider {
    .deatilsMakaniCard {
      margin-right: 20px !important;
    }
  }
  .building-address {
    padding-left: 20px;
    padding-right: 20px;

    .deatilsMakaniCard {
      margin-left: 5px;
    }
  }
}

@media (max-width: 1281px) {
  [data-size="2"] {
    .this-page-spacing .DetailsMakaniPageBtns .navigateWarper {
      // width: 190px !important;
    }
    .navigateTitle {
      // font-size: 16px;
    }
  }

  .no-slider {
    .deatilsMakaniCard {
      margin-right: 20px;
    }
    .MakaniCardHeader .copyWrapper {
      padding-left: 10px;
      margin-left: 10px;
    }
  }
  [dir="rtl"] .slick-cards-cover .no-slider .deatilsMakaniCard {
    margin-left: 20px !important;
  }
  .slick-cards-cover .no-slider .deatilsMakaniCard {
    margin-left: 0;
  }
  .without-slide {
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  .without-slide .deatilsMakaniCard {
    margin-left: 10px;
  }
  .myMakaniCards {
    max-height: calc(100% - 130px);
  }

  .streetView {
    left: 420px;
    top: 127px;
    height: calc(100% - 145px);
  }

  .myMakaniCards .makaniCloseIcon {
    margin-bottom: 3px;
  }
  .myMakaniCard {
    margin: 0 3px;
  }
  .myMakaniCards {
    top: 120px;
    padding-bottom: 15px;
  }
  .slick-cards-cover .no-slider .deatilsMakaniCard {
    margin-right: 20px;
  }

  .myMakaniCards {
    width: 345px;
    left: 425px;
    .makaniCloseIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg,
      img {
        width: 15px;
      }
    }
  }
  [dir="rtl"] {
    .myMakaniCards {
      right: 435px;
    }
  }
  .singleMakaniCard {
    .deatilsMakaniCard {
      position: static;
    }
  }
  .myMakaniCardsWarper {
    gap: 15px;
    .myMakaniCard {
      margin: 0;
    }
  }
}

@media (max-width: 1199px) {
  .streetView {
    left: 360px;
    width: 310px;
  }
  .myMakaniCards {
    left: 375px;
  }
  [dir="rtl"] {
    .myMakaniCards {
      right: 375px;
    }
  }
  .myMakaniCards {
    // width: 33%;

    .makaniCloseIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg,
      img {
        width: 12px;
      }
    }
  }
  .myMakaniCardContent {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 1023px) {
  .myMakaniCards {
    left: 365px;
    top: 128px;
    .makaniCloseIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg,
      img {
        width: 12px;
      }
    }
  }
  [dir="rtl"] {
    .myMakaniCards {
      right: 365px;
    }
  }
  .myMakaniCardContent .makaniNumber {
    font-size: var(--font-size-16);
  }
  .without-slide .MakaniCardHeader .copyWrapper {
    margin-left: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .streetView {
    width: 94.5%;
    left: 2%;
    right: 2%;
    top: 16px;
    height: 350px;
    border-radius: 10px;
    transform: translate(0%, -2%);
  }
  .streetView .gm-fullscreen-control {
    top: 60px !important;
    left: 0px;
  }

  .myMakaniCardsWarper {
    .CardQrWrapper {
      flex-shrink: 0;
    }
    .myMakaniCardHeader {
      overflow: hidden;
    }
    .myMakaniCardContent {
      padding: 30px 0 30px 30px;
    }
    .CardQrWrapper p {
      font-size: var(--font-size-12);
    }
    .myMakaniCardContent .makaniNumber {
      font-size: var(--font-size-24);
    }
    .myMakaniCardContent .title {
      font-size: var(--font-size-13);
      line-height: 1.2;
    }
    .myMakaniCardContent .details {
      font-size: var(--font-size-12-3);
    }
    .myMakaniCard {
      margin: 0;
    }
  }

  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    margin-top: 5px;
  }
}
@media (max-width: 479px) {
  .myMakaniCardsWarper .myMakaniCardContent {
    padding: 25px 0 25px 25px;
  }
  .myMakaniCardsWarper .myMakaniCardContent .makaniNumber {
    font-size: var(--font-size-22);
  }
  .streetView {
    width: 95%;
    height: 220px;
  }
}

[dir="rtl"] {
  @media (min-width: 1200px) {
    [data-size="2"] {
      .streetView {
        right: 588px;
      }
    }
  }
}
