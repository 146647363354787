.POIMenuItemCard {
  background: var(--menu-item-bg);
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.POIMenuItemCards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}

.POIMenuItemCardTitle {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 11.88px;
  text-align: left;
  color: var(--main-text-color);
}

.POIMenuItemCardCategory {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--main-text-color);
}

.POIMenuItemCardNavigate {
  display: flex;
  justify-content: space-between;
}

.POIMenuItemCardNavigateTitle {
  color: var(--title-color);
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 11.88px;
  text-align: right;
}

.POIMenuItemCardNearestBarking {
  color: var(--element-text-color);
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
