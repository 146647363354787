@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.signupWarper {
    background: #000000;
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-radius: 15px;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
      background-color: #4e5972;
    }
  }
  
  .profileInfo .signWarper {
    align-self: flex-end;
  }
  .signWarper{
    .close_btn{
        background: #7A7F97;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    }
  }
  .profileInfo .avater {
    background: var(--third-text-color);
    border-radius: 50%;
    height: 76px;
    width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signUpTitle {
    font-size: var(--font-size-21);
  }
  .settingWarper {
    background: var(--element-light-bg);
    display: flex;
    padding: 10px 15px 10px 10px;
    align-items: center;
    gap: 15px;
    border-radius: 15px;
    justify-content: space-between;
    margin-top: 45px;
    cursor: pointer;
  }
  
  .description {
    color: var(--profile-description);
    font-size: var(--font-size-16);
    font-weight: 300;
    line-height: 1.1;
  }
  .signUpTitle{
    font-weight: 400;
  }
  .profileInfoDetails{
   
    p{
        color: var(--dark-color)
    }
}
.profileInfo{
    margin-top: 30px;
}

.profileInfoDetails p{
  @include apply-font-family(semibold);
  font-size: var(--font-size-20);
}
.signUpTitle{
  @include apply-font-family(secondary-regular);
  letter-spacing: 0.5px;
}
.description{
  @include apply-font-family(regular);
}

[data-theme=dark] {
  .signupWarper{
    border-width: 2.5px !important;
  }
}
.signupWarper{
  height: 50px;
  .signUpTitle{
    
  }
}
.signWarper .close_btn{
  z-index: 9999;
}
 // arabic mode 
 [dir=rtl]{
 .settingWarper {
  padding: 10px 10px 10px 15px;
  }
 }


 
@media(max-width:1679px){
    .profileInfo .avater{
        width: 60px;
        height: 60px;
        img{
            width: 20px;
        }
    }
    .description{
        font-size: var(--font-size-16);
    }
    .signUpTitle {
        font-size: var(--font-size-18);
    }
    .settingWarper{
        margin-top: 30px;
    }
    .signupWarper{
      margin:30px 5% 0 5%;
  }
  .signUpTitle {
    font-size: var(--font-size-14);
}
}
@media(max-width:1281px){
    .signUpTitle {
        font-size: var(--font-size-15);
    }
    .profileInfo .avater {
        width: 50px;
        height: 50px;
    }
    .profileInfo .avater img {
        width: 16px;
    }
    .settingWarper{
        border-radius: 10px;
    }
    .signupWarper{
        margin-top: 20px;
        border-radius: 10px;
    }
  
}

  
  @media screen and (max-width: 767px) {
    .profileInfo p{
      @include apply-font-family(medium); 
    }
    .description{
        margin-top: 30px;
    }
    .signupWrapper {
      padding: 10px 0px;
    //   margin-top: 20px;
    .description{
      margin-left: 5%;
    }
  
    }
    .settingWarper{
        margin-top: 44px;
    }
    .profileInfo {
      background: #333c4f;
      padding:10px 20px;
      margin-top: 0;
      min-height: 124px;
      align-items: center;
      display: flex;
      margin-left: -20px;
      margin-right: -20px;
    }
  
    .profileInfo p {
      color: #ffffff;
    }
    .profileInfoDetails {
      display: flex;
      align-items: center;
      gap: 20px;
    }
   // dark theme custom
[data-theme=dark] {
    .profileInfo {
      background: #1e222b;
    }
  }
    
  }
  

  @media(max-width:479px){
    .signupWarper{
        margin:30px 5% 0 5%;

    }
    .profileInfo p{
        font-size: var(--font-size-18);
    }
    .profileInfo .avater {
        width: 68px;
        height: 68px;
        img{
            width: 23px;
        }
    }
  }