


/* body {
  margin: 0;
  padding: 0;
  font-family: 'VisbyRegular' ,sans-serif;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
