.MakaniCardLogoAr {
  border-left: 1.14px solid #e6e6e6;
  padding-left: 10px;
}
.MakaniCardInfoAr {
  padding-right: 17px;
}
.MakaniCardBtn img {
  width: 20px;
  height: 20px;
}
.MakaniCard {
  height: 82.2px;
}
.makaniCardDistance {
  font-family: VisbyRegular;
  color: var(--title-color);
  font-size: var(--font-size-16);
  line-height: 15.98px;
}
.MakaniCardBtn {
  background-color: var(--makani-card-btns);
  width: 49.96px;
  height: 43.38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.favoriteTitle {
  color: var(--favorite-title);
  font-weight: 400;
  font-size: var(--font-size-13-7);
}
.favoriteItemMakaniNumber {
  color: var(--main-text-color);
  font-family: VisbyRegular;
  font-weight: 400;
  font-size: var(--font-size-18-3);
  line-height: 18.08px;
  margin-bottom: 3px;
}
.ReportCardFooter {
  position: relative;
}
.info {
  padding-bottom: 10px;
}
.ReportCard p {
  width: 80%;
  color: var(--secandary-text-color);
}
.aboutCard,
.settingCard {
  display: flex;
  gap: 20px;
  flex: 1;
  align-items: center;
  background: var(--element-light-bg);
  border-radius: 11px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
  color: var(--secandary-text-color);
}
.ReportCardFooter .arrow,
.searchCard .arrow {
  right: 10px;
  position: absolute;
  bottom: 14px;
  z-index: 100;
  cursor: pointer;
}
.pointOfInternetLoading {
  display: flex;
  justify-content: space-between;
}
.MakaniCardShareBtn {
  z-index: 999;
}
