.searchByLandWarper {
  background: var(--element-light-bg);
  width: 100%;
  height: 43px;
  border-radius: 10px;
  /* padding: 0 10px; */
}

.searchByLandWarper .parcelInput {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  margin: 0 10px;
}

.searchByLandBtnWarper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
}
