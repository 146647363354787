.updateModel {
  width: 300.84px;
  height: 165.1px;
  border-radius: 15px;
  background: #f3f3f1;
  z-index: 700;
  margin-bottom: 10px;
}
.updateModelHeader {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #d1d1d1;
  gap: 65px;
}

.updateModelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
}
.updateModelContent .inputWarper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.updateModelHeader p {
  font-size: var(--font-size-16);
  font-weight: 500;
  line-height: 15.84px;
  text-align: center;
  color: #333c4f;
}

.updateModelHeader .closeWarper {
  width: 25px;
  height: 25px;
  background: #c4c3c3;
  display: flex;
justify-content: center;
  align-items: center;
}
.editInput {
  outline: none;
  border: none;
  width: 90%;
  height: 38px;
  border-radius: 10px;
  padding: 0 10px;
}

.editInput::placeholder {
  color: #c6c6c6;
  font-size: var(--font-size-14);
  font-weight: 500;
  line-height: 13.86px;
}
.saveModelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
  gap: 20px;
}

.saveBtn {
  width: 199px;
  height: 42px;
  border-radius: 8px;
  background: #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveBtn p {
  outline: none;
  color: #ffffff;
}
