.custom-select-search {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    border: none;
    padding: 0 10px;
}

.custom-select-search:focus-visible {
    outline: none;
}

.sideBarContent .searchBuildingInnerContent .is-mobile-screen .select-dropdown {
    height: auto;
}