.AddFavoriteModel .closeWarper {
  width: 25px;
  height: 25px;
  background: #c4c3c3;
  display: flex;
justify-content: center;
  align-items: center;
}
.AddFavoriteModel {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: #ececeb;
}
.AddFavoriteModelHeader {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  flex-direction: row-reverse;
}
.closeFavoriteWarper{
  width: 20px;
  height: 20px;
  background: var(--close-wraper);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img,svg{
    width: 15px;
  }
}
.AddFavoriteModelHeader p {
  font-size: var(--font-size-16);
  font-weight: 600;
  line-height: 15.84px;
  text-align: center;
  color: #333c4f;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.addBtn {
  width: 199px;
  height: 42px;
  border-radius: 8px;
  background: #009d91;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addBtn p {
  outline: none;
  color: #ffffff;
  font-size: var(--font-size-14);
  font-weight: 500;
  line-height: 13.86px;
  text-align: center;
}



