@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}

body {
  @include apply-font-family(regular);
  line-height: 1.1;
}

body.greyscale-mode {
  filter: grayscale(100%);
}

.rootContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.form-control {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  @include apply-font-family(regular);
  // font-size: var(--font-size-10-5);
  // font-weight: 400;
  p {
    line-height: 10.88px;
    color: var(--main-text-color);
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 0.9em;
    height: 0.9em;
    border: 0.05em solid #727272;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
    }

    &:checked::before {
      transform: scale(1);
      background-color: rgb(0, 157, 145);
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
  }
}

.form-control + .form-control {
  margin-top: 1em;
}

// .NoEntrancesFound{
//     font-family: Visby;
//     font-size: var(--font-size-13-6);
//     font-weight: 500;
//     line-height: 1.1;
//     text-align: center;
// }

.IndoorNavigation {
  height: 50px;
  gap: 0px;
  border-radius: 10px;
  background: #4e5972;
  margin: 0 35px;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 15px;
}

[dir="rtl"] {
  .indoor-direction-card {
    right: 40vw;
  }
}

.indoor-direction-card {
  background: linear-gradient(
    99.19deg,
    rgba(4, 202, 187, 0.7) -19.53%,
    rgba(0, 55, 176, 0.7) 139.73%
  );
  width: 250px;
  height: 90px;
  background-color: #000000;
  position: absolute;
  left: 57%;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 20px;
  top: 3vh;
  opacity: 0.7;
  display: flex;
  color: #fff;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .indoor-direction-card {
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
  }
}

// .indoor-direction-card:hover {
//   opacity: 0;
//   visibility: hidden;
// }

.indoor-direction-card .text-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 250px;
  height: 80px;
  justify-content: center;
}

[dir="rtl"] {
  .indoor-direction-card .text-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 250px;
    height: 80px;
    justify-content: center;
  }
}

.indoor-direction-card .from-to {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
}

.indoor-direction-card .destination {
  display: flex;
  align-items: center;
}

.indoor-direction-card .to-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.indoor-direction-card .name {
  font-size: 16px;
  font-weight: bold; /* Make names bolder */
  color: rgba(255, 255, 255, 0.7);
}

.coped-align {
  // position: relative;
}

.copied {
  font-size: 12px;
  line-height: 1.5;
  background-color: #5d6a89;
  border-radius: 5px;
  padding: 5px 8px;
  position: absolute;
  left: 160px;
  top: -9px;
  z-index: 99;
  box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
}
[dir="rtl"] {
  .copied {
    left: inherit;
    right: 160px;
    max-height: 38px;
    width: 50px;
  }
}

// ---

// @font-face {
//   font-family: "VisbyRegular";
//   src: url("../assets/font/visbyregular-webfont.woff2") format("woff2"),
//     url("../assets/font/visbyregular-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "VisbyRegular";
  src: url("../assets/font/Dubai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "VisbyBold";
//   src: url("../assets/font/visbybold-webfont.woff2") format("woff2"),
//     url("../assets/font/visbybold-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "VisbyBold";
  src: url("../assets/font/Dubai-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "visbyextrabold";
//   src: url("../assets/font/visbyextrabold-webfont.woff2") format("woff2"),
//     url("../assets/font/visbyextrabold-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "visbyextrabold";
  src: url("../assets/font/Dubai-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VisbySemiBold";
  src: url("../assets/font/Dubai-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "VisbyMedium";
  src: url("../assets/font/Dubai-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "visbyheavy";
//   src: url("../assets/font/visbyheavy-webfont.woff2") format("woff2"),
//     url("../assets/font/visbyheavy-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "visbyheavy";
  src: url("../assets/font/Dubai-Bold.woff") format("woff");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "VisbyLight";
//   src: url("../assets/font/visbylight-webfont.woff2") format("woff2"),
//     url("../assets/font/visbylight-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "VisbyLight";
  src: url("../assets/font/Dubai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "visbythin";
//   src: url("../assets/font/visbythin-webfont.woff2") format("woff2"),
//     url("../assets/font/visbythin-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "visbythin";
  src: url("../assets/font/Dubai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
// sf pro

// @font-face {
//   font-family: "sf_pro_displaybold";
//   src: url("../assets/font/sfprodisplaybold-webfont.woff2") format("woff2"),
//     url("../assets/font/sfprodisplaybold-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "sf_pro_displaybold";
  src: url("../assets/font/Dubai-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "sf_pro_displaymedium";
//   src: url("../assets/font/sfprodisplaymedium-webfont.woff2") format("woff2"),
//     url("../assets/font/sfprodisplaymedium-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap; /* or auto, block, fallback */
// }

@font-face {
  font-family: "sf_pro_displaymedium";
  src: url("../assets/font/Dubai-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* or auto, block, fallback */
}

// @font-face {
//   font-family: "sf_pro_displayregular";
//   src: url("../assets/font/sfprodisplayregular-webfont.woff2") format("woff2"),
//     url("../assets/font/sfprodisplayregular-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "sf_pro_displayregular";
  src: url("../assets/font/Dubai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: "The_sans_plain";
//   src: url("../assets/font/THESANS_PLAIN.TTF") format("TTF");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "The_sans_bold";
//   src: url("../assets/font/THESANS_BOLD.OTF") format("TTF");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "The_sans_plain";
  src: url("../assets/font/Dubai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "The_sans_bold";
  src: url("../assets/font/Dubai-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "League Spartan";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../assets/font/Dubai-Medium.woff") format("woff");
}

// ---

:root {
  --background-color-light: #ebebeb59;
  --background-color-dark: #333c4f;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --background-color: var(--background-color-light);
  --sidebar-bg-light: #ebebeb59;
  --sidebar-header-bg: #333c4f;
  --text-color: var(--text-color-light);
  --sidebar-bg: linear-gradient(
    135deg,
    rgba(235, 235, 235, 0.7),
    rgba(235, 235, 235, 0.7)
  );
  --sidebar-header-bg: var(--background-color-dark);
  --main-text-color: #333c4f;
  --secandary-text-color: #1e222b;
  --third-text-color: #4e5972;
  --element-bg: #333c4f;
  --element-light-bg: #ffff;
  --element-text-color: #838383;
  --title-color: #009d91;
  --scroll-color: #e3e3e3;
  --line-color: #e2e2e2;
  --line-color2: #c6c6c6;
  --menu-item-bg: #ffffff80;
  --makani-card-btns: var(--element-bg);
  --toggle-border: #d1d1d1;
  --toggle-item-color: var(--title-color);
  --favorite-title: var(--element-bg);
  --user-details: #7a7f97;
  --profile-card: #f9f9f8;
  --profile-card-haver: #4e5972;
  --favorite-icons: var(--element-bg);
  --tabs-border: var(--toggle-border);
  --rectangle: url("../assets/images/makaniCardRectangle.svg");
  --website: #0e0e09;
  --makani-card-details: #838383;
  --menu-item: var(--element-light-bg);
  --lang-toggle-color: #ffffff;
  --poi-bg: var(--element-light-bg);
  --poi-color: var(--third-text-color);
  --hello-guest: var(--third-text-color);
  --profile-description: var(--element-text-color);
  --route-name: #333c4f;
  --street-name: #838383;
  --route-line: #d1d1d1;
  --dot-line: #d1d1d1;
  --dot-bg: #ffffff;
  --makani-card-header: #f6f6f6;
  --makani-card-content: #ffff;
  --navigate-bg: var(--element-bg);
  --makani-card-btn: #d1d1d1;
  --makani-card-btn-active: #4e5972;
  --navigate-color: var(--element-light-bg);
  --drop-down-color: #f1f1f1;
  --drop-down-item: #4e5972;
  --direction-input-wraper: #ffffff;
  --direction-btns: #e6e6e6;
  --recent-search-direction: #f1f1f1;
  --location-placeholder: #c6c6c6;
  --distance-info-car: #4e5972;
  --disanceOnFeet: #d9d9d9;
  --sort--drop-down: #fff import "." #ffff;
  --reponsive-header-wraper: #4e5972;
  --close-wraper: #c4c3c3;
  --dark-color: #4e5972;
  --menu-color: #999da7;
  --menu-bg: #4e5972;
  --tile-icon-color: #333c4f;
  --color-white-gry: #fff;
  --search-color: #c6c6c6;
  --bg-main: #333c4f;
  --color-white-dark: #fff;
  --color-lightGry-darkGry: #838383;
  --color-dark-lightGry: #333c4f;
  --green: #009d91;
  --filler-white: none;
  --vertical-line: #e6e6e6;
  --toggle-bg: #333c4f;
  --box-hover: #eeeeee;
  --round-bg: #333c4f;
  --map-control-color: #333c4f;
  --map-btn-bg: #fff;
  --btn-icon-color: #fff;
  --white-to-light-gry: #fff;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-15: 15px;
  --font-size-14: 14px;
  --font-size-13: 13px;
  --font-size-22: 22px;
  --font-size-12: 12px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16-half: 16.5px;
  --font-size-13-6: 13.6px;
  --font-size-20-5: 20.5px;
  --font-size-17-5: 17.5px;
  --font-size-15-5: 15.5px;
  --font-size-11: 11px;
  --font-size-10-5: 10.5px;
  --font-size-21: 21px;
  --font-size-12-5: 12.5px;
  --font-size-16-5: 16.5px;
  --font-size-18-3: 18.3px;
  --font-size-12-5: 12.5px;
  --font-size-14-5: 14.5px;
  --font-size-13-7: 13.7px;
  --font-size-15-3: 15.3px;
  --font-size-12-3: 12.3px;
  --font-size-30: 30px;
  --font-size-24: 24px;
  --font-size-17-6: 17.6px;
  --modal-bg: #ececeb;
  --modal-border: #d6d6d6;
  --close-btn: #c4c3c3;
  --sort-drop-down: #fff;
  --font-size-control: #d1d1d1;
  --searchResultBg: rgb(255 255 255 / 62%);
  --menu-icon: #999da7;
  --downloadAppbg: #fff;
  --downlaodRighBg: linear-gradient(129.52deg, #333c4f 2.43%, #596479 94.52%);
  --borderline: #e0e0e0;
  --darkcommon: #333c4f;
  --whitebox: rgba(255, 255, 255, 0.5);
  --darktextcolor: rgba(131, 131, 131, 1);
  --boldtextcolor: rgba(51, 60, 79, 1);
  --linecolor: rgba(51, 60, 79, 0.22);
  --bg-share: #fff;
  --share-border: #e9e9e9;
  --lable-box-bg: #e9e9e9;
  --gry-text-color: rgba(51, 60, 79, 0.49);
  --tooltip-bg: #fff;
  --tooltip-color: var(--element-bg);
  --font-primary-regular: "VisbyRegular";
  --font-primary-bold: "VisbyBold";
  --font-primary-extrabold: "visbyextrabold";
  --font-primary-semibold: "VisbySemiBold";
  --font-primary-medium: "VisbyMedium";
  --font-primary-heavy: "visbyheavy";
  --font-primary-light: "VisbyLight";
  --font-primary-thin: "visbythin";

  --font-secondary-bold: "sf_pro_displaybold";
  --font-secondary-medium: "sf_pro_displaymedium";
  --font-secondary-regular: "sf_pro_displayregular";

  --font-tertiary-regular: "The_sans_plain";
  --font-tertiary-bold: "The_sans_bold";
  --font-league-spartan-regular: "League Spartan";
}

// Map of font sizes
$data-sizes: (
  "16": 16px,
  "17": 17px,
  "15": 15px,
  "14": 14px,
  "13": 13px,
  "22": 22px,
  "12": 12px,
  "20": 20px,
  "18": 18px,
  "16-half": 16.5px,
  "13-6": 13.6px,
  "20-5": 20.5px,
  "17-5": 17.5px,
  "15-5": 15.5px,
  "11": 11px,
  "10-5": 10.5px,
  "21": 21px,
  "12-5": 12.5px,
  "18-3": 18.3px,
  "14-5": 14.5px,
  "13-7": 13.7px,
  "15-3": 15.3px,
  "12-3": 12.3px,
  "30": 30px,
  "24": 24px,
  "17-6": 17.6px,
);

@mixin generate-font-sizes($increment: 0px) {
  @each $key, $value in $data-sizes {
    --font-size-#{$key}: #{$value + $increment};
  }
}

[data-size="0"] {
  @include generate-font-sizes();
}

[data-size="1"] {
  @include generate-font-sizes(2px);
}
[data-size="2"] {
  @include generate-font-sizes(4px);
}

[data-size="-1"] {
  @include generate-font-sizes(-1px);
}
[data-size="-2"] {
  @include generate-font-sizes(-2px);
}

[data-theme="dark"] {
  --sidebar-bg: #333c4f;
  --element-light-bg: #1e222b;
  --secandary-text-color: #ffffff;
  --title-color: #7a7f97;
  --main-text-color: #ffffff;
  --element-bg: #1e222b;
  --line-color2: #7a7f9780;
  --line-color: #3c4457;
  --menu-item-bg: #1e222b;
  --element-text-color: #ffffff;
  --makani-card-btns: #4e5972;
  --sidebar-header-bg: #1e222b;
  --scroll-color: #4e5972;
  --toggle-border: #4e5972;
  --toggle-item-color: #009d91;
  --favorite-title: #4e5972;
  --profile-card: #1e222bb2;
  --profile-card-haver: ;
  --favorite-icons: #4e5972;
  --tabs-border: #7a7f97;
  --rectangle: url("../assets/images/makaniCardRectangleDark.svg");
  --website: #ffffff;
  --makani-card-details: #4e5972;
  --menu-item: #ffffff;
  --lang-toggle-color: var(--secandary-text-color);
  --poi-bg: #333c4f;
  --poi-color: #cfd5f2;
  --hello-guest: #cfd5f2;
  --profile-description: #cfd5f2;
  --route-name: #4e5972;
  --street-name: #4e5972;
  --route-line: #333c4f;
  --dot-line: #333c4f;
  --dot-bg: #4e5972;
  --makani-card-header: #242a36;
  --makani-card-content: #333c4f;
  --makani-card-btn: #4e5972;
  --navigate-bg: #4e5972;
  --navigate-color: #ffffff;
  --drop-down-color: #4e5972;
  --drop-down-item: #cfd5f2;
  --direction-input-wraper: #1e222b;
  --direction-btns: #4e5972;
  --recent-search-direction: #1e222b;
  --location-placeholder: #4e5972;
  --distance-info-car: #1e222b;
  --disanceOnFeet: #4e5972;
  --sort--drop-down: #1e222b;
  --reponsive-header-wraper: #1e222b;
  --close-wraper: #7a7f97;
  --dark-color: #cfd5f2;
  --menu-color: #1e222b;
  --menu-bg: #333c4f;
  --tile-icon-color: #a7bbe7;
  --color-white-gry: #a7bbe7;
  --search-color: #4e5972;
  --bg-main: #a7bbe7;
  --color-white-dark: #1e222b;
  --color-lightGry-darkGry: #4e5972;
  --color-dark-lightGry: #4e5972;
  --filler-white: brightness(0) invert(1);
  --vertical-line: #485269;
  --toggle-bg: #333c4f;
  --toggle--border: #1e222b;
  --box-hover: #4e5972;
  --round-bg: #333c4f;
  --map-control-color: #cfd5f2;
  --map-btn-bg: #333c4f;
  --modal-bg: #394256;
  --modal-border: #6e768f;
  --close-btn: #4e5972;
  --sort-drop-down: #333c4f;
  --font-size-control: #4e5972;
  --searchResultBg: #445069eb;
  --menu-icon: #999da7;
  --downloadAppbg: #333c4f;
  --downlaodRighBg: #4e5972;
  --borderline: rgba(122, 127, 151, 0.5);
  --darkcommon: #ffffff;
  --whitebox: rgba(30, 34, 43, 1);
  --darktextcolor: #ffffff;
  --boldtextcolor: #ffffff;
  --linecolor: rgba(51, 60, 79, 1);
  --bg-share: #1f222b;
  --share-border: #333c4f;
  --lable-box-bg: #333c4f;
  --gry-text-color: rgba(255, 255, 255, 0.49);
  --tooltip-bg: #1e222b;
  --tooltip-color: #cfd5f2;
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.sideBarContent {
  padding: 35.77px 40px;

  overflow-y: auto !important;
  background: var(--sidebar-bg);

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  @media (max-width: 767px) {
    transition: position 0.3s, top 0.3s;
    overflow-y: hidden !important;
  }
  // height: 100vh !important;

  // @media (max-width: 2048px) {
  //   min-height: 76vh !important;
  //   height: 81vh !important;
  //   max-height: 100vh !important;
  // }

  // @media (max-width: 1920px) {
  //   min-height: 76vh !important;
  //   height: 79vh !important;
  //   max-height: 100vh !important;
  // }

  // @media (max-width: 1710px) {
  //   min-height: 75vh !important;
  //   height: 76.3vh !important;
  //   max-height: 100vh !important;
  // }

  // @media (max-width: 1679px) {
  //   min-height: 75vh !important;
  //   height: 79vh !important;
  //   max-height: 100vh !important;
  // }

  // @media (max-width: 1281px) {
  //   min-height: 50vh !important;
  //   height: 85vh !important;
  //   max-height: 100vh !important;
  // }

  // @media (max-width: 763px) {
  //   min-height: 42vh !important;
  //   // height: 45vh !important;
  //   max-height: 78vh !important;
  // }

  // @media (max-height: 750px) {
  //   min-height: 50vh;
  //   height: 100%;
  //   max-height: 66vh;
  // }

  // @media (max-height: 610px) {
  //   min-height: 50vh;
  //   height: 100%;
  //   max-height: 60vh;
  // }

  // @media (max-height: 510px) {
  //   min-height: 50vh;
  //   height: 100%;
  //   max-height: 52vh;
  // }

  // @media (max-height: 450px) {
  //   min-height: 30vh;
  //   height: 100%;
  //   max-height: 45vh;
  // }

  // @media (max-height: 385px) {
  //   min-height: 30vh;
  //   height: 100%;
  //   max-height: 38vh;
  // }
}

.compass-button {
  // position: absolute;
  // bottom: 20px;
  // right: 20px;
  // width: 50px;
  // height: 50px;
  // background-color: white;
  // // border-radius: 50%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  // transition: transform 0.3s ease;
  // z-index: 100;
  // font-size: 24px;
}

.sideBarContentChild {
  // height: 100%;
  // height: auto !important;

  // min-height: 50vh !important;
  // height: auto !important;
  // max-height: 6.5vh !important;

  @media (max-width: 767px) {
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 5px;
    // min-height: 100vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  // overflow-y: scroll;
}

// .gm-style-cc {
//   right: 10px !important;
//   left: unset !important;
//   position: absolute !important;
// }

.validationError {
  color: red;
  font-size: 12px;
  padding: 10px 0 0 0;
}

.copy_right {
  color: #838383;
  text-align: center;
  // width: 100%;
  font-size: var(--font-size-10-5);

  @media (min-width: 752px) {
    margin-top: auto;
    margin-bottom: -25px;
    padding-top: 10px;
    padding-bottom: 10px;
    // position: absolute;
    // bottom: 1px;
    // margin-bottom: 0;
  }
  // margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 10px;
  // padding-bottom: 10px;
  // margin-bottom: 2rem;
}
[data-theme="dark"] {
  .copy_right {
    color: #ffffff;
  }
}

.__floater {
  visibility: unset !important;
  opacity: unset !important;
}

.myMakaniCardsWarper {
  height: calc(100% - 191px);
  width: 20%;
}

.top-spacing {
  padding-top: 25px;
}

/* WebKit (Chrome, Safari) */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--scroll-color);
}

::-webkit-scrollbar-thumb {
  background: #333c4f4d;
  border-radius: 10px;
}

/* Firefox */
/* Set width and color */
* {
  scrollbar-width: thin;
  scrollbar-color: #333c4f4d var(--scroll-color);
}

/* Track color */
*::-moz-scrollbar-track {
  background: var(--scroll-color);
}

/* Thumb color */
*::-moz-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: 10px;
}

.sideBar {
  width: 499.15px;
  height: 100vh;
  position: absolute;
  z-index: 100;

  // height: 100%;
  // border: 1px solid rgba(235, 235, 235, 0.35);
  // box-shadow: 0px 0px 65.54px 0px #00000036;
}
.sideBarContent ul {
  list-style: none;
  padding: 0;
}

.zoom-controls-ar {
  top: 50%;
  position: absolute;
  left: 35px;
  @media (max-width: 767px) {
    display: none;
  }
}
.allowAccessDesc {
  font-size: var(--font-size-15) !important;
}

.allowAccess {
  height: 177px !important;
}
.zoom-controls {
  position: absolute;
  // top: 50%;
  bottom: 19%;
  right: 2.2rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    bottom: 51%;
    right: 1rem;
    display: none;
  }

  // @media (max-width: 595px) {
  //   bottom: 48%;
  //   right: 1rem;
  // }
}

div.map-layer-ar {
  @media (max-width: 767px) {
    // bottom: 40% !important;
  }
  @media (max-width: 579px) {
    // bottom: 394px !important;
  }
}

.nearestMakaniPopUp {
  width: 145.88px;
  height: 45.88px;
  border-radius: 5.88px;
  background: #0037b0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  color: #ffffff;
  position: absolute;
  right: 45%;
}
.react-joyride__beacon {
  display: none !important;
}
.nearestMakaniPopUp .makaniNumber {
  font-size: 16.47px;
  font-weight: 500;
  line-height: 16.31px;
}
.nearestMakaniNumberWraper {
  cursor: pointer;
  display: flex;
  gap: 12px;
}

.nearestMakaniPopUp .title {
  // font-size: 14.12px;
  // font-weight: 400;
  // line-height: 13.98px;
}

.map-layer {
  // position: absolute;
  // bottom: 99px;
  // right: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--map-btn-bg) !important;
  color: var(--map-control-color) !important;
  border-radius: 8px;
  width: 46.75px;
  height: 46.75px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img,
  svg {
    width: 20px;
  }
  @media (max-width: 767px) {
    // bottom: 46% !important;
    svg {
      width: 18px;
    }
  }
  // @media (max-width: 590px) {
  //   bottom: 394px !important;
  // }
}
.current-location-button {
  img,
  svg {
    width: 20px;
  }
}

.zoom-btn {
  img,
  svg {
    width: 25px;
  }
}
.weather span,
.weather-ar span {
  font-size: 28.56px;
  font-weight: 600;
  line-height: 28.27px;
  text-align: right;
  color: #4e5972;
}

.directionMap-ar {
  position: absolute;
  right: 530px;
  bottom: 33px;
  background: #4e5972;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  img {
    width: 30px;
    position: relative;
    right: -2px;
  }

  .pointOfInternetContent .pointOfInternetTitle {
    cursor: pointer;
  }

  @media (max-width: 1679px) {
    width: 50px;
    height: 50px;
    border-radius: 13px;
    right: 480px;
    img {
      width: 25px;
    }
  }
  @media (max-width: 1199px) {
    right: 390px;
  }
  @media (max-width: 1023px) {
    right: 355px;
  }
  @media (max-width: 767px) {
    // display: none;
    bottom: 20rem !important;
    right: 1rem;
  }
}
.POIMap {
  left: 530px;
  padding-right: 2%;
}

.directionMap {
  position: absolute;
  left: 530px;
  bottom: 36px;
  background: #4e5972;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  img {
    width: 30px;
    position: relative;
    right: -2px;
  }

  @media (max-width: 767px) {
    position: absolute;
    // top: 10%;
    left: 5% !important;
    bottom: 40% !important;

    // margin-right: 500rem;
    // background: #4e5972;
    // width: 60px;
    // height: 60px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border-radius: 12px;
    img {
      width: 30px;
      position: relative;
      right: -2px;
    }
  }
}
.weather {
  position: absolute;
  top: 40px;
  right: 25px;
  width: 107.11px;
  height: 51.67px;
  border-radius: 15.35px;
  background-color: var(--map-btn-bg) !important;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  padding: 11px;
  @media (max-width: 767px) {
    top: 30px;
  }
}

.weather-ar {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 107.11px;
  height: 51.67px;
  border-radius: 15.35px;
  background-color: var(--map-btn-bg) !important;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    top: 30px;
  }
}
.map-layer-ar {
  // position: absolute;
  // bottom: 100px;
  // left: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--map-btn-bg) !important;
  color: var(--map-control-color) !important;
  border-radius: 8px;
  width: 46.75px;
  height: 46.75px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    bottom: 39%;
    svg {
      width: 15px;
    }
  }
}
.current-controls-ar {
  position: absolute;
  // bottom: 16x;
  // left: 40px;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    bottom: 250px !important;
    display: flex;

    .current-location-button {
      svg {
        width: 15px;
      }
    }
  }
  @media (max-width: 780px) {
    bottom: 20rem !important;
  }
}

.zoomButtonInMap {
  @media (max-width: 767px) {
    // bottom: 250px !important;
    display: none !important;
  }
}

.current-controls {
  position: absolute;
  right: 2.5rem;
  bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;

  @media (max-width: 767px) {
    // bottom: 250px !important;
    display: flex;
    right: 1rem;

    .current-location-button {
      svg {
        width: 18px;
      }
    }
    @media (max-width: 590px) {
      bottom: 40% !important;
    }
  }
}
.current-controls-ar {
  bottom: 36px;
  column-gap: 16px;
}
.current-location-button {
  background-color: var(--map-btn-bg) !important;
  color: var(--map-control-color) !important;
  border: 2px solid #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 47.31px;
  height: 47.31px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-button {
  background-color: var(--map-btn-bg) !important;
  color: var(--map-control-color) !important;
  border: 2px solid #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 8px 5px;
  padding: 10px;
  text-align: center;
  width: 47.31px;
  height: 47.31px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clicked-text-parent {
  z-index: 50;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(to right, #04cabbb2, #0037b0b2);
  // background: #333c4f;
  border-radius: 5px;
  // height: 6rem;
  width: 15rem;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  /*   overflow: hidden; */
  white-space: nowrap;
}

[dir="rtl"] {
  .clicked-text-parent {
    background-image: linear-gradient(to left, #04cabbb2, #0037b0b2);
  }
}

.clicked-text-parent[data-position="left"]:after {
  left: 10%;
}

.clicked-text-parent[data-position="right"]:after {
  right: 10%;
  left: auto;
}

// .clicked-text-parent:after {
//   content: "";
//   position: absolute;
//   bottom: -15px;
//   border-style: solid;
//   border-width: 15px 15px 0;
//   border-color: #4cc0ca transparent;
//   display: block;
//   width: 0;
//   z-index: 1;
// }

// .clicked-text-parent[data-position="left"]:after {
//   left: 10%;
// }

// .clicked-text-parent[data-position="right"]:after {
//   right: 10%;
//   left: auto;
// }

@media (max-width: 768px) {
  .clicked-text-parent {
    height: 4rem;
    width: 10rem;
  }
}

/* Prevent the tooltip from going off-screen horizontally */
.clicked-text-parent.right-edge {
  right: 0;
  left: auto;
}

.clicked-text-parent.left-edge {
  left: 0;
  right: auto;
}

.rtl .clicked-text-parent.right-edge {
  left: 100;
  right: auto;
}

.rtl .clicked-text-parent.left-edge {
  right: 100;
  left: auto;
}

// .clicked-text-parent.right-edge:after {
//   left: auto;
//   right: 10%;
// }

// .clicked-text-parent.left-edge:after {
//   left: 10%;
// }

.clicked-text {
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}

.clicked-text-button-div-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clicked-text-button-div-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clicked-text-button-div-left > button {
  height: 46%;
  z-index: 10;
  background-color: #333c4f;
  color: white;
  font-weight: 500;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 8px;
}

.clicked-text-button-div-right > button {
  height: 46%;
  // width: 4.5rem;
  z-index: 10;
  background-color: #333c4f;
  color: white;
  font-weight: 500;
  border: none;
  // border-radius: 0px 0px 10px;
  // margin: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  padding: 5px 8px;
  @media (max-width: 768px) {
    padding: 1px 1px;
  }
}

.clicked-text-button-div > button:nth-child(even) {
  border-bottom-right-radius: 5px;
}

[dir="rtl"] {
  .clicked-text-button-div > button:first-child {
    border-top-left-radius: 5px;
  }

  // .clicked-text-button-div > button:last-child {
  //   border-bottom-left-radius: 5px;
  // }
}

.gm-svpc {
  display: none;
}
// dark theme custom
[data-theme="dark"] {
  .noAccess {
    color: #939cc7;
  }
  .searchByBuildingBtnActive {
    color: #fff !important;
  }
  .signupWarperHome {
    background-color: #000;
    .signUpTitle {
      color: #fff;
    }
    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
    }
  }

  .entrance {
    background-color: #333c4f;
    color: #cfd5f2;
  }
  .searchByBuildingBtn,
  .searchByBuildingBtnActive {
    color: rgb(198, 198, 198);
  }
  .download-app-card-cover .download-app-card .btn-close {
    background-color: #c4c3c3;
  }
  .zoom-button,
  .current-location-button {
    border: 0;
  }
  .font-controller .font-size-row .f-size {
  }
  .collapsible {
    .arrowMenuWarper {
      img,
      svg {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
        opacity: 0.5;
      }
    }
  }
  .recentSearchAddress,
  .recentSearchDestination {
    color: #939cc7;
  }
  .collapsibleMenuItemWrapper {
    img,
    svg {
      -webkit-filter: invert(2) sepia(0.5) saturate(3) hue-rotate(184deg);
      filter: invert(2) sepia(0.5) saturate(3) hue-rotate(184deg);
      opacity: 0.4;
    }
  }

  .download-app-card-cover .download-app-card .right-vector {
    background: url(../assets//images/half-circle-dark.svg);
    background-size: cover;
  }
  .directionBtns .directionBtn svg {
    color: #fff;
  }
  .directionBtn {
    background: #4e5972;
  }
  .AddFavoriteModel {
    background-color: #1e222b;
  }
  .AddFavoriteModelHeader {
    border-bottom: 1px solid var(--share-border);
    p {
      color: var(--main-text-color);
    }
  }
  .AddInput {
    background-color: var(--lable-box-bg);
  }
  .closeFavoriteWarper {
    background-color: rgb(51, 60, 79);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-sm-otr {
  background-color: #ddd;
  border-radius: 10px;
  width: 132px;
  position: absolute;
  right: 0;
  z-index: 9;
  top: -16px;
  .sortDropDown {
    background: var(--sort-drop-down);
    padding: 0px 0;
    height: auto;
    width: 100%;
    ul {
      column-gap: 0;
      li {
        padding: 12px 13px;
        height: 42px;
      }
    }
    input[type="radio"] {
      border: 2px solid #ccc;
      background: #d1d1d1;
      border-radius: 50%;
      outline: none;
      width: 14px;
      height: 14px;
      accent-color: rgb(0, 157, 145);
    }
  }
}

.without-slide {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.directionPointWarper {
  position: relative;
}
// side bar

.contactDetailsWrapper {
  p {
    display: inline-flex;
    align-items: center;

    .arrow-link {
      color: #009d91;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }
}

.search-result-cover {
  background-color: var(--searchResultBg);
  margin-top: 10px;
  padding: 20px 17px 17px 17px;
}

.download-app-card-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.197);
  z-index: 99999;
  .download-app-card {
    max-width: 560px;
    width: 100%;
    min-height: 258.13px;
    display: flex;
    position: absolute;
    background-color: var(--downloadAppbg);
    border-radius: 30px;
    overflow: hidden;
    left: 50%;
    transform: translate(-12%, 0);
    img {
      max-width: 100%;
    }
    .lft-content {
      padding: 38.77px 0 27px 35.77px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .top-side {
        strong {
          font-size: var(--font-size-20-5);
          line-height: 1.1;
          @include apply-font-family(bold);
          color: var(--secandary-text-color);
          margin-bottom: 10px;
          display: inline-flex;
        }
        p {
          color: #9ba4ab;
          font-size: var(--font-size-13);
          line-height: 1.1;
          margin-bottom: 10px;
          min-height: 60px;
        }
      }
      .download-group {
        margin-top: auto;

        .app-stors {
          display: flex;
          column-gap: 28px;
          .apps {
            width: 102px;
            display: flex;
            flex-direction: column;
            column-gap: 10px;
            img {
              width: 100%;
            }
          }
          .qr-code {
            width: 71px;
          }
        }
      }
    }
    .right-vector {
      width: 265px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 9;
      background: url(../assets/images/half-circle.svg) no-repeat;
      background-size: cover;
    }

    .btn-close {
      flex-shrink: 0;
      margin-left: auto;
      width: 25px;
      height: 25px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--close-btn);
      color: #424c60;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 99;
      &:hover {
        background-color: #dee0e3;
      }
      img,
      svg {
        width: 18px;
      }
    }
  }
}

.no-makani-found {
  font-size: 14px;
  color: var(--darktextcolor);
}
[data-theme="dark"] {
  .no-makani-found img {
    -webkit-filter: invert(0.3) sepia(1) saturate(2) hue-rotate(189deg);
    filter: invert(0.3) sepia(1) saturate(2) hue-rotate(189deg);
  }
}

.distanceInfoOnFeetHour,
.distanceInfOnCarHour {
  // font-size: 16px !important;
  strong {
    @include apply-font-family(semibold);
    font-size: var(--font-size-16);
  }
}

.nort-btn {
  svg {
    position: relative;
    left: 2px;
    top: 2px;
  }
}

.directionPointWarper {
  .directionInputWarper.start_point {
    border-bottom: 1px solid #e2e2e2;
  }
  .directionInputWarper.Add_destination {
    padding-top: 18px !important;
  }
}

[data-theme="dark"] {
  .directionPointWarper .directionInputWarper.start_point {
    border-color: #7a7f9780;
  }
}

.delete-m-card {
  background: #ececeb;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  margin-bottom: 10px;

  .d-head {
    position: relative;
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 10px;
    color: #333c4f;

    span {
      font-size: var(--font-size-16);
      @include apply-font-family(semibold);
      margin-left: auto;
    }

    .close_btn {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: auto;

      background-color: #c4c3c3;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 18px;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .body-content {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    strong {
      display: block;
      font-size: var(--font-size-20);
      margin-bottom: 10px;
      @include apply-font-family(medium);
      color: var(--main-text-color);
    }

    p {
      font-size: var(--font-size-12);
      line-height: 19px;
      color: #666;
    }

    .btn__brp {
      display: flex;
      justify-content: flex-end;
      column-gap: 13px;
      margin-top: 20px;
      .btn-m {
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: var(--font-size-14);
        flex-grow: 1;
        background-color: #d1d1d1;
        color: #fff;
        transition: 0.2s;
        @include apply-font-family(regular);
        &.btn-cancel {
          background: #d1d1d1;

          &:hover {
            background: #c0bfbf;
          }
        }

        &.btn-delete {
          background: #e74c3c;
          color: #fff;

          &:hover {
            background: #c0392b;
          }
        }
      }
    }
  }
}

[data-theme="dark"] {
  .delete-m-card {
    background-color: #1e222b;
    border: 1px solid #4e5972;
  }
  .delete-m-card .d-head .close_btn {
    background-color: #424c60;
  }

  .delete-m-card .d-head {
    border-color: #4e5972;
  }
  .delete-m-card .d-head,
  .delete-m-card .body-content strong {
    color: #cfd5f2;
  }
  .delete-m-card .body-content p {
    color: #939cc7;
  }
  .delete-m-card .body-content .btn__brp .btn-m.btn-cancel {
    background-color: #4e5972;
  }
  .POIMap {
    .slick-prev:before,
    .slick-next:before {
      color: #4e5972;
    }
  }
}

// slick slider poi & recent search
.poi-recent-slide {
  .slick-list {
    height: 45px;
    margin-bottom: 20px;
  }
  .slick-slide {
    > div {
      margin-right: 10px;
    }
  }
  .pointOfInternetItemSearchSuggestions {
    display: inline-flex !important;
    margin-bottom: 0;
    span {
      white-space: normal !important;
      line-height: 1.1;
    }
    .pointOfInternetIcon {
      flex-shrink: 0;
    }
  }
}

.deleteFavoriteLocation {
  width: 100%;
  // display: flex;
  // justify-content: center;
  min-width: auto;
  min-height: 60.59px;
  height: auto;
  border-radius: 9.15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin-left: 15px;
}
@media (max-width: 767px) {
  .deleteFavoriteLocation {
    margin-left: 0px;
    margin-right: 0px;
    width: 107%;
  }
}
// -----------
.POIMap {
  position: absolute;
  bottom: 35px;
  width: 60%;
  left: 640px;

  .slick-slider {
    .slick-slide {
      > div {
        margin-right: 10px;
      }
    }
    .pointOfInternetItemSearchSuggestions {
      display: inline-flex !important;
      margin-bottom: 0;
      span {
        white-space: normal !important;
        line-height: 1.1;
      }
      .pointOfInternetIcon {
        flex-shrink: 0;
      }
    }
  }
}

.POIMap {
  .slick-list {
    height: 38px;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #4e5972;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

// slick slider poi & recent search  end
@media (max-width: 1850px) {
  .POIMap {
    width: 55%;
    left: 640px;
  }
}

.settingsContent {
  // overflow: hidden !important;
  height: auto;
}

.arrowright {
  cursor: pointer;
  svg {
    width: 8px;
    color: #4e5972;
  }
}
.POIMap {
  .slick-prev,
  .slick-next {
    width: 60px;
    height: 60px;
  }
}

[dir="ltr"] {
  .POIMap {
    .slick-prev:before {
      content: "←";
      position: absolute;
      left: 50%;
      transform: translate(-100%, -50%);
    }
    .slick-next {
      right: -50px;
    }
    .slick-prev {
      left: -50px;
    }
  }
}

[dir="rtl"] {
  .slick-prev {
    right: -60px;
  }
  .slick-next {
    left: -50px;
  }
}
[data-theme="dark"] {
  .arrowright svg {
    color: #9da1ac;
    opacity: 1 !important;
    filter: none !important;
  }
}
.__floater {
}
.react-joyride__tooltip {
  border-radius: 17px !important;
  font-size: var(--font-size-18-3) !important;
  line-height: 1.2;
  z-index: 0;
  [data-action="skip"] {
    border-radius: 27px !important;
    font-size: 13px !important;
    line-height: 1;
    padding: 8px 15px !important;
    appearance: none;
    @include apply-font-family(medium);
    min-width: 70px;
    transition: 0.2s;
    &:hover {
      background-color: #009d91 !important;
    }
  }

  [aria-label="Next"],
  [data-action="back"] {
    font-size: 13px !important;
    padding: 8px 15px !important;
    @include apply-font-family(medium);
    border-radius: 27px !important;
    transition: 0.2s;
    &:hover {
      background-color: #009d91 !important;
    }
  }
  [aria-label="التالي"],
  [aria-label="رجوع"] {
    font-size: 13px !important;
    padding: 10px 15px !important;
    @include apply-font-family(medium);
    border-radius: 27px !important;
    transition: 0.2s;
    margin: 0 5px !important;
  }

  [data-test-id="button-primary"] {
    font-size: 13px !important;
    padding: 8px 15px !important;
    @include apply-font-family(medium);
    border-radius: 27px !important;
    transition: 0.2s;
    &:hover {
      background-color: #009d91 !important;
    }
  }

  [aria-label="Close"] {
    display: none !important;
  }
}

[data-theme="dark"] {
  .react-joyride__tooltip {
    background-color: #4e5972 !important;
  }
  .react-joyride__tooltip [data-test-id="button-primary"],
  .react-joyride__tooltip [data-action="skip"] {
    background-color: #1e222b !important;
    &:hover {
      background-color: #009d91 !important;
    }
  }
  .__floater__arrow polygon {
    fill: #333c4f !important;
  }
}

.zoom-controls-svg {
  position: absolute;
  top: 50%;
  right: 35px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    top: 54%;
    right: 15px;
  }
}

.zoom-controls-svg-ar {
  position: absolute;
  top: 50%;
  left: 35px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    top: 54%;
    right: 15px;
  }
}

@media (max-width: 1679px) {
  .download-app-card-cover .download-app-card {
    max-width: 495.72px;
    min-height: 200px;
  }
  .download-app-card-cover .download-app-card .lft-content .top-side p {
    min-height: 40px;
  }
  .nort-btn {
    svg {
      top: 0px;
      left: 1;
      width: 16px !important;
    }
  }

  .map-layer img,
  .map-layer svg {
    width: 16px;
  }

  .zoom-controls-ar {
    left: 18px;
  }
  .map-layer-ar,
  .current-controls-ar {
    // left: 23px;
    column-gap: 10px;
  }
  .current-controls-ar,
  .directionMap-ar,
  .POIMap {
    bottom: 30px;
  }
  .map-layer-ar {
    bottom: 76px;
    svg {
      width: 16px;
    }
  }

  .download-app-card-cover .download-app-card .lft-content .top-side strong {
    font-size: var(--font-size-18);
    margin-bottom: 6px;
  }
  .download-app-card-cover .download-app-card .lft-content {
    padding: 30px 10px 30px 30px;
  }
  .current-location-button img,
  .current-location-button svg {
    width: 20px;
  }
  .current-location-button img,
  .current-location-button svg {
    width: 20px;
  }
  .download-app-card-cover .download-app-card .right-vector {
    width: 220px;
  }
  .download-app-card-cover .download-app-card img {
    max-width: 100%;
    width: 100px;
  }
  .map-layer {
    width: 35px;
    height: 35px;
    // right: 30px;
    // right: 2.5rem;
    // bottom: 85px;
  }
  .current-controls,
  .map-layer {
    // right: 27px;
    // right: 1.4rem;
    column-gap: 10px;
  }
  .map-layer-ar {
    width: 35px;
    height: 35px;
    @media (max-width: 767px) {
      bottom: 400px !important;
    }
  }
  .zoom-button,
  .current-location-button {
    width: 35px;
    height: 35px;
  }
  .weather {
    min-width: 50px;
    min-height: 25.58px;
    // border-radius: 7px;
    padding: 8px;
  }
  .weather-ar {
    width: 50px;
    height: 25.58px;
    border-radius: 7px;
  }
}

.locationInput {
  color: var(--third-text-color);
  @include apply-font-family(regular);
  font-size: var(--font-size-15);
  font-weight: 400;
  line-height: 15.78px;
}

.locationInput::placeholder {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.78px;
  color: var(--location-placeholder);
}
.custom-select-warper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.directionPointWarper {
  // min-width: 402.82px;
  min-height: auto;
  background: var(--direction-input-wraper);
  border-radius: 9.15px;
  padding-bottom: 10px;
  padding-top: 5px;
}
.directionInputWarper {
  display: flex;
  padding: 8px 15px !important;
  gap: 15px;
  align-items: center;
  background: var(--direction-input-wraper) !important;
  margin-bottom: 0 !important;
}
.directionInputWarper input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: var(--secandary-text-color) !important;
}
.directionInputWarper input ::placeholder {
  @include apply-font-family(regular);
  font-size: var(--font-size-17);
  font-weight: 400;
  line-height: 15.82px;
  color: #c6c6c6;
}

.POIMenuItemLogo img {
  width: 30px;
}
.distanceInfOnCarHour {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 18.03px;
  color: #ffffff;
  white-space: nowrap;
  span {
    @include apply-font-family(semibold);
    padding: 0 !important;
  }
}
.dist-otr {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-top: 3px;
  // margin: auto;
}
.location {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.76px;
  color: #4e5972;
  background: transparent;
}
.distanceInfo.distanceInfOnCar img {
  position: relative;
  bottom: -5px;
  margin-right: 10px;
}
.distanceInfo.distanceInfOnCarNotActive img {
  position: relative;
  bottom: -5px;
  margin-right: 10px;
}

.distanceInfoWarper {
  display: flex;
  width: 100%;
}
.direction-outer {
  display: flex;
  width: 100%;
  column-gap: 10px;
  .distanceInfo {
    flex-grow: 1;
  }
}
.mapPlaceHolder {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.82px;
  color: #c6c6c6;
}
.SuggestionsDirection {
  background: var(--recent-search-direction);
  padding: 1px 15px;
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
}
.directionBtns {
  column-gap: 12.55px;

  .directionBtn {
    transition: 0.2s;
    &:hover {
      background-color: #009d91 !important;
      svg {
        color: #fff !important;
      }
    }
    svg {
      color: #333c4f;
    }
  }
}

.MakaniCardContentDesinence {
  .km-bold {
    @include apply-font-family(semibold);
    ~ span {
      font-size: var(--font-size-12);
    }
  }
}

.directionBtn {
  background: #d1d1d1;
  height: 47.93px;
  border-radius: 7.32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 75px;
  img,
  svg {
    color: #d1d1d1;
  }
}
.directionBtnStart {
  width: 227.08px;
  height: 47.93px;
}

.copyWrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.makaniCardNumber,
.favoriteItemMakaniNumber {
  color: var(--main-text-color);
  @include apply-font-family(regular);
  font-weight: 400;
  font-size: var(--font-size-18-3);
  line-height: 18.08px;
  margin-bottom: 3px;
  white-space: nowrap;
}
.myMakaniCardBtns div,
.DetailsMakaniCardBtn {
  transition: 0.2s;
  &:hover {
    background-color: #5d6a89;
    color: #fff;
  }
}
.navigateWarper {
  transition: 0.2s;
  &:hover {
    transition: 0.2s;
    background-color: #5d6a89;
    color: #fff;
  }
}
.profileWrapperLeft + div {
  transition: 0.2s;
}

.collapsible {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: var(--font-size-15);
  transition: background-color 0.3s ease;
}

.POIMenuItem {
  display: flex;
  gap: 15px;
  align-items: center;
  border-top: 1px solid var(--line-color2);
  padding: 9px 0;
}
.POIMenuItemTitle {
  @include apply-font-family(semibold);
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  color: var(--main-text-color);
}
.collapsibleMenuItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.collapsibleMenuItemWrapper {
  min-height: 54px;
  background: var(--menu-item-bg);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  row-gap: 25px;
}
.collapsibleMenuItemWrapperCol {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.collapsibleMenuItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.content.active {
  max-height: 9000px;
}

.MenuItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.MenuItemCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recentSearchDirection {
  background: var(--recent-search-direction);
  padding: 15px;
  padding-top: 20px;
  display: none;
  z-index: 200;
}
.pointOfInternetSearchSuggestions {
  display: flex;
  // overflow-x: scroll;
  gap: 15px;
  // flex-wrap: wrap;

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: lightgrey;
  }
}
.pointOfInternetItemSearchSuggestions {
  display: flex;
  align-items: center;
  gap: 6px;
  background: var(--poi-bg);
  border-radius: 50px;
  padding-right: 14px;
  cursor: pointer;
  .pointOfInternetIcon {
    width: 38.11px;
    height: 38.11px;
    img {
      max-width: 100%;
    }
  }
}

.POIMap {
  .pointOfInternetSearchSuggestions {
    .pointOfInternetItemSearchSuggestions {
      margin-bottom: 0;
    }
  }
}

.searchSuggestions {
  // margin-top: 15px;
  .recentSearch {
    padding-top: 15px !important;
  }
}
.pointOfInternetSearchSuggestions {
  margin-bottom: 0px;
  overflow-y: hidden;
}
.pointOfInternetItemSearchSuggestions {
  color: var(--poi-color);
  margin-bottom: 15px;
  box-shadow: 0px 0px 10.89px 0px #0000001a;
}

.loading {
  padding: 10px 0;
}

.pointOfInternetItemSearchSuggestions span {
  font-size: 13.07px;
  font-weight: 400;
  line-height: 12.94px;
  @include apply-font-family(semibold);
}

.recentSearchers {
  margin-top: 0px;
}

.recentSearchesWarper {
  margin-top: 10px;
  .recentSearch {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.recentSearchesWarper .recentSearch:last-child {
}

.recentSearchersTitle {
  color: var(--title-color);
  margin-bottom: 0px;
  font-size: var(--font-size-14-5);
  line-height: 14.37px;
  @include apply-font-family(semibold);
}
.recentSearch {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 13px 0;
  border-bottom: 1px solid var(--line-color);
  cursor: pointer;
}

.recentSearchTitle {
  font-size: var(--font-size-13);
  font-weight: 500;
  line-height: 1.1;
  color: var(--main-text-color);
  @include apply-font-family(semibold);
}
.recentSearchLocation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// ---
.distanceInfo.distanceInfOnCar,
.distanceInfo.distanceInfoOnFeetActive,
.distanceInfoOnFeetHour {
  color: #fff;
}
.distanceInfo.distanceInfoOnFeet {
  color: #7a7f97;
}
.distanceInfo.distanceInfOnCarNotActive {
  color: #7a7f97;
  .distanceInfOnCarHour,
  .distanceInfOnCarDistance {
    color: #7a7f97;
  }
}
.distanceInfo.distanceInfoOnFeetActive {
  .distanceInfoOnFeetHour,
  .distanceInfo.OnFeetDistance {
    color: #fff;
  }
}

// ---

.recentSearchAddress {
  color: var(--third-text-color);
  font-size: var(--font-size-13-7);
  line-height: 1.1;
}
.recentSearchDestination {
  color: #7a7f97;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.56px;
}
.pointOfInternetType {
  display: flex;
  gap: 5px;
  align-items: center;
}
.shoppingIcon {
  width: 16px;
  height: 16px;
  background: var(--element-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.side-fav {
  box-shadow: 0px 0px 74.4px 7px #00000040;
  border-radius: 9.15px !important;
}
.pointOfInternetTypeTitle {
  color: var(--element-text-color);
  @include apply-font-family(regular);
  font-size: var(--font-size-13);
  font-weight: 400;
  line-height: 17.28px;
}
.directionBtns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  z-index: -1;
}

.distanceInfOnCar {
  background: var(--distance-info-car);
  display: flex;
  border-radius: 7.31px;
  align-items: center;
  justify-content: center;
}

.distanceInfOnCarNotActive {
  background: var(--disanceOnFeet);
  display: flex;
  border-radius: 7.31px;
  align-items: center;
  justify-content: center;
}
.distanceInfOnCarDistance {
  @include apply-font-family(regular);
  font-size: 13.66px;
  font-weight: 400;
  line-height: 13.53px;
  color: #ffffff;
  margin-top: 2px;
  text-align: left;
}
.distanceInfoWarper .distanceInfo {
  text-align: left;
}
.distanceInfo {
  height: 47.82px;
  border-radius: 7.3px;
}

.distanceInfoOnFeet {
  background: var(--disanceOnFeet);
  display: flex;
  align-items: center;
  border-radius: 7.31px;
  justify-content: center;
  gap: 10px;
}
.distanceInfoOnFeetActive {
  background: var(--distance-info-car);
  display: flex;
  align-items: center;
  border-radius: 7.31px;
  justify-content: center;
  gap: 10px;
}

.distanceInfoOnFeetHour {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 18.03px;
  color: #7a7f97;
  display: flex;
}

.distanceInfoOnFeetDistance {
  @include apply-font-family(regular);
  font-size: 13.66px;
  font-weight: 400;
  line-height: 13.53px;
  color: #7a7f97;
  text-align: center;
}
.distanceInfoWarper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .direction-outer {
    margin-bottom: 20px;
  }
  .directionSettingsWarper {
    margin-bottom: 10px;
  }
}
.directionSettingsWarper {
  padding-right: 10px;
}

.distanceInfoOnFeet {
  text-align: center;
  padding: 0 10px;
  column-gap: 0px;
  position: relative;
  .hrs-grp {
    // margin-left: 10px;
  }
  .icon_distance {
    // width: 40px;
    flex-shrink: 0;
    position: static;
    left: 20px;
    // margin-right: 10px;
  }
  .distanceInfo {
    height: auto;
    width: auto;
    color: #7a7f97;
    font-size: var(--font-size-13);
  }
}

.distanceInfoOnFeetActive {
  text-align: center;
  padding: 0 10px;
  column-gap: 0px;
  position: relative;
  .hrs-grp {
    // margin-left: 10px;
  }
  .icon_distance {
    // width: 40px;
    flex-shrink: 0;
    position: static;
    left: 20px;
    // margin-right: 10px;
  }
  .distanceInfo {
    height: auto;
    width: auto;
    color: #7a7f97;
    font-size: var(--font-size-13);
  }
}
.distanceInfo {
  .sm {
    font-size: var(--font-size-12);
  }
}
.distanceInfoWarper {
  column-gap: 12px;
  .distanceInfo {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    column-gap: 10px;
    display: flex;
    justify-content: center;
  }
  .directionSettingsWarper {
    margin-left: 10px;
  }
}

.signWarper,
.closeWarper {
  // display: flex;
  // top: 4%;
}

.aboutUsCloseWarper {
  top: 6%;
}

.dist-txt-otr {
  display: flex;
  flex-direction: column;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000b5;
  z-index: 999;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s; /* For Safari and older versions of Chrome */
  -moz-transition: all 0.5s ease 0s; /* For older versions of Firefox */
  -o-transition: all 0.5s ease 0s; /* For older versions of Opera */
}
.menu {
  z-index: 9999 !important;
}

.pageTitle p {
  font-size: var(--font-size-22);
  color: var(--main-text-color);
  @include apply-font-family(bold);
}

.collapsibleMenuItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.MenuItemCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input[type="radio"] {
  border: 2px solid #ccc;
  background: #d1d1d1;
  border-radius: 50%;
  outline: none;
  width: 20.5px;
  height: 20.5px; /* Set the desired height */
  accent-color: var(--title-color);
}
.collapsibleMenuItemWrapper {
  min-height: 54px;
  background: var(--menu-item-bg);
  width: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  @include apply-font-family(semibold);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  color: var(--main-text-color);
}

// toggle swich
.setting-toggle-container {
  display: flex;
  overflow: hidden;
  border: 1px solid var(--toggle-border);
  color: #7a7f97;
  height: 26px;
  border-radius: 13px;
  flex-shrink: 0;
}

.setting-toggle-button {
  flex: 1;
  min-width: 82.9px;
  height: 24px;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 13px;
  display: flex;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 2;
  text-align: center;
}
.setting-toggle-button.active {
  background-color: #009d91;
  color: #ffff;
}

// toggle common

.toggle-container {
  display: flex;
  overflow: hidden;
  border: 1.3px solid var(--toggle--border);
  color: var(--menu-item);
  height: 30px;
  border-radius: 23px;
  max-width: 170px;
  background-color: var(--toggle-bg);
}

.toggle-button {
  flex: 1;
  height: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 23px;
  display: flex;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 11.88px;
  text-align: center;
}
.toggle-button.active {
  background-color: #009d91;
  color: #ffff;
}

.backPage {
  img,
  svg {
    transition: 0.2s;
  }
  &:hover {
    img,
    svg {
      position: relative;
      margin-left: -5px;
    }
  }
}

// font

.font-info {
  font-size: 10px;
  line-height: 1.3;
  color: #838383;
  @include apply-font-family(regular);
}
.font-controller {
  column-gap: 15px;
  display: flex;
  align-items: center;
  @include apply-font-family(semibold);
  .f-small {
    color: #7a7f97;
    font-size: 10%;
  }
  .f-large {
    font-size: var(--font-size-16);
    color: #7a7f97;
  }
  .font-size-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &:after {
      content: "";
      background-color: var(--font-size-control);
      position: absolute;
      height: 2px;
      width: 100%;
    }
    .f-size {
      background-color: var(--font-size-control);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      cursor: pointer;
      &.active {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #009d91;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.pageHeaderIcons {
  .bk-btn {
    cursor: pointer;
    color: var(--white-to-light-gry);
  }
}
.dragline {
  width: 20px;
  flex-shrink: 0;
  color: var(--tabs-border);
}
.backPage {
  flex-shrink: 0;
  cursor: pointer;
}

// modal styles

.madal-cover {
  position: fixed;
  top: 0;
  left: 160px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.475);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    max-width: 362px;
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: calc(100% - 3.5rem);
    position: relative;

    .modal-content {
      background-color: var(--modal-bg);
      border-radius: 15px;
      .modal-header {
        padding: 0 20px;
        display: flex;
        align-items: center;
        min-height: 50px;
        border-bottom: 1px solid var(--modal-border);
        .modal-title {
          display: flex;
          justify-content: center;
          width: 100%;
          text-align: center;
          color: var(--secandary-text-color);
          @include apply-font-family(semibold);
          font-size: var(--font-size-16);
        }
        .btn-close {
          flex-shrink: 0;
          margin-left: auto;
          width: 25px;
          height: 25px;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--close-btn);
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color: #333c4f;
          }
          img,
          svg {
            width: 18px;
          }
        }
      }
      .modal-body {
        padding: 20px 22px 26px;
        .layers-list {
          display: flex;
          column-gap: 30px;
          justify-content: center;
          .layer-bx-otr {
            text-align: center;
            .layer-bx {
              width: 140.32px;
              height: 82.3px;
              border-radius: 8px;
              overflow: hidden;
              margin-bottom: 10px;
              cursor: pointer;

              &:hover {
              }
              img,
              svg {
                width: 100%;
                max-width: 100%;
                transition: 0.2s;
              }
            }

            &.active {
              .layer-bx {
                border: 3px solid transparent;
                -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 157, 145, 1);
                -moz-box-shadow: 0px 0px 0px 1px rgba(0, 157, 145, 1);
                box-shadow: 0px 0px 0px 1px rgba(0, 157, 145, 1);
              }
              p {
                color: #009d91 !important;
                @include apply-font-family(semibold);
              }
            }
            p {
              color: var(--element-text-color);
              font-size: var(--font-size-15-5);
            }
          }
        }
        .root-info {
          border-radius: 8px;
          background: var(--element-light-bg);
          overflow: hidden;
          padding-right: 13.37px;
          display: flex;
          min-height: 70.47px;
          margin-top: 30px;
          margin-bottom: 26.5px;
          align-items: stretch;
          .lft-info {
            width: 70.54px;
            flex-shrink: 0;
            overflow: hidden;
            display: flex;
            svg,
            img {
              max-width: 100%;
            }
          }
          .right-content {
            margin-left: 14.36px;
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            .inr-content {
              padding-right: 20px;
              strong {
                color: var(--secandary-text-color);
                @include apply-font-family(semibold);
                font-size: var(--font-size-15);
                margin-bottom: 4px;
                display: inline-block;
              }
              p {
                color: var(--element-text-color);
                font-size: var(--font-size-12);
              }
              .switch-otr {
                margin-left: auto;
              }
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        .primary-btn {
          min-width: 199px;
          height: 42px;
          border-radius: 8px;
          border: 0;
          cursor: pointer;
          transition: 0.2s;
          @include apply-font-family(semibold);
          font-size: 14px;
          &.save-btn {
            background-color: #009d91;
            color: #fff;
            &:hover {
              background-color: #333c4f;
            }
          }
        }
      }
    }
  }
}

.myMakaniCards {
  .updateModel {
    border: 1px solid #d1d1d1;
    margin-bottom: 25px;
  }
}

.share-drop-down {
  border-radius: 13px;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 437px;
  width: 100%;
  transform: translate(-4%, -50%);
  z-index: 999;

  .btn-close {
    align-items: center;
    background-color: rgba(51, 60, 79, 1);
    border: 0;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    justify-content: center;
    margin-left: auto;
    transition: 0.2s;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9;
    &:hover {
      background-color: #333c4f;
    }
    svg {
      width: 20px;
    }
  }
  .share-box {
    position: relative;
  }
  .share-head {
    position: relative;
    text-align: center;
    font-size: 16px;
    min-height: 56px;
    @include apply-font-family(medium);
    border-bottom: 1px solid var(--share-border);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--main-text-color);
      background-color: var(--bg-share);
      position: relative;
      z-index: 9;
      padding: 0 10px;
    }
  }
  .share-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
    padding: 0 27px;
  }
  .share-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(51, 60, 79, 0.49);
    font-size: 12px;
    p {
      color: var(--gry-text-color);
    }
    .share-circle {
      margin-bottom: 2px;
      img {
        // width: 54px;
      }
    }
  }
  .copy-link-row {
    margin-top: 35px;
    font-size: 12px;
    padding: 0 27px 33px;
    .label {
      color: var(--gry-text-color);
      margin-bottom: 10px;
    }
    .copy-link {
      background-color: var(--lable-box-bg);
      padding: 17px 25px 17px 12px;
      border-radius: 10px;
      display: flex;
      .copy {
        color: #333c4f;
        margin-left: auto;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
      p {
        color: var(--gry-text-color);
      }
    }
  }
}

.makanicard-details-popup {
  max-width: 444px;
  width: 100%;
  min-height: 400px;
  background: var(--sidebar-bg);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 9999;
  border-radius: 18px;
  padding: 20px;
  .flag-point {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -50px;
    z-index: 9;
    width: 21px;
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid rgb(255 255 255 / 41%);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -20px;
  }
  .btn-close {
    flex-shrink: 0;
    margin-left: auto;
    width: 22px;
    height: 22px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #424c60;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    color: #fff;
    &:hover {
      background-color: #5d6a89;
      color: #fff;
    }
    svg {
      width: 15px;
    }
  }
  .m-card-top {
    .title-md {
      color: var(--main-text-color);
      @include apply-font-family(semibold);
      font-size: 17.15px;
      line-height: 23px;
    }
    p {
      font-size: var(--font-size-13-7);
      line-height: 1.1;
      color: var(--element-text-color);
      @include apply-font-family(regular);
    }
  }
  .contactDetailsLabel {
    display: flex;
  }
  .button-group {
    display: flex;
    column-gap: 10px;
    margin-top: 16px;
    margin-bottom: 40px;
    position: relative;
    .MakaniCardContentDesinence {
      position: absolute;
      right: 0;
      top: -30px;
      @include apply-font-family(semibold);
    }
    .md-btn {
      background: var(--makani-card-btn);
      width: 43px;
      height: 43.32px;
      border-radius: 9.12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      border: 0;
      color: var(--secandary-text-color);
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        background-color: #5d6a89;
        color: #fff;
      }
      svg {
        width: 23px;
      }
      &.logo-btn {
        background: var(--makani-card-btn) !important;
        width: 122px;
        img {
          width: 76px;
        }
      }

      &.navg-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--navigate-bg);
        color: var(--navigate-color);
        border-radius: 9.12px;
        width: 105px;
        gap: 5px;
        &:hover {
          background-color: #5d6a89 !important;
        }
      }
    }
  }

  .arrow-link {
    color: #009d91;
    margin-left: 10px;
  }
  .contact-link {
    color: var(--element-text-color);
    text-decoration: none;
    font-size: var(--font-size-13-6);
  }
  .icon-md {
    margin-right: 10px;
    width: 21px;
    height: 21px;
  }
  .makaniCardDetailsTitle {
    @include apply-font-family(semibold);
    font-size: 15px;
    color: var(--main-text-color);
  }
  .makaniCardDetailsAddressTitle {
    @include apply-font-family(medium);
  }
  .contactDetailsWrapper {
    span {
      padding-right: 3px;
    }
  }
}

.file-input .placeholder {
  font-size: 12px !important;
}
.file-input .placeholder {
  color: var(--main-text-color) !important;
  @include apply-font-family(regular);
}
.qr-error {
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-top: 3px;
  color: var(--main-text-color);
}
// arabic mode
[dir="rtl"] {
  .distanceInfOnCarDistance {
    text-align: right;
  }
  .madal-cover {
    left: unset;
    right: 160px;
    @media (max-width: 991px) {
      left: 0;
      right: 0;
    }
  }
  .download-app-card-cover {
    left: auto;
    right: 0;
  }
  .download-app-card-cover .download-app-card {
    @media (max-width: 1679px) {
      max-width: 495.72px !important;
      min-height: 200px;
      .right-vector {
        width: 190px;
      }
    }
    @media (max-width: 1281px) {
      max-width: 400px !important;
      min-height: auto;
    }
  }
  .share-drop-down {
    transform: translate(4%, -50%);
    right: 50%;
    left: unset;
    @media (max-width: 1679px) {
      right: 60%;
      left: unset;
    }
    @media (max-width: 1479px) {
      right: 64%;
      left: unset;
    }
    @media (max-width: 1281px) {
      transform: translate(0%, -50%);
      right: 66.3%;
      max-width: 350px;
    }
    @media (max-width: 1199px) {
      max-width: 315px;
      right: 69.3%;
    }
  }

  .download-app-card-cover .download-app-card .right-vector {
    background: url(../../src/assets/images/half-circle-arb.svg) no-repeat;
    background-size: cover;
    display: flex;
    width: 273px;
    background-position: right;
  }
  .weather,
  .map-layer,
  .current-controls {
    left: 25px;
    right: inherit;
  }

  .current-controls,
  .zoom-controls {
    left: 20px;
    right: inherit;
  }
  .directionMap {
    left: auto;
    right: 27%;
  }

  .download-app-card-cover .download-app-card {
    direction: rtl;
    left: inherit;
    right: 50%;
    transform: translate(5%, 0);
    max-width: 577px;
  }

  .download-app-card-cover .download-app-card .lft-content {
    padding: 40px 40px 40px 0px;
  }
  .download-app-card-cover .download-app-card .btn-close {
    right: auto;
    left: 20px;
  }

  .makanicard-details-popup {
    .MakaniCardContentDesinence {
      left: 0;
      right: auto;
    }
  }
  .makanicard-details-popup .icon-md {
    margin-right: 0;
    margin-left: 10px;
  }
  .contactDetailsWrapper p .arrow-link svg,
  .makanicard-details-popup .arrow-link svg {
    margin-left: 0px;
    margin-right: 5px;
    transform: rotate(180deg);
    margin-bottom: -4px;
  }

  .POIMap {
    left: auto;
    right: 600px;
    width: 55%;
  }

  .download-app-card-cover .download-app-card img {
    height: auto;
  }

  @media (max-width: 1679px) {
    .POIMap {
      width: 58%;
      right: 580px;
    }
    .download-app-card-cover .download-app-card .right-vector {
      width: 230px;
    }

    .download-app-card-cover .download-app-card .lft-content {
      padding: 30px 30px 30px 10px;
    }
    .share-drop-down {
      // transform: translate(-80%, -50%);
    }
  }
  @media (max-width: 1560px) {
    .POIMap {
      width: 53%;
      right: 580px;
    }
  }
  @media (max-width: 1479px) {
    .POIMap {
      width: 51%;
      right: 580px;
    }
  }
  @media (max-width: 1367px) {
    .POIMap {
      width: 48%;
      right: 590px;
    }
  }
  @media (max-width: 1281px) {
    .POIMap {
      width: 47%;
      right: 550px;
    }
    .download-app-card-cover .download-app-card .right-vector {
      width: 190px;
    }
    .download-app-card-cover .download-app-card .lft-content {
      padding: 20px 20px 20px 10px;
    }
    .download-app-card-cover .download-app-card .btn-close {
      left: 15px;
    }
  }

  @media (max-width: 1199px) {
    .POIMap {
      width: 46%;
      right: 460px;
    }
  }
  @media (max-width: 1023px) {
    .POIMap {
      right: 450px;
    }
  }
  @media (max-width: 991px) {
    .madal-cover {
      left: 0;
      right: 0;
    }

    .POIMap {
      right: 460px;
      width: 39%;
      .slick-next {
      }
    }
    .share-drop-down {
      transform: translate(-90%, -50%);
    }
  }
  @media (max-width: 890px) {
    .POIMap {
      right: 450px;
      width: 35%;
    }
  }
  @media (max-width: 768px) {
    .POIMap {
      right: 40px;
      width: 85%;
    }
    .download-app-card-cover {
      background: rgb(0 0 0 / 32%);
      justify-content: center;
      align-items: center;
    }
    .download-app-card-cover .download-app-card .right-vector {
      width: 150px;
      background-position: right;
    }
    .download-app-card-cover .download-app-card {
      right: unset;
      transform: none;
    }
    .share-drop-down {
      transform: translate(50%, -50%);
      max-width: 380px;
      top: 26%;
      right: 50%;
    }
  }
  @media (max-width: 726px) {
    .POIMap {
      right: 50px;
      width: 83%;
    }
  }
}

// arabic mode End

.clear-search {
  width: 21px;
  height: 21px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--close-btn);
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  right: 10px;
  z-index: 9;
  img,
  svg {
    width: 15px;
  }
}

.clear-search-indoor-nav {
  width: 18px;
  height: 18px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--close-btn);
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  flex-shrink: 0;
  right: 75px;
  z-index: 9;
  img,
  svg {
    width: 15px;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.headerMenuMobile {
  align-items: center;
  padding: 0 35px;
  gap: calc(50% - 60px);
  display: none;
  background: #333c4f;
  width: 100%;
  height: 65.55px;
  position: absolute;
  top: 0;
}

.makaniCardDetailsSubAddress {
  .arrow-link {
    position: relative;
    top: 3px;
  }
}

.makanicard-details-popup .arrow-link {
  display: inline-flex;
  align-items: center;
}
.logo-in-card {
  max-width: 76px !important;
}
.AddInput::placeholder {
  color: #c6c6c6;
  font-size: var(--font-size-14);
  line-height: 13.86px;
  @include apply-font-family(regular);
}

.AddFavoriteContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 18px;
  gap: 13px;
}
.AddInput {
  outline: none;
  border: none;
  height: 38px;
  border-radius: 10px;
  padding: 0 10px;
  width: 100%;
}
.input-cover {
  display: flex;
  width: 100%;
}
.information-text {
  font-size: var(--font-size-14);
  line-height: 1.1;
  margin-bottom: -4px;
  color: var(--line-color2);
  padding-top: 0 !important;
}

.tooltip-wrp {
  position: absolute;
  background-color: #0037b0;
  min-width: 150px;
  min-height: 45px;
  border-radius: 5.88px;
  padding: 7px 9px 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
  top: -50px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  align-items: flex-start;
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #0037b0;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    bottom: -7px;
  }

  .title {
    font-size: var(--font-size-14);
    @include apply-font-family(light);
    line-height: 1.1;
  }

  .makaniNumber {
    font-size: var(--font-size-16-5);
    line-height: 1.1;
    @include apply-font-family(medium);
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
    white-space: nowrap;
    span {
      margin-top: 2px;
      cursor: pointer;
    }
  }
}

.custom-select-otr {
  position: relative;
  .custom-select-search {
    @include apply-font-family(regular);
    height: 41px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--element-light-bg);
    margin-bottom: 7px;
    border-radius: 7.32px;
    font-size: var(--font-size-12);
    font-weight: 400;
    line-height: 11.94px;
    color: var(--secandary-text-color);
    padding-left: 40px;
    &::placeholder {
      opacity: 0.5;
    }
  }
  .search-icon {
    position: absolute;
    left: 22px;
    top: 58%;
    transform: translate(-50%, -50%);
    color: var(--search-color);
    z-index: 9;
    svg {
      width: 15px;
    }
  }
}
.custom-select.active .select-dropdown {
  border-top-left-radius: 0;
}
//  spinner
.spin-wrap {
  border-radius: 50%;
  display: flex;
}
.center-point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(0, 55, 176, 1);
  border: 2.35px solid #fff;
  -webkit-box-shadow: 0px 0px 11px 1px rgba(0, 194, 255, 1);
  -moz-box-shadow: 0px 0px 11px 1px rgba(0, 194, 255, 1);
  box-shadow: 0px 0px 11px 1px rgba(0, 194, 255, 1);
}

.spinner {
  &-wrap {
    position: relative;
    display: inline;
    border-radius: 50px;
  }

  &-item {
    background-color: rgba(57, 52, 206, 0.11);
    border-radius: 50%;
    height: 90px;
    width: 90px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: zoom 2s linear 0.75s infinite;
    display: flex;
    align-items: center;
    justify-content: center;

    &--2 {
      animation-delay: 1.25s;
    }

    &--3 {
      animation-delay: 1.75s;
    }
  }
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.2);
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.2);
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.copy-link .copy:hover {
  opacity: 0.5;
}
[data-theme="dark"] {
  .copy-link .copy {
    color: #fff !important;
  }
  .spinner-item {
    background-color: rgba(57, 52, 206, 0.37);
  }
  .DetailsMakaniCardBtn,
  .md-btn {
    .share__icon {
      fill: #d1d1d1;
      stroke: #333c4f;
    }
    &:hover {
      .share__icon {
        fill: #fff;
        stroke: #333c4f;
      }
    }
  }
  .logo-in-card {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
  .makanicard-details-popup:after {
    border-top-color: var(--sidebar-bg);
  }
  .favouritePlaceSelected {
    .favouritePlaceLogo {
      background-color: #7382a1;
    }
  }
}

.DetailsMakaniCardBtn,
.md-btn {
  .share__icon {
    fill: #333c4f;
    stroke: #d1d1d1;
  }
  &:hover {
    .share__icon {
      fill: #fff;
      stroke: #333c4f;
    }
  }
}

.d-flex-cstm {
  display: flex;
  .love {
    transition: 0.2s;
    &:hover {
      color: #eb5364;
    }
  }
}

.spacing-inner {
  padding: 22.33px 20px;
}

// .inner-spacing-01 {
//   padding: 22px 35px;
// }

.entrance {
  color: #4e5972;
}
.horizontal-slide {
  column-gap: 10px;

  .slick-list {
    padding-top: 15px;
    padding-bottom: 10px;
    column-gap: 10px;
  }
  .entrance {
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10.98px 0px #0000001a;
    min-width: auto !important;
    margin: 0 5px;
    color: #4e5972;
    font-size: var(--font-size-13);
    @include apply-font-family(semibold);
  }
  .horizontal-otr {
  }
  .slick-track {
    min-width: 100%;
  }
}
[data-theme="dark"] {
  .horizontal-slide .entrance {
    background-color: #333c4f;
    color: #cfd5f2;
  }
}

// drag functions

.favoritesContent {
  overflow-y: visible !important;
  @media (max-width: 767px) {
    overflow-y: auto !important;
    min-height: 5rem;
    max-height: 100vh;
  }
}

.pageHeaderIcons {
  display: flex;
  gap: 18px;
  align-items: center;
  .pageTitle {
  }
  margin-bottom: 28px;
}

.sideBar {
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
}

.this-page-spacing {
  .PageHeaderTitleWarper,
  .searchTitleWarper,
  .MakaniPageContent,
  .makaniCardDetails {
    margin: 0;
  }
  .MakaniPageContentText {
    padding: 0;
  }
  .DetailsMakaniPageBtns {
    padding-left: 0;
    padding-right: 0;
  }
}

// Report Makani Plate Damage

.form__box {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  @include apply-font-family(regular);
  .form-group {
    .formcontrol {
      border-radius: 10px;
      padding: 10px 12.5px;
      min-height: 38px;
      background-color: #fff;
      display: inline-flex;
      align-items: center;
      width: 100%;
      font-size: var(--font-size-14);
      @include apply-font-family(regular);
      border: 0;
      outline: none;
      position: relative;
      transition: 0.2s;
      &:focus {
        border-color: #333c4f;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(51 60 79 / 5%);
      }
      &::placeholder {
        color: #c6c6c6;
      }
    }
    &.mb {
      margin-bottom: 13px;
    }
    .location-point-otr {
      justify-content: space-between;

      .location-point {
        align-items: center;
        display: inline-flex;
        column-gap: 7px;
        color: #c6c6c6;
        cursor: pointer;
        span {
          color: #4e5972;
        }
      }
      .m-text {
        background-color: transparent;
        border: 0;
        color: #c6c6c6;
        cursor: pointer;
      }
    }
  }

  .file-upload-bx {
    position: relative;
    border: 1px dashed #b1b1b1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 141px !important;
    background-color: #fff;
    cursor: pointer;
    .upload-and-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c6c6c6;
      gap: 10px;
      svg {
        color: #333c4f;
      }

      .file-input {
        position: absolute;
        top: 0;
        .file-input__label {
          width: 100%;
          height: 100%;
          right: 0;
          position: absolute;
          opacity: 0;
        }
        .placeholder {
          font-size: var(--font-size-12) !important;
          position: absolute;
          bottom: 6px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 15px;
        }
        .file-input__input {
          inset: auto;
        }
      }
    }
  }
  .btnsend {
    width: 150px;
    margin: 18px auto 0 auto;
    height: 42px;
    background: var(--makani-card-btn);
    color: var(--element-light-bg);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: none;
    cursor: pointer;
    font-size: var(--font-size-14);
    &:hover {
      opacity: 0.9;
    }
    &.active {
      background-color: #009d91;
    }
  }
}

.disabled-btn {
  pointer-events: none;
}

.myMakaniCards .updateModel .saveBtn {
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}
// dark theme custom
[data-theme="dark"] {
  .form__box .form-group .formcontrol:focus {
    box-shadow: 0 0 0 0.25rem rgb(67, 79, 103);
  }
  .myMakaniCards .updateModel {
    background: #1e222b;
    border-color: #4e5972;
    .editInput {
      background-color: #4e5972;
      color: #cfd5f2;
    }
  }
  .updateModelHeader {
    border-color: #4e5972;
  }
  .updateModelHeader p {
    color: #cfd5f2;
  }
  .updateModelHeader .closeWarper {
    background: #424c60;
  }
  .custom-select-otr .custom-select-search {
    &::placeholder {
      color: #cfd5f2ab;
    }
  }
  .form__box {
    background-color: #1e222b;
  }
  .form__box .form-group .formcontrol {
    background-color: #333c4f;
  }
  .file-upload-bx {
    border-color: #485269 !important;
  }
  .form__box .file-upload-bx .upload-and-content svg,
  .form__box .form-group .location-point-otr .location-point span {
    color: #c6c6c6;
  }
  .form__box .form-group .formcontrol,
  .form__box .btnsend {
    color: #ffffff;
    &::placeholder {
      opacity: 0.5;
    }
  }
  .searchByLandWarper .parcelInput {
    color: rgb(198, 198, 198);
  }
}

.searchByBuildingBtnActive {
  background-color: #009d91 !important;
  &:hover {
    opacity: 0.9;
  }
}

.download-app-card-cover
  .download-app-card
  .lft-content
  .download-group
  .app-stors
  .apps
  img,
.download-app-card-cover .download-app-card img {
  height: auto;
}

.sideBarContent {
  display: flex;
  flex-direction: column;
}

@media (max-width: 2569px) {
  .delete-m-card.loginPopUp {
    left: 60%;
  }

  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 40%;
  }
}

@media (max-width: 1679px) {
  .delete-m-card.loginPopUp {
    left: 65%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 35%;
  }
  .POIMap {
    width: 62%;
  }
  .sideBarContent.inner-content.inner-article {
    padding: 28px 20px !important;
  }
  .AddFavoriteModel {
    .addBtn {
      height: 38px;
    }
  }
  .zoom-button {
    margin-bottom: 0;
  }
  .share-drop-down {
    // transform: translate(-20%, -50%);
    left: 60%;
  }

  .sideBar,
  .menu {
    width: 450px;
  }
  .sideBarContent {
    padding: 28px;
    // height: calc(100% - 155px);
  }
  .directionMap {
    left: 480px;
  }
  .POIMap {
    left: 580px;
    width: 58%;
  }

  .backPage {
    width: 37px;
    height: 37px;
  }
  .pageTitle p {
    font-size: var(--font-size-20);
  }
  .directionBtnStart {
    width: auto;
    flex-basis: 125px;
  }
  .directionMap {
    width: 50px;
    height: 50px;
    bottom: 30px;
    img {
      width: 25px;
    }
  }
  .current-controls {
    bottom: 30px;
  }
  .map-layer {
    // bottom: 76px;
  }
}
@media (max-width: 1570px) {
  .delete-m-card.loginPopUp {
    left: 65%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 35%;
  }

  .POIMap {
    width: 55%;
  }
}
@media (max-width: 1479px) {
  .delete-m-card.loginPopUp {
    left: 65%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 35%;
  }

  .share-drop-down {
    left: 64%;
  }

  .directionMap {
    left: 443px;
    bottom: 20px;
  }
  .POIMap {
    left: 540px;
    width: 56%;
  }
  .current-location-button img,
  .current-location-button svg {
    width: 16px;
  }
  .sideBarContent {
    // height: calc(100% - 150px);
  }
  .directionBtnStart {
    flex-basis: 100px;
  }
  .searchCards .searchCard {
    flex: 1;
  }
  .sideBar,
  .menu {
    // width: 420px;
  }
}
@media (max-width: 1367px) {
  .delete-m-card.loginPopUp {
    left: 65%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 35%;
  }
  .POIMap {
    width: 50%;
  }
}
@media (max-width: 1281px) {
  .delete-m-card.loginPopUp {
    left: 72%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 28%;
  }
  .download-app-card-cover .download-app-card img {
    max-width: 100%;
    width: 70px;
  }
  .download-app-card-cover
    .download-app-card
    .lft-content
    .download-group
    .app-stors
    .apps {
    width: 91px;
  }
  .download-app-card-cover .download-app-card {
    transform: translate(-7%, 0);
  }
  .current-controls {
    bottom: 20px;
  }
  .directionMap-ar {
    // right: 430px;
  }
  .sideBar,
  .menu {
    width: 405px;
  }
  .directionMa {
    left: 425px;
    bottom: 20px;
  }
  .POIMap {
    left: 550px;
    width: 50%;
    bottom: 25px;
  }
  .map-layer {
    // bottom: 66px;
  }
  .share-drop-down {
    transform: translate(0%, -50%);
    left: 66.3%;
    max-width: 350px;
  }
  .share-drop-down .share-link .share-circle img {
    width: 42px;
  }
  // .infoBox{
  //   left: -100px!important;
  // }

  .download-app-card-cover .download-app-card .right-vector {
    width: 190px;
  }
  .download-app-card-cover .download-app-card .lft-content {
    padding: 20px 10px 20px 20px;
  }
  .download-app-card-cover .download-app-card .lft-content .top-side strong {
    font-size: var(--font-size-16-5);
    margin-bottom: 5px;
  }

  .download-app-card-cover
    .download-app-card
    .lft-content
    .download-group
    .app-stors
    .qr-code {
    width: 60px;
  }
  .download-app-card-cover
    .download-app-card
    .lft-content
    .download-group
    .app-stors {
    column-gap: 10px;
  }
  .download-app-card-cover .download-app-card .btn-close {
    top: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
  }
  .download-app-card-cover .download-app-card .btn-close img,
  .download-app-card-cover .download-app-card .btn-close svg {
    width: 14px;
  }
  .download-app-card-cover .download-app-card {
    max-width: 400px;
    min-height: auto;
    border-radius: 20px;
  }

  [dir="rtl"] .directionMap,
  .POIMap {
    right: 445px;
    width: 45%;
  }
  // .sideBar , .menu {
  //   width: 433.64px;
  // }

  .sideBarContent {
    // height: 100vh;

    // padding-left: 26.34px !important;
    // padding-right: 26.34px !important;
    // padding-top: 26.34px !important;
  }
  .directionMap {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1379px) {
  .delete-m-card.loginPopUp {
    left: 72%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 28%;
  }
  .share-drop-down .share-link .share-circle img {
    width: 47px;
  }
  .this-page-spacing {
    .DetailsMakaniPageBtns {
      column-gap: 8px;
      .makaniPageLogo,
      .navigateWarper {
        height: 40px;
        img,
        svg {
          max-width: 70px;
        }
      }
      .DetailsMakaniCardBtn {
        flex-grow: 1;
        width: 40px;
        height: 40px;
      }
    }
  }
}
@media (max-width: 1199px) {
  .share-drop-down {
    max-width: 315px;
    left: 69.3%;
  }
  .this-page-spacing {
    .DetailsMakaniPageBtns {
      column-gap: 8px;

      .makaniPageLogo,
      .navigateWarper {
        height: 40px;
        img,
        svg {
          max-width: 50px;
        }
      }
      .DetailsMakaniCardBtn {
        flex-grow: 1;
        width: 33px;
        svg {
          width: 18px;
        }
      }
    }
  }

  .current-controls-ar,
  .directionMap-ar,
  .POIMap {
    bottom: 20px;
  }
  .map-layer-ar {
    bottom: 66px;
  }
  .directionMap-ar {
    // right: 370px;
    bottom: 20px;
  }
  .directionMap {
    bottom: 23px;
  }
  .AddFavoriteContent {
    padding: 13px 15px 15px;
    .AddInput {
      height: 33px;
    }
  }
  .share-drop-down .share-link .share-circle img {
    width: 40px;
  }

  .sideBar,
  .menu {
    width: 350px;
  }
  .directionMap,
  .POIMap {
    left: 370px;
  }
  .POIMap {
    left: 460px;
    width: 45%;
    bottom: 25px;
  }
  .distanceInfo.distanceInfOnCar img {
    width: 30px;
  }
  .distanceInfo.distanceInfOnCarNotActive img {
    width: 30px;
  }

  .distanceInfoOnFeetActive .icon_distance img {
    width: 15px;
  }
  .distanceInfoOnFeet .icon_distance img {
    width: 15px;
  }
  .distanceInfoWarper .directionSettingsWarper {
    margin-left: 0;
    padding-right: 0;
  }
  .distanceInfoOnFeetHour,
  .distanceInfOnCarHour {
    // font-size: 16px !important  ;
  }
  .distanceInfoWarper .distanceInfo {
    column-gap: 5px;
  }
  .distanceInfoWarper .distanceInfo {
    gap: 5px !important;
  }
}

@media screen and (max-width: 1100px) {
  .userInfo {
    // margin-top: 30px;
  }
  .profileCards {
    padding-left: 0;
    padding-right: 0;
  }

  .rootContainer {
    display: flex;
    // height: auto;
    flex-direction: column-reverse;
  }

  .recentSearchDirection {
    background: var(--recent-search-direction);
    padding: 15px;
    padding-top: 20px;
    display: none;
    z-index: 200;
  }
  .pointOfInternetSearchSuggestions {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .pointOfInternetItemSearchSuggestions {
    color: var(--poi-color);
    margin-bottom: 20px;
  }
  .pointOfInternetItemSearchSuggestions {
    display: flex;
    align-items: center;
    gap: 5px;
    background: var(--poi-bg);
    border-radius: 50px;
    padding-right: 14px;
  }
  .pointOfInternetIcon {
    width: 56.78px;
    height: 56.78px;
    border-radius: 50%;
    background-color: var(--element-bg);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .recentSearchWarper {
    border-radius: 10px;
    padding: 25px 15px;
    @media (max-width: 767px) {
      padding: 25px 15px;
    }
  }

  /* .pageTitleHeader {
    display: none;
  } */

  .backPage {
    width: 50px;
    height: 50px;
    background: var(--reponsive-header-wraper);
    border-radius: 50%;
    flex-shrink: 0;
  }

  .profileInfoDetails {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .menuContent {
    // height: 100vh !important;
    top: 0;
    // overflow-y: auto !important;
  }
  .profileInfo p {
    color: #ffffff;
  }

  .sideBar {
    .pageTitleHeader {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .collapsible {
    .POIMenuItemTitle {
      font-size: var(--font-size-16) !important;
    }
  }
  .collapsibleMenuItemWrapper {
    font-size: var(--font-size-14);
  }

  .directionInputWarper input {
    font-size: var(--font-size-14);
    &::placeholder {
      font-size: var(--font-size-14);
    }
  }
  .mapPlaceHolder {
    font-size: var(--font-size-14);
  }
  .directionBtnStart {
    min-width: 120px !important;
  }
  .directionBtn {
    width: 50px;
    min-width: auto;
    height: 42px;
  }
  .recentSearchTitle {
    font-size: var(--font-size-12);
  }
  .recentSearchAddress {
    font-size: var(--font-size-12);
  }
  .pointOfInternetTypeTitle {
    font-size: var(--font-size-12);
  }
  .recentSearchDestination {
    font-size: var(--font-size-12);
  }
  .directionPointWarper {
    .pointOfInternetIcon {
      width: 35px !important;
      height: 35px !important;
      img {
        width: 13px;
      }
    }
  }
  .recentSearchersTitle {
    font-size: var(--font-size-14);
  }
  .distanceInfoWarper .distanceInfo {
    // height: 42px;
  }
  .distanceInfo.distanceInfOnCar,
  .distanceInfo.distanceInfoOnFeet {
    height: 42px;
  }
  .distanceInfo.distanceInfOnCarNotActive,
  .distanceInfo.distanceInfoOnFeet {
    height: 42px;
  }
  .distanceInfo.distanceInfOnCar,
  .distanceInfo.distanceInfoOnFeetActive {
    height: 42px;
  }
  .distanceInfo.distanceInfOnCarNotActive,
  .distanceInfo.distanceInfoOnFeetActive {
    height: 42px;
  }

  .distanceInfoWarper .distanceInfo {
    gap: 15px;
  }
  .distanceInfOnCarHour {
    font-size: var(--font-size-15);
  }
  .distanceInfOnCarDistance {
    font-size: var(--font-size-12);
  }
  .distanceInfoOnFeetHour {
    font-size: var(--font-size-16);
  }
  .contactDetailsWrapper p {
    flex-wrap: wrap;
  }
}

@media (max-width: 1023px) {
  .directionMap,
  .POIMap {
    left: 390px;
  }

  .POIMap {
    left: 450px;
  }
  [dir="rtl"] .directionMap,
  .POIMap {
    right: 41%;
  }
  .menu {
    position: fixed;
    height: 100%;
    border-radius: 50px;
    width: 90%;
    top: 0;
  }
  .sideBar,
  .menu {
    width: 375px;
  }
  .download-app-card-cover .download-app-card {
    // max-width: 474.27px;
    min-height: auto;
  }

  .download-app-card-cover .download-app-card {
    border-radius: 20px;
  }
  .download-app-card-cover .download-app-card .lft-content .top-side p {
    font-size: var(--font-size-12);
    line-height: 15px;
    margin-bottom: 5px;
  }
  .POIMap {
    width: 44%;
    left: 48vw;
  }
}
@media (max-width: 991px) {
  .delete-m-card.loginPopUp {
    left: 73%;
  }
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: -5%;
  }
  .POIMap {
    width: 40%;
    left: 50vw;
  }
  .makanicard-details-popup {
    max-width: 400px;
  }
  .share-drop-down .share-link .share-circle img {
    width: 35px;
  }
  .share-drop-down {
  }
  // .infoBox {
  //   left: -35px !important;

  // }
  .collapsible .POIMenuItemTitle {
    font-size: var(--font-size-14) !important;
  }
}
@media (max-width: 940px) {
  .delete-m-card.loginPopUp {
    left: 74%;
  }

  // [dir="rtl"] .delete-m-card.loginPopUp {
  //   right: 26%;
  // }

  .POIMap {
    left: 480px;
    width: 32%;
    bottom: 28px;
  }
}

@media (max-width: 800px) {
  .POIMap {
    width: 80%;
    left: 13vw;
    top: 10vh;
    margin: auto;
    bottom: 100vh;
  }
}

@media (max-width: 450px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 26%;
  }
  .POIMap {
    width: 78%;
    left: 13vw;
    top: 15vh;
  }
}
@media (max-width: 768px) {
  .delete-m-card.loginPopUp {
    left: 50%;
  }
  // [dir="rtl"] .delete-m-card.loginPopUp {
  //   right: -10%;
  // }
  .download-app-card-cover .download-app-card {
    right: unset;
    left: unset;
    transform: none;
  }
  .download-app-card-cover {
    background: rgba(0, 0, 0, 0.32);
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 769px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 22%;
  }
}
@media (max-width: 990px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 28%;
  }
}
@media (max-width: 853px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: 25%;
  }
}

@media (max-width: 768px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: -10%;
  }
  .distanceInfoWarper .distanceInfo {
    gap: 10px !important;
  }
  .delete-m-card {
    width: 90%;
  }

  .headerMenuMobile .menubtn img {
    height: auto;
  }
  .download-app-card-cover .download-app-card .lft-content {
    width: 200px;
  }
  .download-app-card-cover .download-app-card .lft-content .top-side strong {
    word-break: break-all;
  }
  .myMakaniCardsWarper .myMakaniCard .updateModel {
    width: 100% !important;
    margin-bottom: 25px;
  }

  .current-controls,
  .map-layer {
    // right: 20px;
  }
  .slider-cover-main {
    .pageTitleHeader {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .download-app-card-cover {
    background: rgb(0 0 0 / 32%);
    justify-content: center;
    align-items: center;
  }
  .download-app-card-cover .download-app-card {
    position: relative;
    max-width: 400px;
    width: 100%;
    transform: none;
    left: 0;
  }

  .nort-btn svg {
    top: 1px;
    left: 1px;
  }
  .logo-in-card {
    max-width: 67px !important;
  }
  .share-drop-down .copy-link-row {
    padding: 15px;
    margin-top: 15px;
  }
  .share-drop-down .share-row {
    padding: 0 15px;
    margin-top: 15px;
  }
  .share-drop-down {
    transform: translate(-50%, -50%);
    max-width: 380px;
    top: 26%;
    left: 50%;
  }
  .madal-cover {
    left: 0;
  }
  .infoBox {
    // left: -205px !important;

    // width: 100% !important;
    z-index: 999;
    // bottom: -150px!important  ;
    .makanicard-details-popup {
    }
  }
  .makanicard-details-popup .button-group .MakaniCardContentDesinence {
    column-gap: 15px;
    display: flex;
  }
  .makanicard-details-popup .button-group .md-btn {
    flex-shrink: 0;
  }
  .makanicard-details-popup .button-group .md-btn {
  }

  .distanceInfoWarper .distanceInfo {
    font-size: 12px;
  }
  .distanceInfoOnFeetHour,
  .distanceInfOnCarHour strong {
  }
  .directionInputWarper {
    gap: 10px;
  }
  .makanicard-details-popup .button-group .md-btn {
    height: 33px;
    width: 33px;
    svg {
      width: 15px;
    }
  }
  .makanicard-details-popup .button-group .md-btn.logo-btn img {
    width: 55px;
  }
  .makanicard-details-popup .button-group .md-btn.logo-btn,
  .makanicard-details-popup .button-group .md-btn.navg-btn {
    width: 100px;
  }
  .makanicard-details-popup .button-group .md-btn.navg-btn img {
    width: 10px;
    margin-right: 5px;
  }
  .makanicard-details-popup .m-card-top p {
    font-size: var(--font-size-12);
  }
  .makanicard-details-popup .m-card-top .title-md {
    font-size: 16.15px;
  }
  .makanicard-details-popup .makaniCardDetailsTitle {
    font-size: 14px;
  }
  .makanicard-details-popup .button-group {
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }
  .download-app-card-cover {
    padding: 10px;
  }
  .myMakaniCardsWarper {
    overflow: auto;
    max-height: 360px;
  }
  .closeWarper {
    display: flex !important;
  }
  .menu {
    max-width: 352px;
    width: 100%;
  }
  .profileInfo {
    background: #333c4f;
    padding: 35px;
  }
  .headerMenuMobile {
    display: flex;
    .menubtn {
      display: flex;
      margin-top: 2.5px;
    }
  }
  .sideBar {
    position: fixed;
    width: 100%;
    min-height: 65.5vh;
    z-index: 101;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0px 4px 25.2px 15px #0000003d;
    top: calc(100% - 250px);
    overflow: hidden;
    // margin-left: 43px;
  }
  .mapContainer {
    height: 100%;
  }
  .sideBar {
    border-radius: 30px 30px 0px 0px;
  }
  .distanceInfoOnFeet .hrs-grp {
    // margin-left: 20px;
  }

  .distanceInfoWarper .directionSettingsWarper {
    margin-left: 0;
  }
  .sideBar .pageTitleHeader {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .closeWarper {
      margin-right: 25px;
      position: absolute;
      right: 0;
      // top: 7%;
      z-index: 9999;
    }
  }

  .settingSidebar .pageTitleHeader {
    .closeWarper {
      margin-right: 25px;
      position: absolute;
      right: 0;
      top: 2.5rem;
      z-index: 9999;
    }
  }

  .sideBar {
    width: 100%;
    margin-left: 0;
  }
  .sideBarContent {
    // height: 100%;
    // overflow-y: unset;
    // overflow-x: unset;
    width: 100%;
    padding-top: 26px !important;
  }
  .inner-spacing-01 {
    padding-top: 0 !important;
  }
  .sideBarHeader {
    display: none;
  }
  .headerMenuMobile {
    padding: 0 30px;
    .menubtn {
      img {
        width: 25.44px;
      }
    }
    div + div img {
      // width: 115px;
    }
  }
  .directionBtnStart {
    min-width: 180px !important;
  }
  .userInfo {
    background: #333c4f;
    padding: 25px 35px;
  }
  .zoom-button,
  .current-location-button {
    border-radius: 10px;
    svg {
    }
  }
  .madal-cover
    .modal-dialog
    .modal-content
    .modal-body
    .root-info
    .right-content
    .inr-content {
    padding-right: 0;
  }

  .weather {
    height: auto;
    width: auto;
    min-width: auto;
    min-height: auto;
    padding: 4px;
    border-radius: 7px;
    img,
    svg {
      width: 50px;
    }
  }

  .swipe-line {
    position: relative;
    &:before {
      content: "";
      width: 46px;
      height: 5px;
      border-radius: 23px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 14px;
      transform: translate(-50%, -50%);
    }
  }
  [data-theme="dark"] {
    .swipe-line:before {
      background-color: #76829e;
    }
  }
  .distanceInfoOnFeetHour strong,
  .distanceInfOnCarHour strong {
    @include apply-font-family(semibold);
    // font-size: 16px;
  }
  .makanicard-details-popup {
    max-width: fit-content;
  }
  .share-drop-down .share-head {
    min-height: 45px;
  }
  .share-drop-down .btn-close {
    top: 15px;
    width: 20px;
    height: 20px;
    svg {
      width: 13px;
    }
  }
  .share-drop-down .copy-link-row .copy-link {
    padding: 13px 15px 13px 12px;
  }
  .download-app-card-cover .download-app-card .right-vector {
    width: 150px;
  }
  .this-page-spacing .DetailsMakaniPageBtns .DetailsMakaniCardBtn svg {
    width: 22px;
  }
  .this-page-spacing .DetailsMakaniPageBtns .DetailsMakaniCardBtn {
    width: 38px;
    height: 38px;
    border-radius: 8px;
  }
  .this-page-spacing .DetailsMakaniPageBtns .makaniPageLogo,
  .this-page-spacing .DetailsMakaniPageBtns .navigateWarper {
    height: 38px;
  }
  .sideBarContent.this-page-spacing {
    .pageTitleHeader {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 479px) {
  [dir="rtl"] .delete-m-card.loginPopUp {
    right: -40%;
  }
  .download-app-card-cover .download-app-card {
    max-width: 98%;
  }
  .share-drop-down {
    max-width: 95%;
    top: 30%;
  }
  .makanicard-details-popup .button-group {
    column-gap: 5px;
  }
  .makanicard-details-popup .button-group .md-btn {
    height: 30px;
    width: 28px;
    border-radius: 5px;
  }
  .makanicard-details-popup .button-group .md-btn.navg-btn {
    border-radius: 5px;
  }
  // .infoBox {
  //   left: -155px !important;
  //   bottom: -180px !important;
  // }
  .makanicard-details-popup .button-group .md-btn.logo-btn,
  .makanicard-details-popup .button-group .md-btn.navg-btn {
    width: 75px;
  }
  .download-app-card-cover .download-app-card .lft-content .top-side strong {
    font-size: var(--font-size-15-5);
    margin-bottom: 7px;
  }

  .download-app-card-cover
    .download-app-card
    .lft-content
    .download-group
    .app-stors
    .apps {
    width: 82px;
  }
  .download-app-card-cover
    .download-app-card
    .lft-content
    .download-group
    .app-stors
    .qr-code {
    width: 57px;
  }

  .download-app-card-cover .download-app-card img {
    max-width: 100%;
    width: 80px;
  }

  .madal-cover .modal-dialog {
    max-width: 322px;
  }
  .madal-cover
    .modal-dialog
    .modal-content
    .modal-body
    .layers-list
    .layer-bx-otr
    .layer-bx {
    width: 72.32px;
    height: 72.3px;
  }
  .madal-cover .modal-dialog .modal-content .modal-footer .primary-btn {
    min-width: 150px;
    height: 38px;
  }
  .directionBtnStart {
    min-width: 165px !important;
  }
}

.fixed {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.font-info {
  font-size: var(--font-size-10-5);
  line-height: 1.3;
  color: #838383;
}

.font-controller {
  column-gap: 15px;
  display: flex;
  align-items: center;
  @include apply-font-family(semibold);
  .f-small {
    color: #7a7f97;
    font-size: 10px;
  }
  .f-large {
    font-size: 16px;
    color: #7a7f97;
  }
}

.fontSizeItem {
  display: flex;
  gap: 28%;
}

.fontSizeItem .font-info-description {
  white-space: nowrap;
}

.infoBox2 {
  width: 362.53px;
}

.sideBar {
  .dragger {
    height: 20px !important;
    position: absolute;
    width: 100%;
    z-index: 999;
  }
}

.infoBox {
  overflow: unset !important;
}
.favoriteLoading {
  padding: 0 35px;
}
.favouritePlaceSelected {
  min-width: auto;
  min-height: 60.59px;
  border-radius: 9.15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  background: #4e5972;
  @media (max-width: 1281px) {
    margin: 0 0;
  }
}
.noDataFound {
  font-size: 13px;
  padding: 6px 0;
}

.POIMenuTitle p {
  font-size: 15.44px;
  font-weight: 600;
  line-height: 15.29px;
  text-align: left;
  color: var(--tile-icon-color);
  padding: 10px 0;
}

.LoadingWraper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.makaniCardWarper {
  .deatilsMakaniCard:after {
    display: none !important;
  }
}
.copy-link-row .copy-link p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.share-drop-down {
  background-color: var(--bg-share);
  box-shadow: 0px 0px 11.4px 0px rgba(0, 0, 0, 0.1019607843);
}
.share-drop-down-slider {
  background-color: var(--bg-share);
  border-radius: 13px;
  padding: 0;
  width: 100%;
  height: 100%;
  .btn-close {
    align-items: center;
    background-color: rgb(51, 60, 79);
    border: 0;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 20px;
    justify-content: center;
    margin-left: auto;
    transition: 0.2s;
    width: 20px;
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 99;
    &:hover {
      background-color: #333c4f;
    }
    svg {
      width: 13px;
    }
  }
  .share-box {
    position: relative;
  }
  .share-head {
    position: relative;
    text-align: center;
    font-size: 16px;
    @include apply-font-family(medium);
    border-bottom: 1px solid var(--share-border);
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--main-text-color);
      background-color: var(--bg-share);
      position: relative;
      z-index: 9;
      padding: 0 10px;
    }
  }
  .share-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    padding: 0 15px;
  }
  .share-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(51, 60, 79, 0.49);
    font-size: 12px;
    cursor: pointer;
    p {
      color: var(--gry-text-color);
    }
    .share-circle {
      margin-bottom: 2px;

      img {
        width: 30px;
      }
    }
  }
  .copy-link-row {
    margin-top: 0px;
    font-size: 12px;
    padding: 0 15px 15px;
    .label {
      color: var(--gry-text-color);
      margin-bottom: 10px;
    }
    .copy-link {
      background-color: var(--lable-box-bg);
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      .copy {
        color: #333c4f;
        margin-left: auto;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
      p {
        color: var(--gry-text-color);
      }
    }
  }
}

.POIMenuItemLogo {
  width: 34.46px;
  height: 34.46px;
  background: var(--sidebar-header-bg, #333c4f);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.makaniCardDetailsSubAddress {
  margin-bottom: 2px;
}
.zoningRegulationsWrapper {
  padding: 10px 0;
  border-top: 1.14px solid var(--line-color);
  .title {
    @include apply-font-family(semibold);
    font-size: var(--font-size-13-7);
    font-weight: 400;
    line-height: 22.8px;

    color: var(--main-text-color);
  }
  .zoningRegulationsLabel {
    @include apply-font-family(semibold);
    font-size: var(--font-size-13-7);
    font-weight: 400;
    line-height: 22.8px;
    color: var(--main-text-color);
  }
}

@media (max-width: 1199px) {
  .share-drop-down-slider .share-head {
    font-size: 13px;
    min-height: 30px;
  }
  .share-drop-down-slider .btn-close {
    width: 15px;
    height: 15px;
    svg {
      width: 10px;
    }
  }
  .share-drop-down-slider .copy-link-row {
    padding: 0 10px 10px;
  }
}

@media (max-width: 767px) {
  .top-spacing {
    padding-top: 16px !important;
  }
  .share-drop-down-slider .copy-link-row .copy-link .copy {
    font-size: 10px;
  }
  .share-drop-down-slider {
    border-radius: 15px;
  }
  .share-drop-down-slider .share-link {
    min-width: 60px;
    margin-bottom: 5px;
  }
  .share-drop-down-slider .share-link p,
  .share-drop-down-slider .copy-link-row .label {
    font-size: 11px;
    margin-bottom: 5px;
  }
}

.backPage-sm-side {
  display: none;
}

@media (max-width: 1025px) and (min-width: 768px) {
  .share-drop-down {
    transform: translate(-26.5%, -100%);
    left: 50%;
    max-width: 370px;
  }
  [dir="rtl"] {
    .share-drop-down {
      transform: translate(26.5%, -100%);
      right: 50%;
      max-width: 370px;
    }
  }

  .sideBar {
    transition: 0.1s;
  }
  .ipad-responsive {
    width: 100px;
    .logo,
    .userInfo,
    .profileCardTitle,
    .profileWrapperLeft + div {
      display: none;
    }
    .backPage-sm-side {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4e5972;
      color: #fff;
      width: 42px;
      height: 42px;
      border-radius: 12.18px;
      margin-bottom: 80px;
      cursor: pointer;
    }
    .ProfileCardWarper {
      padding: 0;
      background-color: transparent;
    }
    .sideBarContent {
      background: #333c4f;
      backdrop-filter: none !important;
      overflow: hidden;
    }
    .myMakaniCards {
      left: 125px;
      top: auto;
      bottom: 20px;
      width: auto;
      max-width: calc(95vw - 104px);
    }
    .myMakaniCardsWarper {
      flex-direction: row;
      gap: 20px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    .myMakaniCardHeader {
      width: 330px;
    }
    .in-ipad-circle {
    }
    .sideBarHeader {
      display: flex;
      justify-content: center;
    }
    .ProfileCardWarper {
      height: 38px;
      width: 38px;
      img {
        width: 18px;
        max-width: 20px;
        min-width: 15px;
      }
    }
    .profileCards {
      align-items: center;
      row-gap: 35px;
      margin-top: 0;
    }

    .card-active {
      background-color: #fff !important;
      img {
        filter: brightness(0%);
        -webkit-filter: brightness(0%);
      }
    }
    .profileCards {
      [tabindex="0"]:nth-last-child(2) {
        .profileWrapper {
          img {
            width: 16px;
          }
        }
      }
    }
    .makaniCloseIcon {
      img {
        position: relative;
        left: 1px;
        top: 1px;
      }
    }
    ~ .mapContainer {
      .map-layer,
      .map-layer-ar {
        bottom: 360px;
      }
      .current-controls,
      .current-controls-ar {
        bottom: 315px;
      }
      // .zoom-controls,
      // .zoom-controls-ar {
      //   display: none;
      // }
    }
  }

  // arabic mode
  [dir="rtl"] {
    .ipad-responsive .myMakaniCards {
      right: 120px;
      left: unset;
      max-width: calc(95vw - 94px);
      @media (max-width: 991px) {
        max-width: calc(95vw - 102px);
      }
    }
  }
}

.download-app-card-cover .download-app-card .btn-close {
  cursor: pointer;
}

.entrance {
  min-width: 110px;
  height: 39px;
  background: #fff;
  border-radius: 20.31px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Visby;
  font-size: var(--font-size-13-6);
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
}
.entrances {
  display: flex;
  gap: 5px;
  margin-top: 9px;
}
.loginPopUp {
  height: 175px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 999999;
}
.signupWarperHome {
  width: 100%;
  height: 43px;
  border: 1px solid #231f20;
  display: flex;
  background: #000000;
  background: #f6f6f6;
  padding: 10px;
  justify-content: center;
  gap: 5px;
  align-items: center;
  border-radius: 15px;
  margin-top: 13px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    border-color: #03625a;
    background-color: #009d91;
    color: #fff;
  }
  .signUpTitle {
    @include apply-font-family(secondary-medium);
    font-size: 17px;
  }
}
.noAccess {
  font-size: var(--font-size-14);
  font-weight: 500;
  line-height: 1.1;
  color: #838383;
}

.custom-tooltip {
  background-color: var(--tooltip-bg) !important;
  color: var(--tooltip-color) !important;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}
.react-loading-skeleton {
}
@media (min-width: 1200px) {
  [data-size="2"] {
    .sideBar {
      width: 570.15px;
    }

    .directionMap {
      left: 595px;
    }
    .searchCards .searchCard {
      height: 179.22px;
    }
    .makaniCardsContent .MakaniCard {
      min-height: 110px;
    }
    .MakaniCardInfo p {
      margin-bottom: 5px;
    }
    .POIMap {
      width: 60%;
      left: 615px;
    }

    .traffic-info-cover .traffic-col .t-label {
      margin-top: -28px;
    }
    .download-app-card-cover .download-app-card {
      max-width: 617px;
    }
  }
  .InfoWindowLongPress {
    width: 200px;
  }
  .gm-style-iw-chr {
    display: none;
  }
  .InfoWindowLongPressHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .coummityName {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .close_btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
    background-color: #c4c3c3;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gm-style-iw .gm-style-iw-c {
    padding: 0;
  }
  // arabic mode
  [dir="rtl"] {
    [data-size="2"] {
      .directionMap-ar {
        right: 595px;
      }
      .POIMap {
        width: 57%;
        left: 225px;
        right: auto;
      }
    }
  }
}

.noDataFound {
  color: var(--secandary-text-color);
  font-size: var(--font-size-13);
  margin: 10px 0;
}

.gm-style img {
  // pointer-events: none;
}
.searchBuildingContent {
  overflow: hidden !important;
}
@media (max-width: 767px) {
  .searchBuildingContent {
    overflow-y: auto !important;
  }
}

.route-directions-container {
  @include apply-font-family(regular);
  font-size: 13.66px;
  font-weight: 500;
  line-height: 13.53px;
  margin-top: 20px;
  border-radius: 10px;
  max-height: 500px;
  min-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background: var(--element-light-bg);
  font-size: 13px;
}

.route-directions-list {
  max-height: 100%;
}

.route-direction-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 0.5px solid #ccc;
  justify-content: space-between;
}

.route-direction-icon-container {
  display: flex;
  align-items: center;
  color: var(--main-text-color);
  width: 28px;
  height: 13px;
  margin-right: 20px;
  flex-shrink: 0;
}

.route-direction-html {
  font-size: 13px;
  line-height: 1.8;
  flex: 1;
  margin-right: 20px;
  max-width: 200px;
  color: var(--main-text-color);
}

.route-direction-distance-duration {
  text-align: right;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.route-direction-distance-duration p {
  margin: 0;
  font-weight: bold;
  color: #009d91;
}

.highlight-toll-text {
  color: #ed991a;
  font-weight: bold;
}

.zoom-btn-svg {
  color: rgb(113, 104, 104);
  border: none;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  font-size: 18px;
  width: 44px;
  height: 44px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 35px;
    height: 35px;
  }
}

[data-theme="dark"] {
  .svgBackground {
    background-color: #76829e;
  }
}

[data-theme="dark"] {
  .mapContainer {
    background-color: #76829e;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

@media (min-width: 730px) {
  .loading-overlay {
    left: 10%;
  }
}

[dir="rtl"] {
  @media (min-width: 730px) {
    .loading-overlay {
      right: 10%;
    }
  }
}

[data-theme="dark"] {
  .loading-overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.floor-selector {
  text-align: center;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  background-color: #4e5972;
}

.floor-selector::-webkit-inner-spin-button,
.floor-selector::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows for Firefox */
.floor-selector {
  -moz-appearance: textfield;
}

.loader {
  font-size: var(--font-size-16);
  padding: 10px 0;
}

.noResultWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.grid-item {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.indoor-direction-from {
  justify-content: end;
  width: 40%;
}

.indoor-direction-end {
  justify-content: end;
  width: 40%;
}

.indoor-navigation-container-div {
  width: 100vw;
  height: 100vh;
  @media (max-width: 769px) {
    height: 80vh;
  }
}

// .joyride-content{
//   // font-style: 4;
//   font-size:4px ;
//   padding: 0;
// }

@media (max-width: 763px) {
  .joyride-content {
    // font-style: 4;
    font-size: 9px;
    padding: 0;
  }
  .joyride-title {
    font-size: 13px;
  }
}

.copyright-property {
  // position: absolute;
  // bottom: 2%;
  // padding: 1rem 0;
  height: 2rem !important;
  display: flex !important;
  justify-items: center !important;
  align-items: center !important;
}

.settingsAlert {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #009d91;
  border-radius: 10px 10px 0px 0px;
  height: 3rem;
  top: 98.2%;
  z-index: 99999;
  color: white;

  @media (max-width: 769px) {
    position: absolute;
    top: 3.4vh;
  }
}
