@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.favouriteTab {
  display: flex;
  gap: 15px;
  margin-bottom: 12px;
  // padding: 0 35px;
}

.favouritePlaces {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.favouritePlacesTab,
.favouriteRouteTab {
  width: 75px;
  border-bottom: 4.57px solid var(--tabs-border);
  @include apply-font-family(semibold);
  font-size: var(--font-size-18-3);
  font-weight: 400;
  line-height: 17.15px;
  color: var(--toggle-border);
  cursor: pointer;
  padding-bottom: 5px;
  border-radius: 2px;

  p {
    font-size: var(--font-size-16);
  }
}

.sortWarper {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  @include apply-font-family(semibold);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.58px;
  color: #4e5972;
  margin-bottom: 12px;
}

.sortWarper .selectedValue {
  color: #009d91 !important;
  cursor: pointer;
}

.EditableFavouritePlace {
  min-width: auto;
  height: 60.59px;
  border-top-left-radius: 9.15px;
  border-top-right-radius: 9.15px;
  background: var(--element-light-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: 1px solid #d6d6d6;
}

.favouritePlace {
  min-width: auto;
  min-height: 60.59px;
  height: auto;
  border-radius: 9.15px;
  background: var(--element-light-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  // margin: 0 35px;
}

.editFavoriteLocation {
  min-width: auto;
  height: 198.65px;
  margin: 0 35px !important;
  background: var(--element-light-bg);
  border-radius: 9.15px;
  margin-right: 0 !important;
}

// arabic mode
[dir="rtl"] {
  .editFavoriteLocation {
    margin-left: 0 !important;
    margin-right: 26px !important;
  }
}

.CancelFavoriteLocationBtn {
  width: 90px;
  outline: none;
  border: none;
  background: transparent;
  @include apply-font-family(regular);
  font-size: 14px;
  font-weight: 500;
  line-height: 13.86px;
  text-align: center;
  color: #333c4f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 42px;
  cursor: pointer;
}

.EditFavoriteBtns {
  display: flex;
  gap: 25px;
  cursor: pointer;
  width: 100%;
  justify-content: right;
}

.EditFavoriteLocationInputWraper {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #ececeb;
  overflow: hidden;
}

.EditFavoriteLocationInputWraper input {
  border: none;
  width: 90%;
  outline: none;
  padding: 8px 5%;
  height: 100%;
}

.EditFavoriteLocationBtn {
  max-width: 202.8px;
  width: 100%;
  height: 42px;
  top: 484.09px;
  left: 192.02px;
  gap: 0px;
  border-radius: 8px;
  background: #009d91;
  outline: none;
  border: none;
  @include apply-font-family(regular);
  font-size: 14px;
  font-weight: 500;
  line-height: 13.86px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.EditFavoriteLocation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px;
  gap: 20px;
}

.favouritePlaceLeft {
  gap: 13px;
  display: flex;
  align-items: center;
}

.favouritePlaceRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.favouritePlaceLogo {
  width: 36.58px;
  height: 36.58px;
  border-radius: 50%;
  background: var(--favorite-icons);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: var(--element-text-color);
}

.favouritePlaceLogo img {
  position: relative;
  right: -2px;
  padding: 0 !important;
}

.favouritePlaceTitle {
  @include apply-font-family(semibold);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  color: var(--main-text-color);
  margin-bottom: 3px;
}

.favouritePlaceSubTitle {
  @include apply-font-family(regular);
  font-size: var(--font-size-13);
  font-weight: 300;
  line-height: 1.1;
  color: var(--element-text-color);
  max-width: 205px;
}

.favouritePlaceSettingLogo {
  cursor: pointer;
}

.favouritesProfileRecentSearchHeader {
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  color: var(--main-text-color);
  font-weight: 400;
  font-size: var(--font-size-15-3);
  line-height: 15.17px;
  @include apply-font-family(semibold);
}

.seeMoreTitle {
  @include apply-font-family(medium);
  color: var(--title-color);
  font-weight: 400;
  font-size: var(--font-size-13);
  line-height: 13.01px;
  cursor: pointer;
}

.favouritePlaceMakainNumber {
  font-size: var(--font-size-16);
  @include apply-font-family(semibold);
}

.favouritesProfileRecentSearchesWarper {
  background: var(--menu-item-bg);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.favouritesProfileRecentSearches {
  // padding: 0 35px;
  margin-top: 43px;
}

.favouritePlaceMakainDistance {
  color: #009d91;
  font-size: var(--font-size-13-7);
  line-height: 1.1;
  @include apply-font-family(regular);

  span {
    @include apply-font-family(semibold);
  }
}

.favouritesProfileRecentSearchWarper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.14px solid var(--line-color);
  padding-bottom: 10px;
}

.favouritesProfileRecentSearch {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.favouritesProfileRecentSearchTitle {
  color: var(--secandary-text-color);
  @include apply-font-family(semibold);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
}

.favouritesProfileRecentSearchAddress {
  color: var(--third-text-color);
  @include apply-font-family(regular);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
}

.poiWarper p {
  @include apply-font-family(regular);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  color: var(--element-text-color);
}

.poiWarper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.poiImageWarper {
  width: 16.01px;
  height: 16.01px;
  background: var(--favorite-icons);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.editableFavoritePlaces {
  background: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  min-height: 60px;

  .placeInfo {
    p {
      font-size: var(--font-size-13-7);
      @include apply-font-family(semibold);
      white-space: nowrap;
    }
  }
}
.place_pWraper {
  width: 100px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  margin-bottom: auto;
}

.editableFavoritePlacesAr {
  background: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;

  .placeInfo {
    p {
      font-size: var(--font-size-13-7);
    }
  }
}

.editableFavoritePlacesAr .placeInfo {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: auto;
  @include apply-font-family(semibold);
  white-space: nowrap;
}

.editableFavoritePlaces .placeInfo {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-left: auto;
}

.placeInfo .makaniNumber {
  @include apply-font-family(semibold);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 1.1;
  text-align: right;
  color: #333c4f;
}

.editableFavoritePlaces .placeInfo p {
  @include apply-font-family(semibold);
  white-space: nowrap;
}
.editableFavoritePlaces .placeInfo .place_p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeInfo .distance {
  @include apply-font-family(semibold);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.58px;
  text-align: right;
  color: #009d91;
}

.arrowWarper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 16px;
}

.favoriteAction {
  display: flex;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.deleteFavorite {
  display: flex;
  width: 76.98px;
  height: 60.59px;
  text-align: center;
  background: #7a7f97;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.editFavorite {
  display: flex;
  width: 60px;
  height: 60.59px;
  cursor: pointer;
  text-align: center;
  background: #4e5972;
  align-items: center;
  justify-content: center;
}

.sharedFavorite {
  display: flex;
  width: 60px;
  height: 60.59px;
  text-align: center;
  background: #333c4f;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.sharedFavoriteAr {
  display: flex;
  width: 60px;
  height: 60.59px;
  text-align: center;
  background: #333c4f;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.sharedFavoriteAr p,
.sharedFavorite p,
.deleteFavorite p,
.editFavorite p {
  @include apply-font-family(regular);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  color: #ffff;
}

.routesFavorites {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.routeFavorite {
  // min-width: 403.57px;
  height: 72.03px;
  border-radius: 9.15px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 15px;
  background: var(--element-light-bg);
  box-shadow: 0px 0px 3.43px 0px rgba(0, 0, 0, 0.1019607843);
}

.routesLogoWarper {
  width: 36.58px;
  height: 36.58px;
  background: var(--favorite-icons);
  // flex: 1;
  border-radius: 50%;
  align-items: center;
  position: relative;
}

.routesLogoWarper .flag {
  position: absolute;
  bottom: 17%;
  left: 55%;
}

.routesLogoWarper .flag2 {
  position: absolute;
  bottom: 40%;
  left: 25%;
}

.routesLine {
  flex: 7;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

.routesLine .routeName {
  @include apply-font-family(semibold);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  color: var(--route-name);
}

.lineWarper {
  display: flex;
  gap: 7px;
}

.lineWarper p {
  @include apply-font-family(regular);
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.58px;
  color: var(--street-name);
  white-space: nowrap;
  max-width: 97px;
  min-width: 97px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line {
  display: flex;
  border-top: 2.11px dashed var(--route-line);
  width: 90%;
  margin-top: 7px;
  position: relative;
}

.routeDetails .distance {
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.58px;
  color: #009d91;
  text-align: end;
  @include apply-font-family(semibold);
  white-space: nowrap;
  span {
    @include apply-font-family(regular);
  }
}

.routeDetails .street {
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 13.58px;
  color: var(--street-name);
  text-align: end;
  // white-space: nowrap;
  @include apply-font-family(regular);
  white-space: nowrap;
  max-width: 80px;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .lineWarper p{
//   white-space: nowrap;
//   max-width: 97px;
//   min-width: 97px;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.routeSettingWarper {
  flex: 0.3;
  display: flex;
  justify-content: flex-end;
}

.favoritesContent {
  .sortDropDown {
    right: 0px !important;
  }
}
.favouritesProfileRecentSearchWarper {
  &:last-child {
    border-bottom: 0;
  }
}

.sortWarper {
  position: relative;

  .sortDropDown li {
    padding-bottom: 10px;
    padding-top: 10px;
    transition: 0.2s;
    height: 33.33%;

    &:hover {
      background-color: #4e5972;

      p {
        color: #fff;
      }
    }
  }

  .sortDropDown {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    top: 25px;
    background: var(--sort-drop-down);
    height: 115px;
  }
}

.favouritePlaceMakainNumber {
  color: var(--main-text-color);
  white-space: nowrap;
}

[data-theme="dark"] {
  .EditFavoriteLocationInputWraper input {
    background: #1e222b;
    color: #cfd5f2;
  }
  .CancelFavoriteLocationBtn {
    color: #cfd5f2;
  }
  .favouritesProfileRecentSearchesWarper {
    color: #cfd5f2;
  }
  .EditableFavouritePlace,
  .EditFavoriteLocationInputWraper {
    border-color: rgba(122, 127, 151, 0.5);
  }
  .editableFavoritePlaces,
  .sharedFavorite,
  .editableFavoritePlacesAr,
  .sharedFavoriteAr {
    background: #1e222b;
  }
  .favouritesProfileRecentSearchAddress,
  .placeInfo .makaniNumber,
  .editableFavoritePlaces .placeInfo p,
  .editableFavoritePlacesAr .placeInfo p {
    color: #939cc7;
  }
  .favouritePlacesTab,
  .favouriteRouteTab {
    color: #ffffff !important;
  }
  .sortWarper > p {
    color: #ffffff;
  }
  .routesLine .routeName,
  .lineWarper p,
  .routeDetails .street {
    color: #ffffff;
  }
  .routesLine .line {
    border-color: #939cc7;
  }
}
// arabic mode
[dir="rtl"] {
  .favoritesContent .sortDropDown {
    left: 0px !important;
    right: unset !important;
  }
  .sharedFavorite {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .editableFavoritePlaces {
    padding-left: 0;
  }
  .arrowWarper {
    margin-right: 0px;
    margin-left: 16px;
    transform: rotate(180deg);
  }
  .placeInfo .distance {
    text-align: left;
  }
  .placeInfo .place_p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing {
    padding-left: 28px;
    padding-right: 0;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .pageTitleHeader,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouriteTab,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlace,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlaceSelected,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritesProfileRecentSearches,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .routeFavorite {
    margin-left: inherit;
    margin-right: 35px;
  }
  .editableFavoritePlacesAr {
    min-height: 60px;
  }
  @media (max-width: 1679px) {
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .pageTitleHeader,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouriteTab,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritePlace,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritePlaceSelected,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritesProfileRecentSearches {
      margin-right: 28px;
    }
  }

  @media (max-width: 767px) {
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .pageTitleHeader,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouriteTab,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritePlace,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritePlaceSelected,
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .favouritesProfileRecentSearches {
      margin-right: 28px;
    }
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing {
      padding-left: 28px !important;
      padding-right: 0 !important;
    }
    .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
      .routeFavorite {
      margin-right: 28px;
    }
  }
}

.lineWarper {
  .line {
    display: flex;
    justify-content: space-between;
    border-top: 2.11px dashed var(--route-line) !important;
    .marker {
      position: relative;
      top: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #009d91;
      width: 8px;
      height: 8px;
      border: 2px solid #fff !important;
      -webkit-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      -moz-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      flex-shrink: 0;
    }
  }
  .markerChild {
    display: none;
  }
}
.routesLogoWarper {
  flex-shrink: 0;
}
.favouritePlaceSelected {
  // transition: 0.2s;
}
.editableFavoritePlaces,
.editableFavoritePlacesAr {
  // transition: 0.2s;
  .favoriteAction {
    > div {
      // transition: 0.2s;
    }
  }
}

.sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing {
  padding-left: 0;
  .pageTitleHeader,
  .favouriteTab,
  .favouritePlace,
  .favouritePlaceSelected,
  .favouritesProfileRecentSearches,
  .routeFavorite {
    margin-left: 35px;
  }
}
@media (max-width: 1679px) {
  .favoriteAction {
    > div {
      width: auto;
      padding: 0 10px;
    }
  }
  .sharedFavorite {
    width: 60.98px;
  }
  .sharedFavoriteAr {
    width: 60.98px;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .pageTitleHeader,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouriteTab,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlace,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlaceSelected,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritesProfileRecentSearches {
    margin-left: 28px;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .pageTitleHeader,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouriteTab,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlace,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlaceSelected,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritesProfileRecentSearches,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .routeFavorite {
    margin-left: 30px;
  }
}

@media (max-width: 1479px) {
  .editableFavoritePlaces {
    .placeInfo {
      gap: 10px;

      .makaniNumber {
        font-size: var(--font-size-15);
      }
    }
  }
  .editableFavoritePlacesAr {
    .placeInfo {
      gap: 10px;

      .makaniNumber {
        font-size: var(--font-size-15);
      }
    }
  }
}

@media (max-width: 1281px) {
  .editableFavoritePlaces {
    margin-right: 0px;
  }
  .editableFavoritePlacesAr {
    margin-right: 0px;
  }
  .arrowWarper {
    margin-right: 5px;
  }
  .deleteFavorite,
  .editFavorite {
    width: 60px;
  }

  .headerTitle,
  .seeMoreTitle {
    font-size: var(--font-size-13);
  }

  .favouritePlaceMakainNumber {
    font-size: var(--font-size-13);
  }

  .favouritePlaceMakainDistance {
    font-size: var(--font-size-12);
  }

  .favouritePlaceLeft,
  .favouritePlaceRight {
    column-gap: 10px;
  }

  .favouritePlaceLogo {
    width: 30px;
    height: 30px;

    img {
      width: 20px;
    }
  }

  .favouritePlace {
    padding: 10px 12px;
  }

  .favouritePlaceSubTitle {
    font-size: var(--font-size-12);
  }

  .favouritePlace {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sortWarper,
  .favouriteTab,
  .favouritesProfileRecentSearches,
  .routesFavorites {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sortWarper,
  .favouriteTab,
  .favouritesProfileRecentSearches,
  .routesFavorites,
  .favouritePlaces {
    padding-left: 0px;
    padding-right: 0px;
  }

  .routeFavorite {
    padding-left: 12px;
    padding-right: 12px;
  }

  .sortWarper .sortDropDown {
    // right: 20px !important;
  }
  .editableFavoritePlaces .placeInfo .place_p,
  .editableFavoritePlacesAr .placeInfo .place_p {
    display: none;
  }
  .place_pWraper {
    display: none;
  }
}

@media (max-width: 1100px) {
  .sortWarper,
  .favouriteTab,
  .favouritesProfileRecentSearches,
  .routesFavorites,
  .favouritePlaces {
    padding-left: 0px;
    padding-right: 0px;
  }

  .editableFavoritePlaces .placeInfo p {
    margin-bottom: 2px;
  }

  .editableFavoritePlacesAr .placeInfo p {
    margin-bottom: 2px;
  }
  .editableFavoritePlaces {
    margin-right: 0px;
  }
  .editableFavoritePlacesAr {
    // margin-right: 20px;
  }

  .headerTitle,
  .seeMoreTitle {
    font-size: var(--font-size-16);
  }

  .headerTitle {
    font-size: var(--font-size-14);
  }

  .seeMoreTitle {
    font-size: var(--font-size-12);
  }

  .favouritePlace {
  }

  .sortWarper,
  .favouriteTab {
    font-size: var(--font-size-12);
  }

  .favouritePlaceTitle {
    font-size: var(--font-size-12);
  }

  .editableFavoritePlaces .placeInfo p,
  .sharedFavorite p,
  .deleteFavorite p,
  .editFavorite p {
    font-size: var(--font-size-12);
  }

  .editableFavoritePlacesAr .placeInfo p,
  .sharedFavorite p,
  .sharedFavoriteAr p,
  .deleteFavorite p,
  .editFavorite p {
    font-size: var(--font-size-12);
  }

  .routesLine .routeName {
    font-size: var(--font-size-12);
  }

  .lineWarper p {
    font-size: var(--font-size-12);
  }

  .routesLogoWarper {
    width: 32px;
    height: 32px;
  }

  .routesLogoWarper .flag2 {
    bottom: 40%;
  }

  .routesLogoWarper .flag {
    bottom: 15%;
  }

  .routeDetails .street {
    font-size: var(--font-size-12);
  }

  .routeDetails .distance {
    font-size: var(--font-size-12);
  }
  .sharedFavoriteAr,
  .sharedFavorite,
  .deleteFavorite,
  .editFavorite {
    width: auto;
    flex-grow: 1;
    flex-basis: 50px;
  }

  .favoriteAction {
    flex-grow: 1;
  }

  .favouritesProfileRecentSearchWarper {
    .plus-btn {
      width: 16px;
    }
  }

  .editableFavoritePlaces .placeInfo {
    // margin-right: 15px;
  }

  .editableFavoritePlacesAr .placeInfo {
    margin-right: 15px;
  }
  .editableFavoritePlaces .placeInfo {
    // padding-right: 10px;
    column-gap: 15px;

    .place_p {
      // display: none;
    }
  }
  .editableFavoritePlacesAr .placeInfo {
    // padding-right: 10px;
    column-gap: 15px;

    // .place_p {
    //   display: none;
    // }
  }

  .editableFavoritePlaces {
    justify-content: flex-end;
    column-gap: 5px;
  }
  .editableFavoritePlacesAr {
    justify-content: flex-end;
    column-gap: 5px;
  }

  .closeWarper img {
    width: 10px;
    height: 10px;
  }

  .favouritesProfileRecentSearches {
    margin-top: 37px;
  }

  .favouritesProfileRecentSearchTitle {
    font-size: var(--font-size-12);
  }

  .favouritesProfileRecentSearchAddress {
    font-size: var(--font-size-12);
  }

  .poiWarper p {
    font-size: var(--font-size-12);
  }

  .favouritesProfileRecentSearchesWarper {
    padding: 13px 12px;
  }
}

@media (max-width: 1281px) {
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .pageTitleHeader,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouriteTab,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlace,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlaceSelected,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritesProfileRecentSearches,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .routeFavorite {
    margin-left: 30px;
  }
  .routeDetails .street,
  .lineWarper p {
    max-width: 60px;
    min-width: 60px;
  }
}

@media (max-width: 767px) {
  .favouritePlaceLogo img {
    width: 13px;
    height: auto;
  }
  .favoriteAction {
    flex-grow: unset;
  }
  .favoriteAction > div {
    width: 67px;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing {
    padding-left: 0 !important;
    padding-right: 30px !important;
  }
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .pageTitleHeader,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouriteTab,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlace,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritePlaceSelected,
  .sideBarContent.swipe-line.inner-spacing-01.top-spacing.favourite-spacing
    .favouritesProfileRecentSearches {
    margin-left: 30px;
  }
  .editableFavoritePlaces .placeInfo .makaniNumber {
    font-size: var(--font-size-14);
  }

  .editableFavoritePlaces .placeInfo {
    margin-right: 5px;
  }
  .editableFavoritePlacesAr .placeInfo .makaniNumber {
    font-size: var(--font-size-14);
  }

  .editableFavoritePlacesAr .placeInfo {
    margin-right: 5px;
  }
}
.lineWarper {
  .line {
    display: flex;
    justify-content: space-between;
    border-top: 2.11px dashed var(--route-line) !important;
    .marker {
      position: relative;
      top: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #009d91;
      width: 8px;
      height: 8px;
      border: 2px solid #fff !important;
      -webkit-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      -moz-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
      flex-shrink: 0;
    }
  }
  .markerChild {
    display: none;
  }
}

@media (min-width: 1200px) {
  [data-size="2"] {
    .favouritePlace {
      min-width: auto;
      min-height: 70.59px;
    }
    .editableFavoritePlaces,
    .favouritePlaceSelected {
      min-height: 70.59px;
    }
    .favoriteAction div {
      height: 70.59px;
    }
    .place_pWraper ~ div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .favouritePlaceRight div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
