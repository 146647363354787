@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.faq-cover-main {
  .content-faq {
    padding: 14px;
    border-radius: 10px;
    background: var(--element-light-bg);
    margin-bottom: 10px;
    transition: max-height 0.2s ease-out;

    .faq-head {
      color: var(--main-text-color);
      @include apply-font-family(semibold);
      font-size: var(--font-size-14);
      line-height: 20px;
      margin-bottom: 0;
      padding-right: 20px;
      position: relative;
      cursor: pointer;

      .arrow {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .para {
      max-height: 0;
      opacity: 0;
      transition: max-height 0.2s ease-out, opacity 0.2s ease-out;
      p,
      li {
        font-size: var(--font-size-13-6);
        line-height: 1.3;
        color: var(--main-text-color);
      }

      ul {
        margin-top: 5px;
        list-style: decimal !important;

        li {
          color: var(--main-text-color);
          display: inline-flex;
          margin-bottom: 3px;
          
          /* Indentation for list items */
          padding-left: 20px; /* Adjust the padding value as needed */

          &:before {
            content: "-";
            padding-right: 10px;
          }
        }
      }
    }
    .para.expanded {
      max-height: 300px;
      overflow: visible;
      opacity: 1;
    }
   

    /* RTL Support */
    [dir="rtl"] ul li {
      padding-right: 20px; /* Adjust the padding value as needed */
      padding-left: 0; /* Reset the LTR padding */

      &:before {
        content: "-";
        padding-left: 10px;
        padding-right: 0; /* Reset the LTR padding */
      }
    }
  }
}


 // arabic mode 
 [dir=rtl]{
.faq-cover-main .content-faq .faq-head{
  padding-right: inherit;
  padding-left: 20px;
}
.faq-cover-main .content-faq .faq-head .arrow {
  right: inherit;
  top: 0px;
  left: 0;
}

 }

@media (max-width: 1679px) {
  .faq-cover-main .content-faq .faq-head {
    font-size: var(--font-size-13);
  }
  .faq-cover-main .content-faq .para p,  .faq-cover-main .content-faq .para li {
   font-size: var(--font-size-13);
    
  }
  .faq-cover-main {
    // padding-left: 15px;
  }
  .faq-cover-main .content-faq {
    padding: 12px 12px;
  }
}
@media (max-width: 1281px) {
  .faq-cover-main {
    padding-left: 0px;
  }
}

