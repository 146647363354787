.buildingAddressTitle {
  color: var(--main-text-color);
  font-family: "VisbySemiBold", sans-serif;
  font-size: var(--font-size-18-3);
  font-weight: 400;
  line-height: 18.06px;
  margin-bottom: 10px;
}
.buildingAddressInfo {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.54px;
  color: var(--element-text-color);
}

.makaniCardWarper {
  padding: 0 35px;
}
