@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.DeatilsMakaniCards {
  display: block;
  gap: 30px;
  /* overflow-x: scroll; */
  align-items: center;
  white-space: nowrap;
  padding: 0 36px;

  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    font-size: var(--font-size-20-5);
  }
}
.POIMenuTitle p {
  @include apply-font-family(semibold);
}
.searchTitle {
  @include apply-font-family(semibold);
  font-size: var(--font-size-18-3);
  line-height: 1.1;
  color: var(--main-text-color);
  // margin-bottom: 7px;
}

.searchAddress {
  font-size: var(--font-size-13-7);
  line-height: 1.1;
  color: var(--element-text-color);
  @include apply-font-family(medium);
}

.searchTitleWarper,
.makaniCardDetails,
.poiMenu,
.PageHeaderTitleWarper {
  margin: 0 36px;
}

.slick-cards-cover {
  margin-bottom: 25px;
  padding-right: 0;
  .slick-list {
    position: relative;
    left: 0px;
    padding: 0 !important;
    // width: 452px;
  }
  .slick-prev,
  .slick-next {
    z-index: 99;
  }
  .slick-initialized .slick-slide {
    // width: 362.53px !important;
  }
  .slick_prev:before,
  .slick_next:before {
    content: "";
    border: solid #7a7f97;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 7px;
  }

  .slick-dots {
    position: absolute;
    bottom: -15px;
    left: 30px;
    display: flex !important;
    align-items: center;
  }
  .slider-nav {
    transform: none;
    top: auto;
    .slick_prev {
      position: absolute;
      left: 0;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 3px;
    }
    .slick_next {
      top: 3px;
      cursor: pointer;
      position: relative;
      right: -35px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        position: absolute;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .slick-dots li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .slick-dots li button {
    width: 5.98px;
    height: 5.98px;
    border-radius: 50%;
    background-color: #d1d1d1;
    opacity: 1;
    font-size: 0;
    position: static;
    padding: 0;
  }
  .slick-dots li button:before {
    display: none;
  }
  .slick-dots li.slick-active button {
    background-color: #009d91 !important;
    width: 21.4px;
    border-radius: 23px;
  }
}

.slick-controls {
  display: flex;
  position: relative;
  .custom-dots {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    max-width: 120px;
    left: 30px;
    li {
      list-style: none;
      margin: 0 5px;
      button {
        width: 5.98px;
        height: 5.98px;
        border-radius: 50%;
        background-color: #d1d1d1;
        opacity: 1;
        font-size: 0;
        position: static;
        padding: 0;
        border: 0;
        &.active {
          background-color: #009d91 !important;
          width: 21.4px;
          border-radius: 23px;
        }
      }
    }
  }
}

.slick-track {
  margin: 0;
}
.slick-cards-cover {
  .MakaniCardHeader {
    justify-content: flex-start;
    .copyWrapper {
      position: relative;
      padding-left: 16px;
      margin-left: 16px;
      margin-right: auto;
      &:before {
        content: "";
        position: absolute;
        width: 1.15px;
        background-color: #fff;
        left: 0;
        height: 60px;
      }
    }
  }

  .deatilsMakaniCard:after {
    display: none !important;
  }
}

.slick-cards-cover .MakaniCardHeader {
  overflow: hidden;
}
// dark

.makaniCardNumber,
.favoriteItemMakaniNumber {
  color: var(--secandary-text-color);
}
.MakaniCardHeader {
  .love {
    color: var(--secandary-text-color);
  }
  .logo {
    filter: var(--filler-white);
  }
}

.DetailsMakaniCardBtn {
  color: var(--secandary-text-color);
  img,
  svg {
    width: 23px;
  }
}

[dir="rtl" i] {
  .MakaniCardContentDesinence .directions {
    right: -13px;
  }
  .sideBarContent.inner-content {
    .DeatilsMakaniCards {
      padding: 0 36px 0 0 !important;
    }
  }
  .slick-controls .custom-dots {
    right: 30px;
    min-width: 60px;
  }
  .slick-cards-cover .slick-slider {
    .deatilsMakaniCard {
      margin: 20px 13.5px;
      // direction: rtl;
      text-align: right;
      left: 73px !important;
    }
    .slick-list {
      padding-left: 0 !important;
      padding-right: inherit !important;
    }
  }
  // .slick-nav {
  //   direction: rtl;
  // }
  .slick_next {
    top: 1px !important;
    position: absolute !important;
    right: 0px !important;
  }
  .slick_prev {
    position: relative !important;
    right: 30px !important;
    top: 2px !important;
    left: inherit !important;
  }
  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    margin-left: auto;
    margin-right: 16px;
    padding-left: 0;
    padding-right: 16px;
    &:before {
      left: inherit;
      right: 0;
    }
  }

  @media (max-width: 1679px) {
    .sideBarContent.inner-content {
      padding-left: 0 !important;
      padding-right: 0px !important;
    }
    .slick-cards-cover .deatilsMakaniCard {
      min-width: auto;
      height: auto;
    }
    .slick-cards-cover .slick-list {
      // width: 385px;
    }
    .slick-cards-cover .slick-slider .deatilsMakaniCard {
      left: 45px !important;
    }
  }

  @media (max-width: 1479px) {
    .slick-cards-cover .slick-list {
      padding-left: 0px !important;
    }
    .slick-cards-cover .deatilsMakaniCard {
      margin: 20px 10.5px;
    }
    .slick-cards-cover .slick-list {
      // width: 365px;
    }
    .slick-cards-cover .MakaniCardHeader .copyWrapper {
      margin-left: auto;
      margin-right: 8px;
      padding-left: 0;
      padding-right: 8px;
    }
  }
  @media (max-width: 1379px) {
    .sideBarContent.inner-content .DeatilsMakaniCards {
      padding: 0 20px 0 0 !important;
    }
    .slick-cards-cover .slick-list {
      // width: 117%;
      right: 0 !important;
      left: inherit;
    }

    .slick-cards-cover .slick-slider .deatilsMakaniCard {
      left: 50px !important;
    }
  }
  @media (max-width: 1281px) {
    .sideBarContent.inner-content {
      padding-right: 20px !important;
    }
    .sideBarContent.inner-content .makaniCardDetails {
      padding-right: 0 !important;
      padding-left: 20px;
    }
    .sideBarContent.inner-content .DeatilsMakaniCards {
      padding: 0 0px 0 0 !important;
    }
    .slick-cards-cover .slick-list {
      // width: 124%;
    }
  }
  @media (max-width: 1199px) {
    .slick-cards-cover .slick-list {
      // width: 122%;
    }

    .slick-cards-cover .slick-slider .deatilsMakaniCard {
      left: 40px !important;
    }
  }
  @media (max-width: 991px) {
    .slick-cards-cover .slick-list {
      // width: 125%;
    }
    .slick-cards-cover .slick-slider .deatilsMakaniCard {
      left: 30px !important;
    }
  }
  @media (max-width: 767px) {
    .slick-cards-cover .slick-slider .deatilsMakaniCard {
      left: 29px !important;
    }
    .sideBar .pageTitleHeader .closeWarper {
      margin-left: 20px;
      right: auto;
      left: 0;
    }
    .slick-cards-cover .MakaniCardHeader .copyWrapper {
      margin-left: auto;
      margin-right: 15px;
      padding-left: 0;
      padding-right: 15px;
    }
    .slick-cards-cover .slick-list {
      // width: 108%;
      right: inherit !important;
    }
    .slick-cards-cover {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 479px) {
    .slick-cards-cover .slick-list {
    }
  }
}
// arabic slide

// arabic slide End
[dir="ltr" i] {
  .slick-cards-cover .slick-list .slick-slide:last-child {
    .deatilsMakaniCard {
      width: 100%;
    }
  }
}
.sideBarContent.inner-content {
  padding-right: 0 !important;
  padding-left: 0;
}

@media (max-width: 1679px) {
  .sideBarContent.inner-content {
    padding-right: 0 !important;
    padding-left: 0;
  }

  .slick-cards-cover .slick-list {
    // width:112%;
  }
  .DeatilsMakaniCards .makaniCardNumber,
  .DeatilsMakaniCards .favoriteItemMakaniNumber {
    font-size: var(--font-size-16-5);
  }
  .slick-cards-cover {
    .deatilsMakaniCard {
      min-width: auto;
      height: auto;
    }
    .MakaniCardHeader .logo {
      width: 65.66px;
      height: auto;
    }

    .navigateWarper {
      height: 40px;
      font-size: var(--font-size-13);
      .navigateTitle {
        font-size: var(--font-size-12);
      }
      img,
      svg {
        width: 13px;
      }
    }
    .DetailsMakaniCardBtn {
      width: 40px;
      height: 40px;
      img,
      svg {
        width: 18px;
      }
    }
  }
  .MakaniCardHeader .love {
    // width: 15px;
  }
}

@media (max-width: 1379px) {
  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    padding-left: 9px;
    margin-left: 9px;
  }
  .slick-cards-cover .slick-list {
    // width: 106.5%;
  }
  .slick-cards-cover .deatilsMakaniCard {
    margin: 20px 9px;
  }
  .slick-cards-cover .slick_prev:before,
  .slick-cards-cover .slick_next:before {
    padding: 5px;
  }
  .slick-cards-cover .deatilsMakaniCard {
    margin: 20px 9px 5px;
  }

  .slick-cards-cover {
    .navigateWarper {
      width: 40%;
    }
    .DetailsMakaniCardBtns {
      column-gap: 12px;
      .DetailsMakaniCardBtn {
        width: auto;
        flex-grow: 1;
      }
    }
  }
  .searchTitleWarper,
  .poiMenu,
  .PageHeaderTitleWarper {
    margin: 0 20px;
  }

  .slick-cards-cover {
    padding: 0 0 0 20px;
  }

  .sideBarContent.inner-content {
    .makaniCardDetails {
      margin: 0 0;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

@media (max-width: 1281px) {
  .slider-cover-main {
    .searchTitleWarper,
    .poiMenu,
    .PageHeaderTitleWarper {
      margin: 0 20px;
    }
    .makaniCardDetails {
      padding-left: 20px;
    }
    .makaniCardDetails {
      padding-left: 20px !important;
    }
  }
  .sideBarContent.inner-content {
    .makaniCardDetails {
      padding-left: 0px;
    }
  }
  .slick-cards-cover {
    // padding: 0;
  }

  .slick-cards-cover .DetailsMakaniCardBtn img,
  .slick-cards-cover .DetailsMakaniCardBtn svg {
    width: 16px;
  }
  .slick-cards-cover .MakaniCardHeader .logo {
    width: 40px;
    height: auto;
  }

  .searchTitleWarper,
  .poiMenu,
  .PageHeaderTitleWarper {
    margin: 0 0px;
  }
}
@media (max-width: 1199px) {
  .slick-cards-cover {
    .MakaniCardHeader {
      padding: 6px 15px;
      .love {
        width: 20px;
      }
    }
  }
  .slick-cards-cover .slick-list {
    // width: 360px;
  }
  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    padding-left: 10px;
    margin-left: 10px;
  }
  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    column-gap: 5px;
  }
}
@media (max-width: 1023px) {
  .slick-cards-cover .slick-list {
    // width: 130%;
  }
}

@media (max-width: 991px) {
  .slick-cards-cover .DetailsMakaniCardBtns {
    column-gap: 8px;
  }
  .slick-cards-cover .slick-list {
    // width: 110%;
  }

  .slick-cards-cover .navigateWarper {
  }
  .slick-cards-cover .slick-list {
    // width: 100%;
  }
}

@media (max-width: 767px) {
  .slider-cover-main .poiMenu {
    padding-right: 20px;
  }
  .slider-cover-main {
    .searchTitleWarper,
    .poiMenu,
    .PageHeaderTitleWarper {
      margin: 0 0px;
    }
    .makaniCardDetails {
      padding-left: 20px;
    }
    .makaniCardDetails {
      padding-left: 20px !important;
    }
    .slick-cards-cover,
    .makaniCardDetails {
      padding-left: 0 !important;
    }
  }
  .slick-cards-cover .navigateWarper {
    width: 48%;
  }
  .MakaniCardHeader .love {
    width: 18px !important;
  }
  .slick-cards-cover .MakaniCardHeader .copyWrapper {
    padding-left: 14px;
    margin-left: 14px;
  }
  .slick-cards-cover .MakaniCardHeader {
    min-height: 50px;
  }
  .DetailsMakaniCardBtns {
    padding: 10px 15px 16px;
  }
  .slick-cards-cover {
    .MakaniCardHeader {
      padding: 6px 15px;
    }
  }
  .slick-cards-cover .slick-list {
    // width: 107%;
  }
  .DeatilsMakaniCards .makaniCardNumber,
  .DeatilsMakaniCards .favoriteItemMakaniNumber {
    font-size: var(--font-size-18);
  }
  .slick-cards-cover .MakaniCardHeader .logo {
    width: 67px;
    height: auto;
  }
  .slick-cards-cover {
    .deatilsMakaniCard {
      border-radius: 15px;
      .MakaniCardContent {
        min-height: 106px;
        display: flex;
        flex-direction: column;
      }
    }
    .DetailsMakaniCardBtns {
      margin-top: auto;
      column-gap: 11px;
    }
  }
  .slick-controls {
    display: none;
  }
  .slick-cards-cover .slick-slider .deatilsMakaniCard {
    left: -56px !important;
  }
  .searchTitle {
    font-size: var(--font-size-16);
  }
  .searchAddress {
    font-size: var(--font-size-12);
  }
  .makaniCardDetailsTitle {
    font-size: var(--font-size-14);
  }
  .makaniCardDetailsAddressTitle,
  .makaniCardDetailsSubAddress,
  .parcelNumberValue {
    font-size: var(--font-size-12);
    line-height: 1.2;
  }
  .contactDetailsWrapper p {
    font-size: var(--font-size-12);
  }
  .slick-cards-cover .DetailsMakaniCardBtns .DetailsMakaniCardBtn {
    width: 38px;
    height: 38px;
    flex-grow: 1;
    border-radius: 8px;
  }
  .slick-cards-cover .navigateWarper {
    // width: 92px;
    height: 38px;
    border-radius: 8px;
  }
  .slick-cards-cover .slick-list {
    // width: 102%;
    padding-left: 48px !important;
  }
}
@media (max-width: 479px) {
  .slick-cards-cover .DetailsMakaniCardBtn img,
  .slick-cards-cover .DetailsMakaniCardBtn svg {
    width: 20px;
  }
  .slick-cards-cover .MakaniCardContentText {
    font-size: var(--font-size-14);
  }
}
