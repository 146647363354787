@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}

.menuContent {
  padding: 45px 40px 59px;
  // height: calc(100% - 191px);
  overflow-x: hidden;
  background-color: var(--menu-bg);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.sideBarHeader,
.menuHeader {
  height: 191px;
  background-color: var(--sidebar-header-bg);
  padding: 40px 40px;
}
.menuContent ul {
  list-style: none;
  padding: 0;
}
.menu {
  width: 499.19px;
  height: 100vh;
  position: absolute;
  z-index: 102;
}
.closeWarper img {
  width: 14px;
  height: 14px;
}
.btn-menu-icon {
  width: 21.04px;
  height: auto;
  position: absolute;
}
.menu {
  position: absolute;
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  color: var(--menu-color);
  .icon-m {
    color: var(--menu-icon);
  }
  .menuItemTitle {
    font-size: var(--font-size-17-6);
  }
}
.menuItem img {
  padding-bottom: 6px;
}

.menuItemTitle {
  color: var(--menu-item);
}

.menuItem img {
  padding-bottom: 6px;
}

.menuHeader .menuBtn {
  position: relative;
  z-index: 9;
}
.menuItem .menuItemTitle {
  @include apply-font-family(medium);
}
.menuItem {
  .icon-m {
    // width: 18px;
  }
}

.menuItem {
  &:hover {
    .menuItemTitle,
    .icon-m {
      color: #009d91;
      transition: 0.2s;
    }
  }
}

.menu-button {
  background-color: #009d91;
  width: 100px;
}

.menuItem.sub-menu {
  cursor: pointer;
  position: relative;
  margin-bottom: 30;

  &::after {
    content: "";
    border: solid #999da7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: transform 0.3s ease;
    margin-left: auto;
  }
  &.arrow-up:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0;
  }
}
.menuContent > ul {
  margin-bottom: 57px;
}
ul.sub-menu {
  transition: transform 0.3s ease;
  margin-left: 12px;
  margin-top: 10px;

  padding-left: 20px;
  position: relative;
  &:before {
    content: "";
    background-color: rgba(51, 60, 79, 1);
    position: absolute;
    width: 1px;
    height: 90%;
    left: 0;
    top: 0;
  }
  .nav-item {
    color: var(--menu-item);
    font-size: var(--font-size-15);
    @include apply-font-family(regular);
    padding: 15px;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 2px;

    &.active,
    &:hover {
      background-color: #333c4f !important;
      border-radius: 6px;
    }
  }
}

// menu animations

.menu {
  -webkit-transform: translate3d(-499.19px, 0, 0);
  -moz-transform: translate3d(-499.19px, 0, 0);
  -ms-transform: translate3d(-499.19px, 0, 0);
  -o-transform: translate3d(-499.19px, 0, 0);
  transform: translate3d(-499.19px, 0, 0);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.3s;
  -moz-transition: -moz-transform 0.5s, opacity 0.3s;
  -ms-transition: -ms-transform 0.5s, opacity 0.3s;
  -o-transition: -o-transform 0.5s, opacity 0.3s;
  transition: transform 0.5s, opacity 0.3s;
  display: block !important;
  opacity: 0;

  &.side_menu {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &:not(.side_menu) .logo {
    opacity: 0;
  }
}

[dir="rtl"] .menu {
  -webkit-transform: translate3d(499.19px, 0, 0);
  -moz-transform: translate3d(499.19px, 0, 0);
  -ms-transform: translate3d(499.19px, 0, 0);
  -o-transform: translate3d(499.19px, 0, 0);
  transform: translate3d(499.19px, 0, 0);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.3s;
  -moz-transition: -moz-transform 0.5s, opacity 0.3s;
  -ms-transition: -ms-transform 0.5s, opacity 0.3s;
  -o-transition: -o-transform 0.5s, opacity 0.3s;
  transition: transform 0.5s, opacity 0.3s;
  display: block !important;
  opacity: 0;

  &.side_menu {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &:not(.side_menu) .logo {
    opacity: 0;
  }
}

// dark theme custom
[data-theme="dark"] {
  ul.sub-menu:before {
    background-color: #6f7385;
  }
}

[dir="rtl" i] {
  ul.sub-menu {
    transition: transform 0.3s ease;
    margin-right: 10px;
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-right: 20px;
    position: relative;
  }
  ul.sub-menu:before {
    right: 0;
    left: inherit;
  }
  .menuItem.sub-menu::after {
    margin-right: auto;
    margin-left: inherit;
  }
}

[dir="ltr" i] {
  .menu {
    // animation: animateleft 0.4s;
  }

  @keyframes animateleft {
    from {
      left: -100px;
      opacity: 0;
    }

    to {
      left: 0;
      opacity: 1;
    }
  }
}

[dir="rtl" i] {
  .menu {
    // animation: animateright 0.4s
  }

  @keyframes animateright {
    from {
      right: -100px;
      opacity: 0;
    }

    to {
      right: 0;
      opacity: 1;
    }
  }
}

.lang-otr {
  margin-top: auto;
}
.menuItem .icon-m {
  svg {
    width: 20px;
  }
}

@media (max-width: 1679px) {
  .sideBarHeader,
  .menuHeader {
    padding: 28px;
  }
  .sideBarHeader,
  .menuHeader {
    // height: 155px;
  }
  .menuContent {
    // height: calc(100% - 155px);
  }
  .menuContent {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 30px;
  }
}

@media (max-width: 1479px) {
  .sideBarHeader,
  .menuHeader {
    // height: 150px;
  }
  .sideBarContent,
  .menuContent {
    // height: calc(100% - 150px);
  }
  .menu .logo img {
    max-width: 125px;
  }
  .menuItem {
    margin-bottom: 25px;
  }
  .menuItem .menuItemTitle {
    font-size: var(--font-size-15);
  }
  .menuContent {
    padding-bottom: 20px;
  }
}
@media (max-width: 1281px) {
  ul.sub-menu .nav-item {
    font-size: var(--font-size-12);
  }

  .menuContent {
    padding: 45px 28px 27px;
  }
  .menuItem .menuItemTitle {
    font-size: var(--font-size-13);
  }
  .menuContent > ul {
    margin-bottom: 35px;
  }
  .menuItem {
    gap: 15px;
    .icon-m {
      svg {
        width: 13px;
      }
    }
  }
  .menuItem {
    margin-bottom: 18px;
  }
  .btn-menu-icon {
    width: 15px;
  }
  ul.sub-menu .nav-item {
    padding: 13px;
  }
  .menuHeader,
  .sideBarHeader {
    // padding: 23.41px 26.34px;
  }
  .sideBar,
  .meun {
    // width: 40.5%;
  }
  .sideBarHeader,
  .menuHeader {
    // height: 130px;
  }
  .sideBarContent,
  .menuContent {
    // height: calc(100% - 130px);
  }
  .menu .logo img {
    max-width: 85px;
  }
  ul.sub-menu {
    margin-bottom: 0px;
  }
}

@media (max-width: 1100px) {
  .menu {
    background-color: #4e5972;
    border-radius: 0 !important;
    .logo {
      width: 100%;
    }
  }
  .menuHeader {
    // height: auto;
    // min-height: 190px;
    // padding: 0 24px;
    // display: flex;
    // align-items: center;
  }
  .menu .logo img {
    max-width: 123.84px;
  }
}

@media (max-width: 767px) {
  .lang-otr {
    margin-left: 40px;
  }
  .menuItem {
    gap: 22px;
  }
  .menuItem .icon-m svg {
    width: 21px;
  }
  .menuHeader,
  .sideBarHeader {
    padding: 30px;
  }
  ul.sub-menu .nav-item {
    padding: 10px;
    font-size: var(--font-size-13);
  }
  .menuContent {
    // height: calc(100% - 160px);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
  }
  .menuItem .menuItemTitle {
    font-size: var(--font-size-16);
  }
  .menuItem {
    margin-bottom: 20px;
  }
  .menuHeader {
    height: 160px;
  }
  .menuHeader .menuBtn img {
    width: 20px;
  }
  .menuContent ul {
    margin-bottom: 0;
  }
  .lang-otr {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  [data-size="2"] {
    .menu {
      width: 570.15px;
      transform: translate3d(-570.15px, 0, 0);
    }
    .menu.side_menu {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

@media (min-width: 1200px) {
  [dir="rtl"] {
    [data-size="2"] {
      .menu {
        transform: translate3d(570.15px, 0, 0);
        opacity: 1;
      }
      .menu.side_menu {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}
