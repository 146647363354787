.myMakaniCardsWarper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 30px;
  color: #c6c6c6;
}
.myMakaniCard {
  margin: 0 15px;
  cursor: pointer;
}

/* .myMakaniCardHeader {
  background: var(--element-light-bg);
  display: flex;
  border: 0.39px solid var(--toggle-border);
  justify-content: space-between;
  border-radius: 8.81px;
  margin-bottom: 10px;
}

.myMakaniCardContent {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;
} */


.myMakaniCardContentDetails {
  display: flex;
  flex-direction: column;
  gap: 3px;
}



.qrCodeImage {
  position: absolute;
  right: 25px;
  top: 30px;
  width: 58.72px;
  height: 58.72px;
}

@media screen and (max-width: 1100px) {
  .myMakaniCardBtns div {
    /* min-width: 120px; */
  }
}

.myMakaniCardHeader:active {
  border: 1.82px solid #4e59728f;
}
