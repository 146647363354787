/* .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
} */

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 15px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 77%;
  position: absolute;

  left: 35px;
  z-index: 400;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.closeWrapper {
  display: flex;
  justify-content: space-between;
}
.popTitle {
  font-size: var(--font-size-18-3);
}
.icon {
  width: 50px;
  height: 50px;
  background: var(--makani-card-btn);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.popup .content {
  max-height: 90%;
  display: flex;
  gap: 30px;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}
