@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.sitemaplist{
    

    li{
        position: relative;
        display: flex;
        column-gap: 19.71px;
        padding-bottom: 30.89px;
        margin-bottom: 1px;

        &::before{
            content: "";
            background-color:#333C4F;
            width: 1px;
            position: absolute;
            left:12px;
            top:26px;
            bottom: 0;
        }
        
        &:last-child{
            &::before{ 
                display: none;
            }
        }
        
        .bulletscircle{
            display: flex;
            align-items: center;
            justify-content: center;
        background-color:#333C4F;
        width:24.23px;
        height:24.23px ;
        border-radius: 50%;
        z-index: 1;
        flex-shrink:0;
        img{
            // width: 12px;
        }
        }
        .rightcontent{
      
           margin-top: 5px;
            .title_small{
                @include apply-font-family(semibold);
             font-size: var(--font-size-14);
             margin-bottom: 4.5px;
             color: var(--main-text-color);

            }
            .para_md{
                @include apply-font-family(regular);
                font-size: var(--font-size-15);
                color: var(--main-text-color);
            }
        }

    }
   
}

@media(max-width:1679px){

    .sitemaplist li {

        padding-bottom: 20px;
    }
    .sitemaplist li .rightcontent .para_md{
        font-size: var(--font-size-14);
        line-height: 19px;
    }
   
}
@media (max-width: 991px) {
    .sitemaplist li .rightcontent .para_md{
        font-size: var(--font-size-13);
    }
}



// arabic mode 
[dir=rtl]{
    li{
      &::before{
            
            left:auto;
            right:12px;
            
        }
}
}

[data-theme=dark] {
.sitemaplist li .bulletscircle{
    background-color: #6a7699;
}   
.sitemaplist li::before {
    background-color: #6a7699;
}
}