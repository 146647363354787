.spin-wrap{
    border-radius: 50%;
    display: flex;
  }
  .center-point{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(0, 55, 176, 1);
    border: 2.35px solid #fff;
    -webkit-box-shadow: 0px 0px 11px 1px rgba(0,194,255,1);
    -moz-box-shadow: 0px 0px 11px 1px rgba(0,194,255,1);
    box-shadow: 0px 0px 11px 1px rgba(0,194,255,1);
   }
  
  .spinner{
    &-wrap{
      position: relative;
      display: inline;
    border-radius: 50px;
    }
    
    &-item{
      background-color:rgba(57, 52, 206, 0.11);
      border-radius: 50%;
      height: 90px;
      width: 90px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: zoom 2s linear 0.75s infinite;
      display: flex;
      align-items: center;
      justify-content: center;
     
      &--2{
        animation-delay: 1.25s;
      }
      
      &--3{
        animation-delay: 1.75s;
      }
    }
  }
  
  @-webkit-keyframes zoom {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.2);
        transform: translate(-50%, -50%) scale(0.2);
        opacity: 0;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
  }
  
  @keyframes zoom {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.2);
        transform: translate(-50%, -50%) scale(0.2);
        opacity: 0;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
  }