.pointOfInternetSearchSuggestions {
  display: flex;
  /* overflow-x: scroll; */
  gap: 15px;
}

.pointOfInternetSearchSuggestions::-webkit-scrollbar {
  display: none;
}

.pointOfInternetIcon {
  width: 56.78px;
  height: 56.78px;
  border-radius: 50%;
  background-color: var(--element-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}
