@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.menu .logo,
.sideBar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // gap: 23px;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 56.75px;

  img {
    max-width: 165px;
    height: auto;
  }
}
.header-title {
  color: var(--element-light-bg);
  margin-top: 22.15px;
  @include apply-font-family(light);
  font-size: var(--font-size-14-5);
  white-space: nowrap;
}
.sideBar .logo {
  height: auto;
  img {
    // max-width: 165px;
    // width: 100%;
  }
}

.sideBarHeader {
  .menu__btn {
  }
}

.sideBarHeader,
.menuHeader {
  .menu__btn {
    width: 28px;
    height: auto;
    position: absolute;
  }
}

// dark theme custom
[data-theme="dark"] {
  .header-title {
    color: #ffffff;
  }
}
.sideBarHeader .menu__btn,
.menuHeader .menu__btn {
  z-index: 9;
}
@media (max-width: 1679px) {
  .menu .logo img,
  .sideBar .logo img {
    max-width: 155px;
  }
  .menu .logo,
  .sideBar .logo {
    top: 44px;
  }
  .menu .logo img,
  .sideBar .logo {
    img {
      // max-width: 125px;
    }
  }

  .header-title {
    font-size: 14px;
    margin-top: 15px;
  }
}
@media (max-width: 1479px) {
  .sideBar .logo img {
    // max-width: 125px;
    // width: 100%;
  }
  .header-title {
    font-size: var(--font-size-14);
  }
  .menu .logo,
  .sideBar .logo {
    height: auto;
  }
  .menu .logo img,
  .sideBar .logo img {
    max-width: 115px;
  }
}

@media (max-width: 1281px) {
  .menu .logo,
  .sideBar .logo {
    top: 35px;
  }
  .sideBarHeader .menu__btn,
  .menuHeader .menu__btn {
    width: 21px;
  }

  .sideBar .logo img {
    // max-width: 85px;
  }
  .header-title {
    font-size: var(--font-size-12);
  }
  .sideBarHeader.menuHeader {
    + div {
      img {
        width: 25px;
      }
    }
  }
  .menuHeader {
    + div {
      img {
        width: 20px;
      }
    }
  }
  .menu .logo {
    // row-gap: 5px;
  }
  .menu .logo img,
  .sideBar .logo img {
    // max-width: 85px;
  }
}

@media (max-width: 991px) {
  .menu .logo {
    // row-gap: 0;
  }
  .menuHeader {
    .header-title {
      // margin-top: 17px;
    }
  }
}
@media (max-width: 767px) {
  .header-title {
    margin-top: 16.61px;
  }
  .menu .logo,
  .sideBar .logo {
    margin-top: 0;
  }
  .menu .logo img,
  .sideBar .logo img {
    max-width: 123.84px;
  }
  .head-logo {
    position: relative;
    margin-left: -11px;
    width: 95px;
    top: 3px;
  }
  .menu .logo,
  .sideBar .logo {
    top: 57px;
  }
}
