.pageTitleHeader {
  display: flex;
  /* margin-bottom: 28px; */
  flex-direction: column;
}

.closeWarper {
  width: 25px;
  height: 25px;
  background: var(--close-wraper);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  display: none;
  cursor: pointer;
}

.closeWarper img {
  width: 14px;
  height: 14px;
}



.backPage {
  background: var(--element-bg);
  border-radius: 7px;
  width: 46.75px ;
  height: 46.75px ;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .backPage {
    width: 50px;
    height: 50px;
    background: var(--reponsive-header-wraper);
    border-radius: 50%;
  }
}
