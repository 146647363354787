@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.searchByBuildingBtnWarper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
}

.searchByBuildingBtn {
  width: 227.51px;
  height: 48.02px;
  background: var(--makani-card-btn);
  color: var(--element-light-bg);
  border-radius: 7.31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.searchByBuildingBtnActive {
  @extend .searchByBuildingBtn;
  background: var(--makani-card-btn-active);
}

.custom-select {
  position: relative;
  // width: 403.57px;
  max-width: 100%;
  .select-button {
    min-width: auto;
  }
}
.custom-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  padding: 20px;
  z-index: 100;
  height: auto;
  max-height: 63vh;
  overflow-y: auto;
}
.select-button {
  background-color: var(--element-light-bg);
  border-radius: 9.14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  width: 100% !important;
  max-width: 100rem !important;
  height: 43px;
  padding: 0 15px;
}

.selected-value {
  @include apply-font-family(regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 15.87px;
  text-align: left;
  color: var(--line-color2);
}

// .select-dropdown:focus-within {
//   // box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
// }

.select-dropdown li {
  height: 41px;
  // width: 354.79px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--element-light-bg);
  margin-bottom: 7px;
  border-radius: 7.32px;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 11.94px;
  padding-left: 10px;
  padding-right: 10px; //NOTE
  color: var(--secandary-text-color);
}
.select-dropdown li label {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: 25px;
}

.select-dropdown li:hover {
  background-color: var(--title-color);
  color: var(--element-light-bg);
}

.select-dropdown {
  // position: absolute;
  list-style: none;
  width: 100%;
  background: var(--drop-down-color);
  border-radius: 4px;
  overflow-y: auto;
  visibility: hidden;
  padding: 20px;
  opacity: 0;
  height: auto;
}

.select-dropdown input:checked ~ label {
  background-color: #f2f2f2;
}

.select-dropdown input:focus ~ label {
  background-color: #dfdfdf;
}

.select-dropdown input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
}

.custom-select.active .arrow {
  transform: rotate(180deg);
}

.custom-select.active {
  background: var(--drop-down-color);
  border-top-left-radius: 9.14px;
  border-top-right-radius: 9.14px;
}

@media (max-width: 1100px) {
  .custom-select {
    .select-button {
      height: 38px;
      border-radius: 10px;
      width: 100%;
    }
    .selected-value {
      font-size: var(--font-size-14);
    }
  }
  .searchByBuildingBtn {
    height: 42px;
    font-size: var(--font-size-14);
    width: 200px;
  }
}
