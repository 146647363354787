.contactIconsWarper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.contactIconsWarper p {
  font-family: 'VisbyRegular' , sans-serif;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 22.87px;
  text-align: left;
  color: #838383;
}

.contactItem {
  display: flex;
  gap: 10px;
}

.contactIconWarper {
  width: 20.58px;
  height: 20.58px;
  background: #4e5972;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.DetailsMakaniPageBtns {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  margin-top: 10px;
}
.makaniPageLogo {
  width: 122.33px;
  height: 43.44px;
  border-radius: 9.15px;
  background: var(--makani-card-btn);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MakaniPageContentText {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.MakaniPageContent {
  margin: 0 20px;
}
