@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.MakaniCardLogo img {
  height: auto;
}
.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12.56px;
}

.searchCards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 18px;
  position: relative;
  margin-bottom: 43.53px;
  margin-top: 30px;
  .searchCard {
    // width: 121.88px;
    height: 149.22px;
    background: var(--element-light-bg);
    display: flex;
    cursor: pointer;
    border-radius: 11px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 121.88px;
    box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);

    .searchCardTitle {
      color: var(--secandary-text-color);
      padding: 14.24px 11.38px;
      font-weight: 500;
      @include apply-font-family(spartan-regular);
      font-size: var(--font-size-16-half);
      line-height: 1.1;
    }
    .searchCardFooter {
      display: flex;
      justify-content: space-between;
      position: relative;
    }
  }
}

.searchCardFooterLogo {
  position: absolute;
  bottom: 11.39px;
  left: 12.53px;
  color: var(--tile-icon-color);
}

.rectangle {
  position: absolute;
  bottom: -5px;
  right: -1px;
  color: var(--bg-main);
  .arrow {
    color: var(--color-white-dark);
  }
}
.rectangle2 {
  position: absolute;
  bottom: -9px;
  right: -2px;
  color: var(--bg-main);
  svg.smallArrow {
    width: auto;
    color: var(--color-white-dark);
    bottom: 14px;
    right: 6px;
  }
}

.pointOfInternetContainer {
  margin-bottom: 25px;
}

.pointOfInternetHeader,
.makaniCardsHeader,
.favouritesHeader,
.favoritesHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.favoritesList {
  background: var(--element-light-bg);
  padding: 15px;
  border-radius: 11px;
  margin-top: 20px;
  box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);
}
.favorites {
  margin-bottom: 40px;
}

.firstFavoritesItem {
  padding-top: 0 !important;
}
.favoritesItemLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}
.favoriteItemLogo {
  // background-color: var(--makani-card-btns);
  background-color: var(--bg-main);
  width: 43.38px;
  height: 43.38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white-dark);
  flex-shrink: 0;
}
.favoriteTitle {
  // color: var(--favorite-title);
  color: var(--main-text-color);
  margin-bottom: 3px;
  @include apply-font-family(semibold);
}
.favoriteTitleEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  vertical-align: middle;
  white-space: nowrap;
}
.favoriteItemMakaniDistance {
  @include apply-font-family(regular);
  color: var(--green);
  span {
    @include apply-font-family(semibold);
  }
}

.ReportCard p,
.settingCard,
.aboutCard {
  @include apply-font-family(spartan-regular);
  font-weight: 500;
  font-size: var(--font-size-18);
  overflow: hidden;
  svg {
    color: var(--tile-icon-color);
    width: 33px;
  }
}
.favoritePOI {
  color: var(--element-text-color);
  @include apply-font-family(regular);
}
.makaniCardNumber,
.favoriteItemMakaniNumber {
  color: var(--color-dark-lightGry);
  @include apply-font-family(semibold);
  font-weight: 500;
  font-size: var(--font-size-18-3);
  line-height: 18.08px;
  margin-bottom: 5px;
  transition: 0.2s;
}
.makaniCardDistance span {
  @include apply-font-family(semibold);
}
.favoriteItemMakaniDistance {
  text-align: end;
}

.favoritesItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--line-color);
  padding-bottom: 12px;
  padding-top: 12px;
}

.lastFavoritesItem {
  border-bottom: none;
  padding-bottom: 5px;
}
.infoCards {
  display: flex;
  gap: 15px;
}

.ReportCard {
  position: relative;
  height: 110px;
  background: var(--element-light-bg);
  padding-top: 15px;
  padding-left: 15px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 11px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);
}
.settingAboutCards {
  flex: 1 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.settingCard,
.aboutCard {
  color: var(--secandary-text-color);
  box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);
}
.makaniCardsContent {
  .MakaniCardLogo {
    img,
    svg {
      width: 85.62px;
    }
  }
}

.pointOfInternetContent {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;

  .pointOfInternetItem {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 25%;
    hyphens: auto;
  }
  .pointOfInternetTitle {
    margin-top: 10px;
  }
  .pointOfInternetIcon {
    box-shadow: 0px 0px 11.36px 0px rgba(0, 0, 0, 0.25);
    color: var(--color-white-gry);
    img,
    svg {
    }
  }
}

.pointOfInternetIcon {
  width: 56.78px;
  height: 56.78px;
  border-radius: 50%;
  background-color: var(--element-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointOfInternetTitleContent {
  display: flex;
  gap: 14.4%;
  color: var(--element-text-color);
  font-weight: 400;
  font-size: var(--font-size-14);
  margin-top: 8px;
}

.pointOfInternetTitleContent2 {
  display: flex;
  gap: 21%;
}

.pointOfInternetTitleContent3 {
  display: flex;
  gap: 24%;
  font-weight: 400;
  font-size: var(--font-size-14);
  line-height: 13.98px;
  color: var(--element-text-color);
  @include apply-font-family(regular);
}

.pointOfInternetTitle {
  font-weight: 300;
  font-size: var(--font-size-13);
  line-height: 13.98px;
  @include apply-font-family(regular);
  overflow: hidden;
  // overflow: visible;
  text-overflow: ellipsis;
  // white-space: nowrap;
  word-wrap: break-word;
  white-space: normal;
  hyphens: auto;
  padding: 10px 3px;
  width: 100%;
  color: #838383;
  position: relative;
  padding: 10px 3px;
  width: 100%;
  overflow: hidden;
  white-space: normal;
  max-height: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.seeMoreTitle {
  @include apply-font-family(regular);
  color: var(--title-color);
  font-weight: 400;
  font-size: var(--font-size-13);
  line-height: 13.01px;
  cursor: pointer;
}
.searchContainer .searchInput {
  font-size: var(--font-size-15-5);
}

.makaniCardTitle {
  // color: var(--color-lightGry-darkGry);
  color: var(--element-text-color);
  @include apply-font-family(regular);
  font-weight: 400;
  font-size: var(--font-size-13-7);
  line-height: 1.2;
  margin-bottom: 4px;
  transition: 0.2s;
}

.makaniCardDistance {
  @include apply-font-family(regular);
  color: var(--green);
  font-size: var(--font-size-16);
  line-height: 15.98px;
}
.MakaniCardBtn {
  background-color: var(--makani-card-btns);
  width: 49.96px;
  height: 49.96px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}
.MakaniCardBtn {
  color: var(--color-white-dark);
  img,
  svg {
    width: 20px;
    height: 20px;
  }
}

.searchInputWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--element-light-bg);

  -webkit-box-shadow: 0px 0px 0px 1px rgb(51 60 79 / 14%);
  -moz-box-shadow: 0px 0px 0px 1px rgb(51 60 79 / 14%);
  box-shadow: 0px 0px 0px 1px rgb(51 60 79 / 14%);

  border-radius: 7.48px;
  padding: 12.5px 10px 10px;
  height: 39px;
  flex-grow: 1;
  .searchIcon {
    color: var(--search-color);
  }
}
.searchInput {
  outline: none;
  border: none;
  background: transparent;
  color: var(--main-text-color);
  &::placeholder {
    color: var(--search-color);
  }
}

.searchInputWrapper {
  position: relative;
  // z-index: 1;
  // overflow: hidden;

  .searchButton {
    position: absolute;
    z-index: 9;
    top: 9px;
  }
}
.searchContainer .searchInput {
  width: auto !important;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: 0.5px;
  right: 0.5px;
  bottom: 0.5px;
  padding-left: 38px;
  border-radius: 7.48px !important;
  transition: 0.2s;
  padding-right: 38px;
  &:focus {
    border-color: #333c4f;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(238 247 248 / 69%);
  }
}
.border-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  background: #333c4f54;
  z-index: -1;
  transition: height 0.3s, width 0.3s 0.1s;
  border-radius: 7.48px;
}

// dark theme custom
[data-theme="dark"] {
  .searchContainer .searchInput {
    background-color: #1e222b;

    &:focus {
      border-color: #a5aab3;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(67 79 103);
    }
  }
  .border-input {
    background: #a7bbe7a6;
  }
}
// arabic mode
[dir="rtl"] {
  .searchContainer .searchInput {
    padding-right: 38px;
  }
  .clear-search {
    right: auto;
    left: 10px;
  }
}

[dir="rtl"] {
  .clear-search-indoor-nav {
    right: auto;
    left: 75px;
  }
}

.profile {
  width: 39px;
  height: 39px;
  border-radius: 7.48px;
  background: var(--element-bg);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: var(--color-white-gry);
}

.searchContainer {
  .profile {
    img,
    svg {
      width: 15px;
    }
  }
}

.MakaniCard {
  display: flex;
  width: 100%;
  background: var(--element-light-bg);
  margin: 15px 0;
  padding: 15px 15.98px;
  align-items: center;
  border-radius: 11px;
}
.MakaniCardLogo {
  padding-right: 10.42px;
}
.MakaniCardLogoAr {
  img {
  }
}
.MakaniCardInfo {
  padding-left: 15.98px;
  border-left: 1.14px solid var(--vertical-line);
}

.MakaniCardBtns {
  display: flex;
  gap: 14.84px;
  margin-left: auto;
  .MakaniCardBtn {
    background-color: var(--bg-main);
  }
}
.MakaniCardBtnsAr {
  .MakaniCardBtn {
    background-color: var(--bg-main);
  }
}
.MakaniCardContentText {
  @include apply-font-family(semibold);
}

.ReportCardFooter > svg {
  width: 30px;
  color: var(--tile-icon-color);
}

.searchCards .searchCard {
  // transition: .2s;
  // &:hover{
  //   margin-top: -3px;
  // }
}

.MakaniCardBtn {
  transition: 0.2s;
}

.pointOfInternetContainer {
  .pointOfInternetIcon {
    cursor: pointer;
    img,
    svg {
      transition: 0.2s;
      height: 42px;
    }
    &:hover {
      background-color: #009d91;
      img,
      svg {
        color: #fff;
        position: relative;
        transform: scale(1.2);
      }
    }
  }
}
.makaniCards {
  margin-bottom: 43.53px;
}
.makaniCardsContent {
  .MakaniCard {
    height: auto;
    min-height: auto;
    margin-bottom: 22.83px;
    box-shadow: 0 0 11.39px 0 rgba(0, 0, 0, 0.1);
  }
  .MakaniCardBtns {
    // padding-right: 13px;
  }
  .MakaniCardBtnsAr {
    // padding-left: 13px;
  }
}
.searchContainer {
  .profile {
    img,
    svg {
      transition: 0.2s;
    }
    &:hover {
      img,
      svg {
        transform: scale(1.2);
      }
    }
  }
}
.seeMoreTitle {
  transition: 0.2s;
  &:hover {
    color: var(--main-text-color);
  }
}
.MakaniCard {
  transition: 0.2s;
  &:hover {
    background: #eeeeee;
    .makaniCardNumber {
      color: var(--secandary-text-color);
    }
  }
}
.MakaniCardBtn {
  transition: 0.2s;
  &:hover {
    background-color: #009d91;
    svg {
      color: #fff;
    }
  }
}
.profile {
  transition: 0.2s;
  &:hover {
    background-color: #009d91;
    color: #fff;
  }
}

.searchCards .searchCard {
  .searchCardFooterLogo,
  .rectangle,
  .searchCardTitle {
    transition: 0.2s;
  }
  &:hover {
    .searchCardTitle {
      color: #009d91;
    }
    .searchCardFooterLogo {
      color: #009d91;
    }
    .rectangle {
      color: #009d91;
    }
  }
}
.MakaniCard {
  transition: 0.2s;
  &:hover {
    background-color: var(--box-hover);
    .makaniCardTitle {
      color: var(--secandary-text-color);
    }
  }
}

[data-theme="dark"] {
  .lineWarper .line .marker {
    border: 2px solid #333c4f !important;
    -webkit-box-shadow: 0px 0px 0px 2.11px rgb(68 76 94);
    -moz-box-shadow: 0px 0px 0px 2.11px rgb(68 76 94);
    box-shadow: 0px 0px 0px 2.11px rgb(68 76 94);
  }
  .pointOfInternetTitle {
    mix-blend-mode: normal;
    color: #ffffff;
    text-shadow: none;
  }
  .MakaniCardLogo img,
  .MakaniCardLogoAr img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
  .MakaniCard {
    transition: 0.2s;
    &:hover {
      .MakaniCardBtn {
        transition: 0.2s;
        background-color: #737e98;
        color: #cfd5f2;
        &:hover {
          background-color: #009d91;
        }
      }
    }
  }
  .pointOfInternetContainer .pointOfInternetIcon img,
  .pointOfInternetContainer .pointOfInternetIcon svg {
    filter: invert(0.3) sepia(1) saturate(4) hue-rotate(181deg);
    -webkit-filter: invert(0.3) sepia(1) saturate(4) hue-rotate(181deg);
  }
  .pointOfInternetContainer .pointOfInternetIcon:hover {
    img,
    svg {
      filter: none;
      -webkit-filter: none;
    }
  }
}
.favoritesItemWrapper {
  .favoriteItemLogo {
    transition: 0.2s;
  }
  .favoritesItem {
    position: relative;
    &:after {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -2px;
      transition: 0.2s;
      background-color: #009d91;
    }
    &:hover {
      &:after {
        content: "";
        width: 100%;
      }
      .favoriteItemLogo {
        background-color: #009d91;
        color: #fff;
      }
    }
  }
}
.ReportCardFooter {
  height: 43px;
  img,
  svg {
    transition: 0.2s;
  }
}

.ReportCard {
  p {
    transition: 0.2s;
  }
  &:hover {
    p {
      color: #009d91;
    }
    .ReportCardFooter {
      img,
      svg {
        color: #009d91;
      }
    }
    .arrow {
      color: #fff !important;
    }
    .rectangle {
      svg {
      }
    }
  }
}

.settingCard,
.aboutCard {
  p {
    transition: 0.2s;
  }
  img,
  svg {
    transition: 0.2s;
  }
  &:hover {
    p {
      color: #009d91;
    }
    img,
    svg {
      color: #009d91;
    }

    .smallArrow {
      color: #fff !important;
    }
  }
}

.searchCards .searchCard {
  overflow: hidden;
}

.searchContainer {
  .searchInput {
    @include apply-font-family(regular);
    width: 100%;
  }
}
.MakaniCardContentDesinence {
  position: relative;
  white-space: nowrap;
  .directions {
    position: absolute;
    left: -13px;
    top: 2px;
    color: #838383;
    img,
    svg {
      width: 10px;
    }
  }
}

// arabic mode
[dir="rtl"] {
  .infoCards {
    .ReportCard {
      direction: ltr;
      padding-left: 0;
      position: relative;
      overflow: hidden;
      p {
        text-align: right;
        width: 100%;
        padding-right: 15px !important;
        padding-top: 15px !important;
        padding-left: 10px;
        color: var(--secandary-text-color);
      }
    }
  }

  .signWarper .close_btn {
    left: 20px;
    right: inherit;
  }
  .madal-cover .modal-dialog .modal-content .modal-body .root-info {
    padding-right: 0;
    padding-left: 13.37px;
  }
  .madal-cover
    .modal-dialog
    .modal-content
    .modal-body
    .root-info
    .right-content {
    margin-left: inherit;
    margin-right: 14.36px;
  }
  .madal-cover
    .modal-dialog
    .modal-content
    .modal-body
    .root-info
    .right-content
    .inr-content {
    padding-right: 0;
  }
  .settingCard,
  .aboutCard {
    flex-direction: row !important;
    padding-right: 15px;
  }
  .infoCards {
    .rectangle2 {
      bottom: -6px;
      right: inherit;
      left: -2px;
      transform: rotate(90deg);
    }
    .ReportCardFooter {
      padding-right: 15px;
      display: flex;
    }
    .rectangle {
      transform: rotate(90deg);
      bottom: 10px;
      left: 20px;
      top: 0;
      right: inherit;
      .arrow {
        bottom: -23px;
        right: 12px;
        transform: rotate(90deg);
      }
    }
    .rectangle2 svg.smallArrow {
      transform: rotate(90deg);
      bottom: auto;
      left: 18px;
      top: 18px;
      right: inherit;
    }
  }

  .MakaniCard.no-data {
    .MakaniCardBtnsAr {
      column-gap: 16.21px;
      display: flex;
      margin-right: auto;
      padding-left: 0;
    }
  }
  @media (max-width: 1281px) {
    .MakaniCard.no-data {
      .MakaniCardBtnsAr {
        column-gap: 8px;
      }
      .MakaniCardInfoAr {
        padding-right: 10px;
      }

      .MakaniCardLogoAr img {
        width: 50px;
      }
    }
  }
  @media (max-width: 1199px) {
    .makaniCardsContent .MakaniCardBtnsAr {
      column-gap: 8px;
    }
  }
}
.no-data-makani {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 310px;

  img {
    width: 200px;
  }
  p {
    color: #333c4f;
    font-size: 16px;
    margin-top: 15px;
  }
}

// no data
.favoritesList.no-data {
  margin-bottom: 5px !important;
  .favoritesItem:hover .favoriteItemLogo {
    background-color: var(--bg-main) !important;
    color: var(--color-white-dark);
  }
  .favoriteTitle {
    color: var(--main-text-color) !important;
  }
}

[data-theme="dark"] .MakaniCard.no-data {
  &:hover {
    .MakaniCardBtn {
      color: #1e222b !important;
    }
  }
}
.no-makani-found {
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.MakaniCard.no-data {
  position: relative;
  background: var(--element-light-bg) !important;
  // margin-bottom:5px;

  &:hover {
  }
  &::after {
    content: "";
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9999;
  }
  .MakaniCardBtn {
    background-color: var(--bg-main) !important;
  }
  .makaniCardTitle {
    color: var(--element-text-color) !important;
  }
  .favoriteItemLogo {
    color: var(--color-white-dark) !important;
  }
  .MakaniCardLogo,
  .MakaniCardInfo,
  .MakaniCardBtn,
  .favoritesItemWrapper,
  .MakaniCardInfoAr,
  .MakaniCardLogoAr {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }
}
.MakaniCardBtn:hover {
}
.favoritesList.no-data {
  min-height: 65px;
  .favoritesItem {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    &::after {
      display: none;
    }
  }
}

.makani_cards_cover {
}

.favoritesItemWrapper:last-child .favoritesItem {
  border-bottom: 0 !important;
  padding-bottom: 0;
  &::after {
    bottom: -8px;
  }
}

.ReportCardFooter .arrow,
.searchCard .arrow {
  right: 9px;
  position: absolute;
  bottom: 15px;
  z-index: 100;
  cursor: pointer;
}
.smallArrow {
  right: 5px;
  position: absolute;
  bottom: 10px;
  z-index: 100;
  cursor: pointer;
}
.info {
  padding-bottom: 10px;
}
.settingCard,
.aboutCard {
  display: flex;
  gap: 14px;
  flex: 1;
  align-items: center;
  background: var(--element-light-bg);
  border-radius: 11px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
}
.ReportCardFooter {
  position: relative;
}

.makaniCardsContent .MakaniCardBtnsAr {
  display: flex;
  padding-left: 0;
  margin-right: auto;
  column-gap: 16.21px;
}
.favoritesItemWrapper .favoriteItemLogo {
  flex-shrink: 0;
}

.searchContainer .searchInput {
  font-size: var(--font-size-15-5);
}

.favoritesItemWrapper .favoritesItem {
  cursor: pointer;
}
.makaniCardsContent .MakaniCard {
  cursor: pointer;
}
.MakaniCard.no-data,
.MakaniCard.no-data::after {
  cursor: default;
}

.pointOfInternetContent .pointOfInternetTitle {
  cursor: pointer;
}

@media (max-width: 1679px) {
  .pointOfInternetContainer .pointOfInternetIcon img,
  .pointOfInternetContainer .pointOfInternetIcon svg {
    height: 38px;
  }
  .searchCards {
    // margin-top: 25px;
    // column-gap: 18.24px;
  }
  .searchCards .searchCard {
    width: auto;
    // height: 138px;
    flex: 1;
  }
  .pointOfInternetIcon {
    width: 50px;
    height: 50px;
  }
  .MakaniCardBtn {
    width: 40px;
    height: 40px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    // font-size: var(--font-size-15);
  }
  .makaniCardDistance {
    font-size: var(--font-size-13);
  }
  .makaniCardTitle {
    font-size: var(--font-size-13);
  }
}

@media (max-width: 1479px) {
  .infoCards {
    gap: 10px;
  }
  .searchCards {
    margin-bottom: 25px;
    .searchCard {
      height: 130px;
    }
  }
  .pointOfInternetIcon {
    width: 43px;
    height: 43px;
    img,
    svg {
      // width: 16px;
    }
  }

  .pointOfInternetContainer {
    margin-bottom: 25px;
  }

  .MakaniCardLogo {
    img {
      width: 60px;
    }
  }
  .MakaniCardInfo {
    padding-left: 10px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    // font-size: var(--font-size-14);
  }
  .makaniCardDistance {
    font-size: var(--font-size-13);
  }
  .makaniCardTitle {
    font-size: var(--font-size-12);
  }
  .MakaniCardBtn {
    // width: ;
  }
  .MakaniCardBtn {
    width: 33px;
    height: 33px;
    img,
    svg {
      width: 15px;
      height: auto;
    }
  }
  .favoriteItemLogo {
    width: 30px;
    height: 30px;
    img,
    svg {
      width: 10px;
    }
  }
  .favoriteTitle {
    font-size: var(--font-size-13);
    line-height: 1.1;
  }
  .favoritePOI {
    font-size: var(--font-size-12-5);
    margin-top: 3px;
  }
  .favoriteItemMakaniDistance {
    font-size: var(--font-size-13);
  }
  .searchCards .searchCard .searchCardTitle {
    font-size: var(--font-size-14-5);
    // line-height: 15.65px;
  }
  .searchCardFooterLogo {
    width: 25px;
    bottom: 5px;
  }
  .ReportCardFooter .arrow,
  .searchCard .arrow {
    // width: 8px;
    // right: 7px;
  }
  .rectangle img {
    width: 40px;
  }
  .ReportCard {
    p {
      font-size: var(--font-size-15);
      line-height: 16px;
    }
  }
  .settingCard,
  .aboutCard {
    > img {
      width: 25px;
    }
    p {
      font-size: var(--font-size-15);
    }
  }
  .ReportCardFooter {
    > img,
    > svg {
      width: 20px;
    }
  }
  .settingCard,
  .aboutCard {
    gap: 10px;
  }

  .makaniCardsContent .MakaniCardLogo img,
  .makaniCardsContent .MakaniCardLogo svg {
    width: 70.62px;
  }
}

@media (max-width: 1367px) {
  [dir="rtl"] .infoCards .rectangle .arrow {
    bottom: -20px;
    right: 13px;
    transform: rotate(90deg);
  }
  .searchCards .searchCard {
    // height: 100px;
  }
  .searchCards {
    column-gap: 15px;
  }
  .searchCardFooterLogo {
    // width: 20px;
  }
  .rectangle img {
    width: 35px;
  }
  .ReportCardFooter .arrow,
  .searchCard .arrow {
    width: 9px;
    right: 10px;
    bottom: 14px;
  }
  .searchCards .searchCard .searchCardTitle {
    // font-size: var( --font-size-13);
    // line-height: 1;
  }
  .MakaniCardBtns {
    gap: 5px;
  }
}

@media (max-width: 1281px) {
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    white-space: normal;
  }
  .pointOfInternetContainer .pointOfInternetIcon img,
  .pointOfInternetContainer .pointOfInternetIcon svg {
    height: 34px;
  }
  .ReportCardFooter > img,
  .ReportCardFooter > svg {
    width: 27px;
  }
  .pageTitleHeader {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .settingCard,
  .aboutCard {
    gap: 10px;
  }
  .settingCard p,
  .aboutCard p,
  .ReportCard p {
    font-size: var(--font-size-14);
  }
  .MakaniCard {
    height: auto;
    // padding: 10px 15px;
    margin: 10px 0;
  }
  .MakaniCardLogo img {
    width: 60px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    font-size: var(--font-size-16);
    line-height: 1.1;
  }
  .MakaniCardBtn {
    width: 30px;
    height: 30px;
    img,
    svg {
      width: 13px;
    }
  }
  .makaniCardTitle {
    // margin-bottom: 0px;
  }
  .pointOfInternetIcon {
    // width: 35px;
    // height: 35px;

    img,
    svg {
      // width: 12px;
    }
  }
  .pointOfInternetContent .pointOfInternetTitle {
    font-size: var(--font-size-12);
  }
  .favorites {
    margin-bottom: 25px;
  }
  .infoCards {
    // gap: 15px;
  }
  .favoritesList {
    margin-top: 0px;
  }

  .searchCardFooterLogo {
    // width: 25px;
  }
  .favoriteItemLogo {
    img,
    svg {
      width: 10px;
    }
  }
  .searchCards .searchCard .rectangle {
    width: 44px;
  }
  .searchContainer .searchInput {
    font-size: var(--font-size-13);
  }
}
@media (max-width: 1199px) {
  .MakaniCardHeader .copyWrapper {
    margin-left: 5px;
    padding-left: 5px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    white-space: nowrap;
  }

  .searchCards .searchCard {
    height: 110px;
  }
  .searchCardFooterLogo {
    width: 20px;
  }
  .makaniCardsContent .MakaniCardBtns {
    // padding-right: 5px;
  }
  .MakaniCardLogo img {
    width: 50px;
  }
  .MakaniCardInfo {
    padding-left: 13px;
  }
  .makaniCardDistance {
    font-size: var(--font-size-12);
  }
}

@media (max-width: 1100px) {
  .searchCards .searchCard .searchCardTitle {
    font-size: var(--font-size-18);
    // padding:20px;
  }
  .searchCardFooterLogo {
    // width: 25px;
    left: 20px;
    // bottom:15px;
  }
  .rectangle img {
    width: 55px;
  }
  .ReportCardFooter .arrow,
  .searchCard .arrow {
    width: 10px;
    right: 10px;
    bottom: 15px;
  }
  .pointOfInternetIcon {
    // width: 45px;
    // height: 45px;
  }
  .pointOfInternetIcon img {
    // width: 18px;
  }

  .MakaniCardLogo img {
    width: 90px;
  }
  .makaniCardTitle {
    font-size: var(--font-size-18);
    line-height: 1.1;
    margin-bottom: 3px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    font-size: var(--font-size-18);
    line-height: 1.1;
  }
  .makaniCardDistance {
    font-size: var(--font-size-15);
  }

  .favoriteTitle {
    font-size: var(--font-size-16);
    line-height: 1.1;
  }
  .favoritePOI {
    font-size: var(--font-size-14);
  }
  .favoriteItemMakaniDistance {
    font-size: var(--font-size-15);
  }
  .ReportCard p {
    font-size: var(--font-size-18);
    line-height: 23px;
  }

  .ReportCardFooter > img,
  .ReportCardFooter > svg {
    width: 30px;
  }
  .settingCard > img,
  .aboutCard > img,
  .settingCard > svg,
  .aboutCard > svg {
    width: 30px;
  }

  .searchCards .searchCard {
    // height: 131px;
  }
  .searchCards .searchCard .searchCardTitle {
    font-size: var(--font-size-14);
    // padding: 10px 11px;
  }
  .rectangle img {
    width: 44px;
  }
  .ReportCardFooter .arrow,
  .searchCard .arrow {
    width: 9px;
    // right: 8px;
    // bottom: 14px;
  }
  .searchCardFooterLogo {
    left: 11px;

    img {
      width: 30px;
    }
  }

  .pointOfInternetContent .pointOfInternetTitle {
    font-size: var(--font-size-12);
    font-weight: 300;
    margin-top: 7px;
  }

  .ReportCard p {
    font-size: var(--font-size-14);
    line-height: 18px;
  }
  .ReportCardFooter > img,
  .ReportCardFooter > svg {
    width: 32px;
    color: var(--tile-icon-color);
  }
  .settingCard p,
  .aboutCard p {
    font-size: var(--font-size-14);
  }
  .settingCard > img,
  .aboutCard > img,
  .settingCard > svg .aboutCard > svg {
    width: 32px;
  }
  .makaniCardTitle {
    font-size: var(--font-size-12);
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    font-size: var(--font-size-16);
    line-height: 1.1;
  }
  .makaniCardDistance {
    font-size: var(--font-size-12);
  }
  .favoriteItemMakaniDistance {
    font-size: var(--font-size-12);
  }
  .favoriteTitle {
    font-size: var(--font-size-12);
    line-height: 1.1;
  }
  .favoritePOI {
    font-size: var(--font-size-12);
  }
  .MakaniCardLogo img {
    width: 65px;
  }
  .MakaniCard {
    padding: 10px 11px;
  }

  .favoritesItem {
    border-width: 1px;
  }
}

@media (max-width: 991px) {
  .searchContainer .searchInput {
    border-radius: 10px !important;
  }
  .makaniCards {
    margin-bottom: 23.53px;
  }
  .searchCards {
    margin-top: 20px;
  }
  .favorites {
    margin-bottom: 15px;
  }

  [dir="rtl"] .infoCards .rectangle .arrow {
    bottom: -21px;
    right: 13px;
    transform: rotate(90deg);
  }
  .searchCards .searchCard {
    height: 115px;
  }
  .searchCardFooterLogo {
    width: 20px;
  }
  .settingCard,
  .aboutCard {
    padding-left: 7px;
  }
  .ReportCard {
    padding-left: 10px;
  }
  .searchCards {
    column-gap: 10px;
  }
  .searchInputWrapper {
    border: 0;
    border-radius: 10px;
    height: 35px;
  }
  .profile {
    width: 35px;
    height: 35px;
    border-radius: 10px;
  }
  .searchContainer .profile img {
    width: 14px;
  }

  .pointOfInternetIcon {
    width: 40px;
    height: 40px;
    img,
    svg {
      // width: 16px;
    }
  }
  .MakaniCardBtns {
    gap: 5px;
  }
  .favoriteItemLogo {
    img,
    svg {
      width: 10px;
    }
  }
  .MakaniCardInfo {
    padding-left: 8px;
  }
}

@media (max-width: 767px) {
  [dir="rtl"] {
    [data-size="2"] {
      .searchCards .searchCard {
        height: auto;
        min-height: 162px;
      }
    }
  }

  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    white-space: nowrap;
  }
  .MakaniCardInfo {
    padding-left: 13px;
  }
  .MakaniCard.no-data {
    margin-bottom: 15px;
  }
  .home__icon {
    width: 22px !important;
    height: 26px !important;
  }
  .searchCardFooterLogo {
    height: 26px !important;
  }

  .MakaniCardBtn img,
  .MakaniCardBtn svg {
    width: 15px;
  }
  .MakaniCardBtns {
    column-gap: 13px;
  }
  .searchCards {
    padding-top: 15px;
  }

  .ReportCardFooter .arrow,
  .searchCard .arrow {
    width: 8px;
    right: 8px;
    bottom: 13px;
  }
  .searchCardFooterLogo {
    bottom: 10px;
  }
  .pointOfInternetItemSearchSuggestions .pointOfInternetIcon {
    width: 35px;
    height: 35px;
  }
  .pointOfInternetContainer .pointOfInternetIcon img,
  .pointOfInternetContainer .pointOfInternetIcon svg {
    height: 35px;
  }

  [dir="rtl"] .settingCard,
  [dir="rtl"] .aboutCard {
    flex-direction: row !important;
    padding-right: 10px;
  }
  .searchInputWrapper {
    margin-bottom: 0px;
  }
  .searchCards {
    margin-top: 15px;
    margin-bottom: 40px;
    column-gap: 16px;
  }
  .pointOfInternetIcon {
    width: 50px;
    height: 50px;
  }
  .pointOfInternetHeader,
  .makaniCardsHeader,
  .favouritesHeader,
  .favoritesHeader {
    margin-bottom: 16px;
  }
  .favoriteItemLogo {
    img,
    svg {
      width: 11px;
    }
  }

  [dir="rtl"] .infoCards .ReportCard p {
    padding-right: 10px !important;
  }
  [dir="rtl"] .infoCards .ReportCardFooter {
    padding-right: 10px;
  }
  [dir="rtl"] .infoCards .rectangle {
    width: 35px;
  }
  [dir="rtl"] .infoCards .rectangle .arrow {
    bottom: -20px;
    right: 1px;
  }
  [dir="rtl"] .infoCards .rectangle2 svg.smallArrow {
    left: 18px;
    top: 20px;
  }
  .makaniCardsContent .MakaniCardBtns {
    padding-right: 0px;
  }
  .sideBarContent {
    // padding-top: 26px !important;
  }
  .searchContainer {
    column-gap: 11px;
    margin-bottom: 8px;
  }
  .searchCards .searchCard {
    height: 131px;
  }
  .searchCardFooterLogo {
    width: 26px;
  }
  .sideBarContent {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .favorites {
    .favoritesList {
      padding: 11px 12px;
      border-radius: 10px;
    }
  }
  .infoCards {
    gap: 15px;
  }
  .settingAboutCards {
    gap: 11px;
  }
  .rectangle2 svg.smallArrow {
    width: 6px;
    right: 6px;
  }
  .ReportCard {
    padding-left: 12px;
    padding-top: 12px;
    border-radius: 10px;
    .rectangle {
      width: 44px;
    }
  }
  .settingCard,
  .aboutCard {
    padding-left: 10px;
  }
  .makaniCardNumber,
  .favoriteItemMakaniNumber {
    font-size: var(--font-size-16);
  }
  .makaniCardDistance {
    font-size: var(--font-size-14);
  }
  .makaniCardDistance span {
    font-size: var(--font-size-12);
  }
}

@media (max-width: 479px) {
  .searchCards {
    margin-top: 0px;
  }
  .searchCards .searchCard .searchCardTitle {
    line-height: 1.1;
  }
  .ReportCard p {
    line-height: 1;
  }
  .pointOfInternetIcon {
    width: 50px;
    height: 50px;
    img,
    svg {
      // width: 18px;
    }
  }
  .MakaniCardBtn {
    width: 38px;
    height: 38px;
  }
  .favoriteItemLogo {
    width: 32px;
    height: 32px;
  }
  .pointOfInternetContainer .pointOfInternetIcon {
    img,
    svg {
      height: 40px;
    }
  }
}
