.sortDropDown {
  position: absolute;
  box-shadow: 0px 0px 8.31px 0px #00000040;
  min-width: 120.84px;
  height: 115.35px;
  border-radius: 9.15px;
  background: var(--sort--drop-down);
  padding: 15px 0;
  z-index: 100;
}
.sortDropDown ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.sortDropDown li {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 13px;
  border-bottom: 0.92px solid var(--line-color2);
  padding-bottom: 10px;
  cursor: pointer;
}
.sortDropDown li:nth-child(3) {
  border-bottom: none;
  padding-bottom: 0;
}

.sortDropDown p {
  font-family: 'VisbyRegular' , sans-serif;
  font-size: var(--font-size-10-5);
  font-weight: 400;
  line-height: 10.88px;
  color: var(--main-text-color);
}
