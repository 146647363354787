.deatilsMakaniCardActive {
  display: block;
}
.copyWrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.MakaniCardContentDesinence {
  color: var(--toggle-item-color);
  font-size: var(--font-size-16);
}
.MakaniCardContentText {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  color: var(--main-text-color);
}
.navigateWarper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--navigate-bg);
  color: var(--navigate-color);
  border-radius: 9.12px;
  width: 104.88px;
  height: 43.32px;
  gap: 4px;
}
.DetailsMakaniCardBtn {
  background: var(--makani-card-btn);
  width: 43.32px;
  height: 43.32px;
  border-radius: 9.12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigateTitle {
  font-size: var(--font-size-13-7);
  line-height: 1.1;
}
.MakaniCardHeader .logo {
  width: 84.66px;
  height: 32.38px;
}
