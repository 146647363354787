.makaniCardDetails {
  padding: 15px 0;
}
.makaniCardDetailsTitle {
  font-size: var(--font-size-15-3);
  line-height: 15.32px;
  color: var(--main-text-color);
}

.makaniCardDetailsAddress {
  margin-top: 10px;
  background: var(--menu-item-bg);
  padding: 15px;
  border-radius: 9.15px;
}

.makaniCardDetailsAddressTitle {
  font-size: var(--font-size-13-7);
  line-height: 13.54px;
  color: var(--main-text-color);
  padding-bottom: 5px;
}
.makaniCardDetailsSubAddressWrapper {
  padding-bottom: 10px;
  /* border-bottom: 1.14px solid var(--line-color); */
}

.makaniCardDetailsSubAddress {
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  color: var(--element-text-color);
  font-family: 'VisbyRegular', sans-serif ;
}
.parcelNumberLabel {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 13.54px;
  text-align: left;
  color: var(--main-text-color);
  padding-bottom: 5px;
}
.parcelNumberValue {
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 18.24px;
  text-align: left;
  color: var(--element-text-color);
}

.contactDetailsLabel {
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 22.8px;
  color: var(--element-text-color);
}

.contactDetailsWrapper {
  padding-top: 10px;
  border-top: 1.14px solid var(--line-color);
}
.contactDetailsWrapper p {
  font-family: 'VisbyRegular', sans-serif;
  font-size: var(--font-size-13-7);
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  color: var(--main-text-color);
  margin-bottom: 5px;
}

.parcelWrapper {
  padding: 10px 0;
}
