
  .sharePopup {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }
  .closeWrapper {
    display: flex;
    justify-content: space-between;
  }
  .popTitle {
    font-size: var(--font-size-18-3);
  }

  .sharePopup .close {
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    cursor: pointer;
  }
  
  .sharePopup .content {
    max-height: 90%;
    display: flex;
    gap: 30px;
  }
