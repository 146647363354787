@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.signWarper {
  display: none;
}

.userInfo {
  display: flex;
  flex-direction: column;
}
.userInfoDetails {
  display: flex;
  gap: 20px;
  align-items: center;
  img {
    width: 76.44px;
    height: 76.44px;
    border-radius: 50%;
    border: 2.46px solid #4e5972;
  }
}
.userDetailsWarper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.userInfo .userName {
  color: var(--main-text-color);
  @include apply-font-family(semibold);
  font-size: var(--font-size-20);
  font-weight: 400;
  line-height: 1.1;
}

.ProfileCardWarper {
  background: var(--profile-card);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56.08px;
  border-radius: 11.22px;
  padding: 0 15px 0 10px;
  cursor: pointer;
}

.profileWrapperLeft {
  display: flex;
  gap: 15px;
  align-items: center;
}
.profileWrapperLeft p {
  color: var(--main-text-color);
  @include apply-font-family(semibold);
  font-size: var(--font-size-17-5);
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
}

.profileWrapper {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: var(--third-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileCardTitle {
  @include apply-font-family(regular);
  font-size: var(--font-size-15-5);
  font-weight: 400;
  line-height: 15.55px;
  color: var(--main-text-color);
}

.userInfo .userDetails {
  color: #7a7f97;
  @include apply-font-family(regular);
  font-size: var(--font-size-13-6);
  font-weight: 400;
  line-height: 1.1;
}

.profileCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.profileInfoDetails {
  display: flex;
  align-items: center;
  column-gap: 25px;
  margin-bottom: 32px;
}

.ProfileCardWarper {
  transition: 0.2s;
  &:hover,
  &.active {
    background-color: #333c4f;
    .profileWrapperLeft + div {
      position: relative;
      margin-right: -3px;
      img {
        filter: brightness(0) invert(1);
      }
    }
    .profileWrapperLeft p {
      color: #cfd5f2;
    }
  }
  &.active {
    .profileWrapperLeft + div {
      margin-right: 0;
    }
  }
}

// arabic mode
[dir="rtl"] {
  .ProfileCardWarper {
    padding: 0 10px 0 15px;
  }
  .profileWrapperLeft + div {
    transform: rotate(180deg);
  }
}

// dark
[data-theme="dark"] {
  .ProfileCardWarper {
    transition: 0.2s;
    &:hover {
      background-color: #1e222b;
      .profileWrapperLeft + div {
        position: relative;
        margin-right: -3px;
        img {
          filter: brightness(0) invert(1);
        }
      }
      .profileWrapperLeft p {
        color: #cfd5f2;
      }
    }
  }
  .profileWrapperLeft {
    .profileWrapper {
      img,
      svg {
        //         -webkit-filter: invert(0.3) sepia(1) saturate(4) hue-rotate(181deg);
        // filter: invert(0.3) sepia(1) saturate(4) hue-rotate(181deg);
      }
    }
  }
  .MenuItemRow {
    input[type="radio"]:checked {
      accent-color: #25b9aa !important;
    }
    input[type="radio"] {
    }
  }
  .userInfo .userDetails {
    color: #ffffff;
  }
}

@media (max-width: 1679px) {
  .profileInfoDetails {
    column-gap: 18px;
  }
  .userInfo .userName {
    font-size: var(--font-size-18);
    line-height: 1.1;
  }
  .userInfo .userDetails {
    font-size: var(--font-size-13);
  }
  .ProfileCardWarper {
    height: 50px;
  }
  .profileWrapper {
    width: 35px;
    height: 35px;
    img {
      width: 20px;
    }
  }
  .profileWrapperLeft p {
    font-size: var(--font-size-15);
  }
}
@media screen and (max-width: 1281px) {
  .profileInfo {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .userInfo {
    padding-left: 20px;
    padding-right: 20px;
  }
  .userInfoDetails img {
    width: 68px;
    height: 68px;
  }
  .profileInfoDetails {
    margin-bottom: 0px;
  }
  .userInfo {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 0;
    .userName {
      color: #ffffff;
    }
  }
  .signWarper {
    display: flex;
  }
}
