@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.contact-container {
    background-color: var(--whitebox);
    border-radius: 10px;
    padding: 23.36px 24.79px;
  }
  
  .contact-container > .contact-col:not(:last-child) {
    border-bottom: 1px solid var(--linecolor);
  
  }
 
  .contact-col {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding-bottom: 15px;
    &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
    }
  }
  
  .contacticon {
    width: 35.95px;
    height: auto;
  }
  .contact-text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .contacttext1 {
      @include apply-font-family(regular);
      font-size: var(--font-size-12);
      color: var(--darktextcolor);
      margin-bottom: 3px;
    }
  
    .contacttext2 {
      @include apply-font-family(semibold); 
      font-size: var(--font-size-16);
      color: var(--boldtextcolor);
    }
  }
  .contacttext2 a {
    color: inherit;
    text-decoration: none;
  }
  
  .contacttext2 a:hover {
    text-decoration: underline;
  }
  
 // arabic mode 
 [dir=rtl]{
    .contact-text {
        margin-left: inherit;
        margin-right: 15px;
    }
 }


  @media (max-width: 767px) {
    .contact-text .contacttext2 {
      font-size: var(--font-size-14);
    }
    .contact-container {
         padding: 20px;
         .contacticon {
            width: 28.95px;
        }
    }
    .contact-text{
        margin-left: 10px;
    }
   
  }
  