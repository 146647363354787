.speech-controls {
    display: flex;
    margin-right: 20px;
    margin-bottom: 17px;
}

.speakButton,
.stopButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -16px;
}

.speakButton::before,
.stopButton::before {
    content: '';
    position: absolute;
    width: 36.75px; 
    height: 36.75px; 
    border-radius: 13.36px;
    border: 1px ;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1; 
    background-color: rgba(51, 60, 79, 0.2); 
}
@media (max-width: 990px){
    .speakButton::before,
    .stopButton::before {
        width: 30px;
        height: 30px;
    }
    .speakButton img,
    .stopButton img {
        width: 20px;
        height: 20px;
    }
}
[dir=rtl] {
    @media (max-width: 991px){
        .speech-controls{
            margin-left: 20px;
        }
    }
}

.speakButton svg,
.stopButton svg {
    color: #333C4F; 
    font-size: 20px; 
}

/* .speakButton:hover::before,
.stopButton:hover::before {
    background-color: #333C4F; 
}

.speakButton:hover svg,
.stopButton:hover svg {
    color: white; 
} */

.stopButton:disabled svg {
    color: #ccc;
}

@media(max-width:1281px){
    .speakButton, .stopButton {
        width: 46.75px; 
        height: 46.75px;
    }
}
@media(max-width:991px) {
    .speakButton, .stopButton {
        margin-right: 15px;
        width: 20px;
        height: 20px;
        margin-left: 15px;
    }
}