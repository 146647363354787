@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}

.legend-cover {
  padding-top: 20px;
  article {
    p {
      color: var(--darkcommon);
      font-size: 14.96px;
      line-height: 22px;
      margin-bottom: 25px;
    }
    .tilte-md {
      font-size: 20px;
      line-height: 22px;
      @include apply-font-family(bold);
      color: #333c4f;
      margin-bottom: 10px;
    }
    .img-article {
      margin-bottom: 18px;
      svg,
      img {
        max-width: 100%;
      }
    }
  }
}

.legendlist {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  column-gap: 15px;
  border-bottom: 1px solid var(--borderline);

  .pin-icon {
    position: relative;
    top: -5px;
    height: auto;
  }

  .text-container {
    display: flex;
    flex-direction: column;
  }
}

.traffic-info-cover {
  display: flex;
  position: relative;
  width: 100%;

  margin-top: 45px;

  .traffic-col {
    flex-grow: 1;
    position: relative;
    height: 10px;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .t-label {
      margin-top: -20px;
      @include apply-font-family(semibold);
      font-size: var(--font-size-14);
      color: var(--darkcommon);
    }
    &.high {
      background-color: #e74c3c;
    }
    &.medium {
      background-color: #ffb606;
    }
    &.no-trafic {
      background-color: #62cb31;
    }
  }
}

.text-bold {
  @include apply-font-family(semibold);
  font-size: var(--font-size-15);
  color: var(--darkcommon);
  margin-top: 0px;
  margin-bottom: 14px;
}

// arabic mode
[dir="rtl"] {
  .content-cover {
    // padding-right: 0px;
    // padding-left:15px;
  }
  .traffic-info-cover .traffic-col:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .traffic-info-cover .traffic-col:last-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
}

// arabic mode End
