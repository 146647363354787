.makaniCardSlider {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 36px;
}
.dots {
  display: flex;
  align-items: center;
}

.dotActive {
  background: #009d91;
  width: 21px;
  border-radius: 27.46px;
}

.dot {
  cursor: pointer;
  height: 6px;
  width: 6px;
  margin: 0 2px;
  background: #d1d1d1;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
