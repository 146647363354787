// indoornavigation
@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == spartan-regular {
    font-family: var(--font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.indoornavigation-otr {
  background: -webkit-linear-gradient(180deg, #a0e2da 17.78%, #a9c1f0 265.49%);
  background: -moz-linear-gradient(180deg, #a0e2da 17.78%, #a9c1f0 265.49%);
  background: -o-linear-gradient(180deg, #a0e2da 17.78%, #a9c1f0 265.49%);
  background: linear-gradient(180deg, #a0e2da 17.78%, #a9c1f0 265.49%);

  .searchTitleWarper {
    margin: 0;
  }
}
[data-theme="dark"] {
  .indoornavigation-otr {
    background: var(--background-color-dark);
  }
}

.indoornav-content {
  margin-top: 15px;

  .indoor-navcontroller {
    background-color: var(--element-light-bg);
    border-radius: 10px;
    min-height: 102px;
    padding: 21px 20px 21px 13px;
    .navigation-group {
      column-gap: 14px;
      display: flex;

      .route-info {
        flex-shrink: 0;
        display: flex;

        padding-bottom: 10px;
        .lines {
          display: flex;
          justify-content: space-between;
          border-left: 2.11px dashed var(--route-line) !important;
          flex-direction: column;
          margin-left: 5px;
          .marker {
            position: relative;
            left: -6px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
            -moz-box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
            box-shadow: 0px 0px 0px 2.11px rgba(209, 209, 209, 1);
            flex-shrink: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            .arrow-green {
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 6px solid #009d91;
              margin-top: 1px;
            }
            &.circle-green {
              background-color: #009d91;
              border: 2px solid #fff !important;
            }
          }
        }
        .markerChild {
          display: none;
        }
      }
      .swap-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #333c4f;
      }
      .navigation-controler {
        .divider {
          background-color: #e2e2e2;
          margin: 10px 0;
          height: 1px;
        }
        width: 100%;
        .input-cvr {
          display: flex;
          color: #4e5972;
          font-size: var(--font-size-14);
          @include apply-font-family(semibold);

          strong {
            padding-right: 15px;
            width: 100%;
          }
        }
      }
      .frm_controler_otr {
        background-color: var(--element-light-bg);
        width: 100%;
      }
      .frm-input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .frm_controler {
        background-color: var(--element-light-bg);
        border: 0;
        outline: none;
        font-size: var(--font-size-14);
        color: var(--main-text-color);
        height: 35px;
        width: 100%;
        padding-right: 15px;
        &::placeholder {
          @include apply-font-family(regular);
          color: #d1d1d1;
        }
      }

      .btn-navi {
        margin-left: auto;
        cursor: pointer;
        color: #c6c6c6;
        border: 0;
        background-color: transparent;
        &:hover {
          color: #009d91;
        }
      }
    }
  }
}

.indoor-broup-btn {
  position: relative;

  // margin-top: 21px;
  .sub-menu-otr {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
  }
  .navi-btn {
    border-radius: 8px;
    flex-grow: 1;
    border: 0;
    outline: none;
    height: 42px;
    cursor: pointer;
    @include apply-font-family(medium);

    background-color: #c5c2c2;
    color: #a1a1a1;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 12px;

    &.primary-navi {
      background-color: #4e5972;
      color: #fff;
      position: relative;
      display: inline-flex;
      align-items: center;
      .icon-control {
        margin-left: auto;
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
      }
      .arrowdown {
        content: "";
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &:hover {
        background-color: #009d91;
      }
      .arrowup {
        content: "";
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      &:hover {
        background-color: #009d91;
      }
    }
    &.start-btn {
      background-color: #009d91;
      color: #fff;
      &:hover {
        // background-color: #047d73;
      }
    }
  }

  .floor-navigation-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 0;
    outline: none;
    height: 42px;
    background-color: #009d91;
    transition: 0.2s;
    position: relative;
  }

  .arrow-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-button.left {
    margin-right: 8px;
  }

  .arrow-button.right {
    margin-left: 8px;
  }

  .floor-nav-content {
    color: #fff;
  }

  .btn__grp {
    justify-content: space-between;
    display: flex;
    .control-btn {
      background-color: transparent;
      border: 0;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
    .lft-navi {
      border-right: 1px solid #079186;
    }
    .rgt-navi {
      border-left: 1px solid #079186;
    }
    .count-control {
      color: #fff;
    }
  }
  .label-navi {
    color: #4e5972;
    width: 100%;
    margin-bottom: 7px;
    @include apply-font-family(medium);
    font-size: var(--font-size-12);
  }
  .navi-otr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
  }
  .submenu {
    position: absolute;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    top: 70px;
    width: 100%;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1490196078);
    border-radius: 10px;
    padding: 19px 32px;
    left: 0;
    z-index: 9;
    .sub-item {
      border: 0;
      background-color: transparent;
      text-align: left;
      border-bottom: 1px solid #c6c6c6;
      padding-bottom: 15px;
      margin-bottom: 15px;
      font-size: var(--font-size-16);
      color: var(--main-text-color);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .input-otr {
        position: relative;
        top: 2px;
        color: var(--main-text-color);
      }
    }
  }
}
[data-theme="dark"] {
  .navi-btn.primary-navi {
    background-color: #1e222b;
    color: #cfd5f2;
  }
}

[data-theme="dark"] {
  .navi-btn {
    background-color: #4e5972;
    color: #7a7f97;
  }
}

[data-theme="dark"] {
  .submenu {
    background: #4e5972;
  }
}

[data-theme="dark"] {
  .search-list-area .search-list-view .list_view {
    background-color: #4e5972;
    color: #cfd5f2;
  }
}

[data-theme="dark"] {
  .indoornav-content .indoor-navcontroller .navigation-group .swap-button {
    color: #4e5972;
  }
}

[data-theme="dark"] {
  .frm_controler_otr {
    color: #cfd5f2;
  }
}
.searchContainer {
  .cancel-btn {
    color: #009d91;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.search-list-area {
  .search-slider {
    left: 10px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    .entrance-label {
      background-color: #ffffff;
      padding: 0px, 15px, 0px, 15px;
      height: 35px;
      border-radius: 27px;
      box-shadow: 0px 0px 10px 0px #0000001a;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-12);
      color: #4e5972;
      cursor: pointer;
    }
    .slick-slide > div {
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .search-title {
    color: #333c4f;
    font-size: var(--font-size-20);
    @include apply-font-family(semibold);
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .search-list-view {
    // height: auto;
    max-height: 20vh;
    overflow-y: auto;
    border-radius: 10px;
    margin-bottom: 15px;

    .title-sm {
      color: #333c4f;
      @include apply-font-family(medium);
      margin-bottom: 11px;
      margin-top: 35px;
    }
    .list_view {
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 10px 12px;
      margin-bottom: 10px;
      .nodeWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      li {
        border-bottom: 1px solid rgba(129, 170, 205, 0.5);
        font-size: var(--font-size-12);
        @include apply-font-family(medium);
        min-height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.add-entrance-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;

  .enterance-modal-body {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 250px;
    width: 100%;
    border-radius: 14px;
    margin: auto;
    z-index: 99;
    position: relative;

    height: auto;
    .hd-otr {
      padding: 22px 10px;
    }
    .head-mdl {
      font-size: var(--font-size-16);
      @include apply-font-family(semibold);
      color: #4e5972;
      margin-bottom: 5px;
    }
    .sub_title {
      font-size: var(--font-size-14);
      color: #4e5972;
    }
    .entrance-ftr {
      display: flex;
      height: 40px;
      border-top: 1px solid #d1d1d1;
      .Btn {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        color: #009d91;
        @include apply-font-family(semibold);
        font-size: var(--font-size-14);
        gap: 10px;
        cursor: pointer;
      }

      .start__btn {
        border-right: 1px solid #d1d1d1;
      }

      .finish-btn {
      }

      .start-finish {
        position: relative;
        left: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 0px 0px 2.11px #009d91;
        -moz-box-shadow: 0px 0px 0px 2.11px #009d91;
        box-shadow: 0px 0px 0px 2.11px #009d91;
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        .arrow-green {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8.5px solid #009d91;
          margin-top: 1px;
        }
        &.circle-green {
          background-color: #009d91;
          border: 3px solid #fff !important;
        }
      }
    }
  }
}

[dir="rtl"] {
  .select-floor {
    right: 50%;
    @media (min-width: 320px) and (max-width: 374px) {
      right: 20%;
    }
    @media (min-width: 375px) and (max-width: 630px) {
      right: auto;
    }
    @media (min-width: 630px) and (max-width: 769px) {
      bottom: 35%;
      right: 35%;
    }
  }
}

.select-floor {
  position: absolute;
  bottom: 2%;
  left: 50%;

  .btn__grp {
    min-width: 226px;
    background-color: #4e5972 !important;

    .count-control {
      strong {
        @include apply-font-family(semibold);
      }
    }
    .sup {
      font-size: 0.75em; /* Adjust the size as needed */
      vertical-align: super;
    }
    .lft-navi,
    .rgt-navi {
      border-color: #717a8e;
    }
  }
  @media (max-width: 769px) {
    bottom: 35%;
    left: 23%;
  }
  @media (min-width: 630px) and (max-width: 769px) {
    bottom: 35%;
    left: 35%;
  }
}

.indoor-navi-point {
  background: linear-gradient(
    99.19deg,
    rgba(4, 202, 187, 0.7) -19.53%,
    rgba(0, 55, 176, 0.7) 139.73%
  );
  min-height: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .lft_content {
    padding: 12px 0 12px 12px;
    display: flex;
    flex-direction: column;
    .point-name {
      color: #fff;
      @include apply-font-family(semibold);
      font-size: var(--font-size-12);
    }
    .floor-door {
      color: #fff;
      font-size: var(--font-size-11);
      margin-top: auto;
      strong {
        @include apply-font-family(semibold);
      }
      .sup {
        font-size: 0.75em; /* Adjust the size as needed */
        vertical-align: super;
      }
    }
  }

  .start_finish_grp {
    width: 77px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;

    .entrance__rgt {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 3px;

      .Btn {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        color: #fff;
        font-size: var(--font-size-14);
        cursor: pointer;
      }

      .start__btn {
        background-color: #333c4f;
      }

      .finish-btn {
        background-color: #333c4f;
      }

      .start-finish {
        position: relative;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 0px 0px 2.11px #009d91;
        -moz-box-shadow: 0px 0px 0px 2.11px #009d91;
        box-shadow: 0px 0px 0px 2.11px #009d91;
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        .arrow-green {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8.5px solid #009d91;
          margin-top: 1px;
        }
        &.circle-green {
          background-color: #009d91;
          border: 3px solid #fff !important;
        }
      }
    }
  }
}

.root-option {
  background: linear-gradient(
    99.19deg,
    rgba(4, 202, 187, 0.7) -19.53%,
    rgba(0, 55, 176, 0.7) 139.73%
  );
  border-radius: 20px;
  padding: 30px 27px;
  display: flex;
  gap: 20px;
  .manwalk {
    flex-shrink: 0;
    color: #fff;
  }
  .content__rgt {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 3px;
    span {
      font-size: var(--font-size-16);
    }
    strong {
      @include apply-font-family(medium);
      font-size: var(--font-size-20);
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// arabic mode
[dir="rtl"] {
  .indoor-navi-point .start_finish_grp .entrance__rgt {
    direction: ltr;
  }
  .indoor-navi-point .start_finish_grp {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .indoor-navi-point .lft_content {
    padding: 12px 12px 12px 0px;
  }
  .indoor-broup-btn .btn__grp .lft-navi,
  .indoor-broup-btn .btn__grp .rgt-navi {
    transform: rotate(180deg);
  }
  .add-entrance-modal .enterance-modal-body .entrance-ftr {
    direction: ltr;
  }
}

.settingsContent {
  display: flex;
  flex-direction: column;
}
