@mixin apply-font-family($font-weight) {
  @if $font-weight == bold {
    font-family: var(--font-primary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == extrabold {
    font-family: var(--font-primary-extrabold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == semibold {
    font-family: var(--font-primary-semibold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  } @else if $font-weight == medium {
    font-family: var(--font-primary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == heavy {
    font-family: var(--font-primary-heavy);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == light {
    font-family: var(--font-primary-light);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == thin {
    font-family: var(--font-primary-thin);
    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }@else if $font-weight == secondary-bold {
    font-family: var(--font-secondary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-medium {
    font-family: var(--font-secondary-medium);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == secondary-regular {
    font-family: var(--font-secondary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  } @else if $font-weight == tertiary-bold {
    font-family: var(--font-tertiary-bold);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-bold);
    }
  }@else if $font-weight == tertiary-regular {
    font-family: var(--font-tertiary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
  @else if $font-weight == spartan-regular {
    font-family: var(  --font-league-spartan-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
   @else {
    font-family: var(--font-primary-regular);

    [dir="rtl"] & {
      font-family: var(--font-tertiary-regular);
    }
  }
}
.sideBarContent.inner-content.inner-article {
  padding: 24px 40px 35px !important;
  .PageHeaderTitleWarper {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  // @media (max-width: 940px){
  //     .PageHeaderTitleWarper{
  //         justify-content: start;
  //     }
  // }
  .pageTitleHeader {
    margin-bottom: 22.23px;
    .backPage {
      width: 46.75px;
      height: 46.75px;
      border-radius: 13.36px;
      background-color: #333c4f;
    }
    // @media (max-width: 940px){
    //     .PageHeaderTitleWarper{
    //         justify-content: start;
    //     }
    // }
    .pageTitleHeader{
        margin-bottom: 22.23px;
        .backPage{
            width:46.75px;
            height:46.75px;
            border-radius: 13.36px;
            background-color: #333C4F;
        }
        .pageHeaderIcons{
            column-gap: 15.19px;
        }
    }
  }
}
.content-cover {
  article {
    p {
      color: var(--main-text-color);
      font-size: var(--font-size-14-5);
      line-height: 1.5;
      margin-bottom: 25px;
    }
    .tilte-md {
      font-size: var(--font-size-20);
      line-height: 22px;
      font-family: "VisbyBold", sans-serif;
      color: var(--main-text-color);
      margin-bottom: 10px;
    }
    .img-article {
      margin-bottom: 18px;
      svg,
      img {
        max-width: 100%;
      }
    }
  }
}

.link {
  color: var(--main-text-color);
  text-decoration: none;
}

.link:hover {
  color: var(--main-text-color);
  text-decoration: underline;
}

// arabic mode
[dir="rtl"] {
  .content-cover {
    // padding-right: 0px;
    // padding-left:15px;
  }
}

// arabic mode End

// dark theme custom
[data-theme="dark"] {
  .sideBarContent.inner-content.inner-article .pageTitleHeader .backPage {
    background-color: #1e222b;
  }
  .content-cover article .img-article svg,
  .content-cover article .img-article img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
}
.content-cover article .img-article svg,
.content-cover article .img-article img {
  height: auto;
}

@media (max-width: 1679px) {
  .sideBarContent.inner-content.inner-article {
    padding: 24px 28px !important;
  }
  .content-cover article p {
    font-size: var(--font-size-13-6);
  }
  .content-cover {
    // padding-right: 15px;
    // padding-left: 0;
  }
  .content-cover article .tilte-md {
    font-size: var(--font-size-18);
  }
}
@media (max-width: 1341px) {
  .content-cover {
    // padding-right: 15px;
    // padding-left: 20px;
  }
}
@media (max-width: 1281px) {
  .content-cover {
    padding-right: 0px;
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .sideBarContent.inner-content.inner-article .pageTitleHeader {
    margin-bottom: 15px;
  }
  .content-cover article p {
    font-size: var(--font-size-13);
    line-height: 1.4;
    margin-bottom: 15px;
  }
  .content-cover article .tilte-md {
    font-size: var(--font-size-17);
  }
  .content-cover article .img-article svg,
  .content-cover article .img-article img {
    width: 35px;
  }
  .inner-article {
    .pageTitleHeader {
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 767px) {
  // .content-cover{
  //     max-height: 285px;
  //     overflow: auto;
  //     scrollbar-width: none;
  //     &::-webkit-scrollbar {
  //         display: none;
  //     }

  // }
  .sideBarContent.inner-content.inner-article {
    padding: 30px !important;
  }
  .sideBarContent.inner-content.inner-article .pageTitleHeader .backPage {
    width: 36.75px;
    height: 36.75px;
    border-radius: 9.36px;
  }
  .sideBarContent.inner-content.inner-article
    .pageTitleHeader
    .pageHeaderIcons {
    column-gap: 15px;
  }
}
